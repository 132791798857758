import produce from 'immer'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { VscClose } from 'react-icons/vsc'
import Modal from 'react-modal';
import IndividualTrackerRow from './individualTracker';
import { FaLongArrowAltRight } from 'react-icons/fa';
import IndividualZimmerTable from './individualZimmerTable';

function InProgressstepperModal(
    {
        zimmerMan,
        listblocks,
        listId,
        isQtyExceeded,
        getInprogressDefaultValue,
        getLinkedPartName,
        currentTrackerType,
        inprogressTotalPartCount,
        enableSaveButton,
        selectedActivity,
        enableNextButton,
        setBtnEnable,
        inprogressCount,
        setIsQtyExceeded,
        setUpdateQuantity,
        updateInprogressDeployedCount,
        setInprogressDeployedPartCount,
        handleCloseWithoutSaving,
        handleSaveInProgressSteps,
        piersTorqueTubesDetails,
        inprogressIndividualTrackerModal,
        setInprogressIndividualTrackerModal,
        selectedPiersTorqueTubes,
        setSelectedPiersTorqueTubes
    }
) {

    const [showDescription, setShowDescription] = useState(null)
    const [enableNextBtn, setEnableNextBtn] = useState(false)
    const [individualTrackerAlertModal, setIndividualTrackerAlertModal] = useState(false)

    useEffect(() => {
        let deployedCount = getInprogressDefaultValue()
        if (deployedCount <= inprogressTotalPartCount && deployedCount !== 0) {
            setEnableNextBtn(true)
        }
        else {
            setEnableNextBtn(false)
        }
    }, [])

    const inProgressTrackersBomCustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '75vw',
            maxWidth: "1000px",
            height: "95vh",
            maxHeight: "1200px",
            padding: "0px 20px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
        }
    }

    const alertModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '800px',
            maxHeight: "650px",
            padding: "0px 20px 20px",
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    function closeInProgressTrackerBomModal() {
        setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 })
    }

    const handleChangeUnitOfCounts = (value) => {
        if (value <= inprogressTotalPartCount) {
            setInprogressDeployedPartCount(prev => ({
                ...prev,
                [currentTrackerType.tracker_type_id]: value
            }));

            let updatedObj = {
                ...listblocks.inprogressDeployedCount,
                [currentTrackerType.tracker_type_id]: value
            };
            updateInprogressDeployedCount(updatedObj);

            const isExceeded = value > inprogressTotalPartCount;
            setIsQtyExceeded(isExceeded);
            setEnableNextBtn(true)
        }
        else {
            setEnableNextBtn(false)
            toast.error("Value must not be greater than unit of counts")
        }
        const isExceeded = value > inprogressTotalPartCount;
        setIsQtyExceeded(isExceeded);
    }

    const handleDistribuetdInstalledQtyOnChange = (e, item, maxVal, type) => {
        const trackerTypeId = parseInt(item?.tracker_type_id);
        const value = parseInt(e.target.value) || 0; // Use 0 if the input is empty

        setUpdateQuantity(prev => ({
            ...prev,
            [trackerTypeId]: {
                ...prev[trackerTypeId],
                [`${item.bom_id}_${item.tracker_row_id}`]: {
                    ...prev[trackerTypeId]?.[`${item.bom_id}_${item.tracker_row_id}`],
                    [type]: value
                }
            }
        }));
        setBtnEnable(true)
        setEnableNextBtn(true)
        setIsQtyExceeded(value > maxVal);
        if (value > maxVal) {
            setBtnEnable(false)
            setEnableNextBtn(false)
            toast.error("Value must not exceed per tracker quantity")
        }
    }

    const getExistingDistributedAndInstalledQuantity = (data) => {
        let existingValue = null
        let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`
        if (listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key]) {
            if (listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key][data?.found_type] !== 0) {
                existingValue = listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key][data?.found_type]
            }
        }
        return existingValue
    }

    const getCurrentDistributedAndInstalledQuantity = (data) => {
        let currentValue = null
        let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`
        if (Object.keys(listblocks?.updateDistributedAndInstalledQuantity).length > 0) {
            if (listblocks?.updateDistributedAndInstalledQuantity[key] && listblocks?.updateDistributedAndInstalledQuantity[`${data?.bom_id}_${parseInt(data?.tracker_row_id)}`][data?.found_type])
                currentValue = listblocks?.updateDistributedAndInstalledQuantity[key][data?.found_type]
        }
        return currentValue
    }

    return (
        <div>
            <Modal
                isOpen={inprogressIndividualTrackerModal.bool}
                onRequestClose={closeInProgressTrackerBomModal}
                style={inProgressTrackersBomCustomStyles}
                contentLabel='In-Progress Individual Tracker Type BOM'
            >
                <div className='h-full'>
                    <div className='flex justify-between font-bold text-base pt-4 py-2 bg-white sticky top-0 z-30'>
                        <div className='flex flex-col'>
                            <p className='my-2'>
                                {inprogressIndividualTrackerModal?.tracker_name}
                            </p>
                        </div>

                        <div className='flex'>
                            <VscClose onClick={() => { setIndividualTrackerAlertModal(true) }} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                        </div>
                    </div>
                    <div className='mb-4'>
                        {!["All", "None Selected"].includes(selectedActivity) && listId?.construction ?
                            <p className='text-gray-500 text-sm flex gap-2 items-center space-x-2 font-medium'>
                                <span className='text-black font-semibold'>{listId?.construction[listId?.construction[selectedActivity]?.parent_activity_id]?.name}</span>
                                <span className=''><FaLongArrowAltRight /></span>
                                <span className='text-black font-semibold'>{listId?.construction[selectedActivity]?.name}</span>
                                {/* <span className=''><FaLongArrowAltRight /></span>
                                <span className='text-black font-semibold'>{inprogressIndividualTrackerModal?.tracker_name}</span> */}
                            </p>
                            :
                            null
                        }
                    </div>
                    <hr />
                    <div className='flex justify-between items-center w-[70%] my-4 m-auto'>
                        <div className='flex items-center space-x-3'>
                            <div className="avatar">
                                <div className={`w-6 text-center rounded-full ${inprogressIndividualTrackerModal?.step === 1 ? "text-white bg-red-550" : "text-white bg-gray-300"}`}>
                                    1
                                </div>
                            </div>
                            <p className={`font-semibold text-sm ${inprogressIndividualTrackerModal?.step === 1 ? "text-black" : "text-gray-400"}`}>Enter BOM data</p>
                        </div>
                        <hr className='w-[60%]' />
                        <div className='flex items-center space-x-3'>
                            <div className="avatar">
                                <div className={`w-6 text-center rounded-full ${inprogressIndividualTrackerModal?.step === 2 ? "text-white bg-red-550" : "text-white bg-gray-300"}`}>
                                    2
                                </div>
                            </div>
                            <p className={`font-semibold text-sm ${inprogressIndividualTrackerModal?.step === 2 ? "text-black" : "text-gray-400"}`}>Enter Colors</p>
                        </div>
                    </div>
                    <hr />
                    {inprogressIndividualTrackerModal?.step === 1 ?
                        <div className='h-[70%] overflow-auto'>
                            <p className='font-semibold text-base my-2'>Distributed Quantities</p>
                            <table className='table table-compact w-full'>
                                <thead>
                                    <tr>
                                        <th className='normal-case'>Part Number</th>
                                        <th className='normal-case'>Part Description</th>
                                        <th className='normal-case'>Quantities per tracker</th>
                                        <th className='normal-case'>Distributed Quantities</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inprogressIndividualTrackerModal?.bomdata?.distributed?.length > 0 && inprogressIndividualTrackerModal?.bomdata?.distributed.map((districtedBomData) => {
                                        return (
                                            <tr>
                                                <td>{districtedBomData?.part_name}</td>
                                                <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(`${districtedBomData?.bom_id}_distributed`) }} onMouseLeave={() => { setShowDescription() }}>
                                                    {districtedBomData?.bom_id && showDescription === `${districtedBomData?.bom_id}_distributed` ?
                                                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                            {districtedBomData?.description}
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                    <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{districtedBomData?.description}</p>
                                                </td>
                                                <td>{districtedBomData?.total_quantity}</td>
                                                <td>
                                                    <input type='number' className='border px-2 py-1.5 w-32'
                                                        defaultValue={listblocks?.updateDistributedAndInstalledQuantity[`${districtedBomData?.bom_id}_${parseInt(districtedBomData?.tracker_row_id)}`] && listblocks?.updateDistributedAndInstalledQuantity[`${districtedBomData?.bom_id}_${parseInt(districtedBomData?.tracker_row_id)}`][districtedBomData?.found_type] ? getCurrentDistributedAndInstalledQuantity(districtedBomData) : getExistingDistributedAndInstalledQuantity(districtedBomData)}
                                                        onChange={(e) => handleDistribuetdInstalledQtyOnChange(e, districtedBomData, districtedBomData?.total_quantity, "distributed")} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <hr className='mt-2 w-full' />
                            <p className='font-semibold text-base my-2'>Installed Quantities</p>
                            <table className='table table-compact w-full'>
                                <thead>
                                    <tr>
                                        <th className='normal-case'>Part Number</th>
                                        <th className='normal-case'>Part Description</th>
                                        <th className='normal-case'>Quantities per tracker</th>
                                        <th className='normal-case'>Installed Quantities</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inprogressIndividualTrackerModal?.bomdata?.installed?.length > 0 && inprogressIndividualTrackerModal?.bomdata?.installed.map((installedBomData) => {
                                        return (
                                            <tr>
                                                <td>{installedBomData?.part_name}</td>
                                                <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(`${installedBomData?.bom_id}_installed`) }} onMouseLeave={() => { setShowDescription() }}>
                                                    {installedBomData?.bom_id && showDescription === `${installedBomData?.bom_id}_installed` ?
                                                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                            {installedBomData?.description}
                                                        </p>
                                                        :
                                                        null
                                                    }
                                                    <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{installedBomData?.description}</p>
                                                </td>
                                                <td>{installedBomData?.total_quantity}</td>
                                                <td>
                                                    <input type='number' className='border px-2 py-1.5 w-32'
                                                        defaultValue={listblocks?.updateDistributedAndInstalledQuantity[`${installedBomData?.bom_id}_${parseInt(installedBomData?.tracker_row_id)}`] && listblocks?.updateDistributedAndInstalledQuantity[`${installedBomData?.bom_id}_${parseInt(installedBomData?.tracker_row_id)}`][installedBomData?.found_type] ? getCurrentDistributedAndInstalledQuantity(installedBomData) : getExistingDistributedAndInstalledQuantity(installedBomData)}
                                                        onChange={(e) => handleDistribuetdInstalledQtyOnChange(e, installedBomData, installedBomData?.total_quantity, "installed")} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='flex items-center space-x-3 my-4'>
                                <p className='text-base font-medium mr-5'>Unit of Count
                                    <br />
                                    <span className='text-base'>{getLinkedPartName()}</span>
                                </p>
                                <input type='number' className='border p-2 w-[100px]' defaultValue={getInprogressDefaultValue()} onChange={e => handleChangeUnitOfCounts(parseInt(e.target.value))} />
                                <p className='text-sm'>out of</p>
                                <p>{inprogressTotalPartCount}</p>
                            </div>
                        </div>
                        :
                        <div className='h-[73%] overflow-auto'>
                            <p className='text-sm my-3'>Choose Piers and Bays that are completed</p>
                            {zimmerMan ?
                                <IndividualZimmerTable
                                    type="cp"
                                    data={piersTorqueTubesDetails}
                                    pierData={piersTorqueTubesDetails?.piers}
                                    torqueTubeData={piersTorqueTubesDetails?.torque_tube}
                                    pierLegend={listblocks?.pierLegends}
                                    selectedItem={selectedPiersTorqueTubes}
                                    setSelectedPiersTorqueTubes={setSelectedPiersTorqueTubes} // selected pier and TT
                                    clearState={false}
                                /> :
                                <IndividualTrackerRow
                                    type="cp"
                                    data={piersTorqueTubesDetails}
                                    pierData={piersTorqueTubesDetails?.piers}
                                    torqueTubeData={piersTorqueTubesDetails?.torque_tube}
                                    pierLegend={listblocks?.pierLegends}
                                    selectedItem={selectedPiersTorqueTubes}
                                    setSelectedPiersTorqueTubes={setSelectedPiersTorqueTubes} // selected pier and TT
                                    clearState={false}
                                />
                            }
                        </div>
                    }
                    <hr />
                    <div className='my-5'>
                        {inprogressIndividualTrackerModal?.step === 1 ?
                            enableNextBtn ?
                                <div className='flex justify-end'>
                                    <button className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full' onClick={() => setInprogressIndividualTrackerModal(produce((draft) => { draft.step = 2 }))}>
                                        Next
                                    </button>
                                </div>
                                :
                                <div className='flex justify-end'>
                                    <button className='cursor-pointer px-6 py-2 bg-gray-400 text-white rounded-full'>
                                        Next
                                    </button>
                                </div>
                            :
                            <div className='flex justify-between'>
                                <p className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full' onClick={() => setInprogressIndividualTrackerModal(produce((draft) => { draft.step = 1 }))}>Back</p>
                                {enableSaveButton ?
                                    <p className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full' onClick={handleSaveInProgressSteps}>Save</p>
                                    :
                                    <p className='px-6 py-2 bg-gray-400 text-white rounded-full'>Save</p>
                                }
                            </div>
                        }
                    </div>
                </div >
            </Modal >
            <Modal
                isOpen={individualTrackerAlertModal}
                style={alertModalStyles}
                contentLabel='Individual Tracker alert modal'
            >
                <div className=''>
                    <p className='text-base font-semibold my-5 pl-3 pr-16'>All the entered data within this tracker type will be lost. Please confirm</p>
                    <div className='flex space-x-4 justify-end text-sm font-normla'>
                        <button className='px-4 py-2 rounded-full text-gray-500 border' onClick={() => setIndividualTrackerAlertModal(false)}>Cancel</button>
                        <button className='px-4 py-2 rounded-full bg-red-550 text-white' onClick={() => { setIndividualTrackerAlertModal(false); closeInProgressTrackerBomModal(); handleCloseWithoutSaving() }}>Confirm</button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default InProgressstepperModal
