import React, { useEffect, useRef, useState } from 'react'
import * as d3 from "d3";
import produce from 'immer';
// import { LiaLocationArrowSolid } from "react-icons/lia";
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';


function IndividualTrackerRow(props) {

    console.log(props)

    const setSelectedPiersTorqueTubes = props?.setSelectedPiersTorqueTubes
    const selectedPiersTorqueTubes = props?.selectedItem

    const selectedPiersTorqueTubesRef = useRef({ piers: [], torque_tubes: [] });
    const containerRef = useRef(null);

    useEffect(() => {
        renderMap()
    }, [props?.selectedItem, props.selectedTab])

    useEffect(() => {
        if (props.clearState === false) {
            setSelectedPiersTorqueTubes({ piers: [], torque_tubes: [] })
            selectedPiersTorqueTubesRef.current = { piers: [], torque_tubes: [] }
        }
    }, [props.clearState])

    useEffect(() => {
        if (props?.selectedItem) {
            setSelectedPiersTorqueTubes({ piers: props?.selectedItem["piers"], torque_tubes: props?.selectedItem["torque_tubes"] })
            selectedPiersTorqueTubesRef.current = { piers: props?.selectedItem["piers"], torque_tubes: props?.selectedItem["torque_tubes"] }
        }
    }, [])

    const renderMap = () => {
        containerRef.current.innerHTML = "";
        if (props?.selectedItem) {
            let selectedPierData = []
            let selectedTorqueTubeData = []
            if (props?.type === "qc") {
                if (props?.selectedTab === "piers") {
                    if (props?.selectedItem.length > 1) {
                        if (parseInt(props.mapType) === 0) {
                            for (let i of props?.selectedItem) {
                                let pierFilter = props.pierData.filter(o => o.

                                    id === i)
                                if (pierFilter[0]) {
                                    selectedPierData.push(pierFilter[0])
                                }
                            }
                        }
                    } else {
                        let pierFilter = props.pierData.filter(o => o.id === props?.selectedItem)
                        selectedPierData.push(pierFilter[0])

                    }
                }
            } else {
                for (let i of props?.selectedItem["piers"]) {
                    let pierFilter = props.pierData.filter(o => o.id === i)
                    if (pierFilter[0]) {
                        selectedPierData.push(pierFilter[0])
                    }
                }
            }
            if (selectedPierData.length > 0) {
                for (let pr of selectedPierData) {
                    d3.select(containerRef.current)
                        .append("rect")
                        .attr("x", 18)
                        .attr("y", pr?.pier_part_order * 40 - 2)
                        .attr("width", 25)
                        .attr("height", 25)
                        .attr("fill", props?.type === "qc" ? "#F6BE00" : "green")
                }
            }
            if (props?.type === "qc") {
                if (props?.selectedTab !== "piers") {
                    if (parseInt(props.mapType) === 0) {
                        for (let j of props?.selectedItem) {
                            let ttFilter = props.torqueTubeData.filter(o => o.id === j)
                            if (ttFilter[0]) {
                                selectedTorqueTubeData.push(ttFilter[0])
                            }
                        }
                    } else {
                        let ttFilter = props.torqueTubeData.filter(o => o.id === props?.selectedItem)
                        selectedTorqueTubeData.push(ttFilter[0])
                    }
                }
            } else {
                for (let j of props?.selectedItem["torque_tubes"]) {
                    let ttFilter = props.torqueTubeData.filter(o => o.id === j)
                    if (ttFilter[0]) {
                        selectedTorqueTubeData.push(ttFilter[0])
                    }
                }
            }
            if (selectedTorqueTubeData.length > 0) {
                for (let tt of selectedTorqueTubeData) {
                    d3.select(containerRef.current)
                        .append("rect")
                        .attr("x", 22)
                        .attr("y", tt?.tt_part_order * 40 + 10)
                        .attr("width", 16)
                        .attr("height", 40)
                        .attr("fill", props?.type === "qc" ? "#F6BE00" : "green")
                }
            }
        }

        for (let key of props?.torqueTubeData) {
            if (key?.tt_part_tracker_row_type === undefined) {
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("class", "tracker-row")
                    .attr("x", 28)
                    .attr("y", key?.tt_part_order * 40 + 10)
                    .attr("width", "5px")
                    .attr("height", 33)
                    .attr("stroke", "gray")
                    .attr("stroke-width", "1px")
                    .attr("fill", "gray")
                    .on("click", () => {

                        if (props?.type === "qc") {
                            if (props.selectedTab !== "piers") {
                                props.onSelectItem({ id: key?.id, type_id: key?.tt_type_id, part_number: Math.round(key?.tt_part_part_number) })
                            }
                        } else {

                            if (!selectedPiersTorqueTubesRef.current.torque_tubes.includes(key?.id)) {

                                if (selectedPiersTorqueTubes["torque_tubes"].length > 0) {
                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                } else {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = [key?.id];
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = [key?.id];
                                }
                            }
                            else {
                                let filteredData = selectedPiersTorqueTubes["torque_tubes"].filter((r) => r !== key?.id)
                                setSelectedPiersTorqueTubes(
                                    produce((draft) => {
                                        draft["torque_tubes"] = filteredData
                                    })
                                );
                                selectedPiersTorqueTubesRef.current.torque_tubes = filteredData
                            }
                        }
                    })
            }
            if (key?.tt_part_tracker_row_type === 0) {
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("class", "tracker-row")
                    .attr("x", 28)
                    .attr("y", key?.tt_part_order * 40 + 10)
                    .attr("width", "5px")
                    .attr("height", 33)
                    .attr("stroke", "gray")
                    .attr("stroke-width", "1px")
                    .attr("fill", "gray")
                    .on("click", () => {
                        if (props?.type === "qc") {
                            if (props.selectedTab !== "piers") {
                                props.onSelectItem({ id: key?.id, type_id: key?.tt_type_id, part_number: Math.round(key?.tt_part_part_number) })
                            }
                        } else {

                            if (!selectedPiersTorqueTubesRef.current.torque_tubes.includes(key?.id)) {

                                if (selectedPiersTorqueTubes["torque_tubes"].length > 0) {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                } else {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = [key?.id];
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = [key?.id];
                                }
                            }
                            else {
                                let filteredData = selectedPiersTorqueTubes["torque_tubes"].filter((r) => r !== key?.id)
                                setSelectedPiersTorqueTubes(
                                    produce((draft) => {
                                        draft["torque_tubes"] = filteredData
                                    })
                                );
                                selectedPiersTorqueTubesRef.current.torque_tubes = filteredData
                            }
                        }
                    })
            }
            if (key?.tt_part_tracker_row_type === 1) {
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("class", "tracker-row")
                    .attr("x", 28)
                    .attr("y", key?.tt_part_order * 40 + 10)
                    .attr("width", "5px")
                    .attr("height", 33)
                    .attr("stroke", "gray")
                    .attr("stroke-width", "1px")
                    .attr("fill", "white")
                    .on("click", () => {
                        if (props?.type === "qc") {
                            if (props.selectedTab !== "piers") {
                                props.onSelectItem({ id: key?.id, type_id: key?.tt_type_id, part_number: Math.round(key?.tt_part_part_number) })
                            }
                        } else {
                            if (!selectedPiersTorqueTubesRef.current.torque_tubes.includes(key?.id)) {
                                if (selectedPiersTorqueTubes["torque_tubes"].length > 0) {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                } else {
                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = [key?.id];
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = [key?.id];
                                }
                            }
                            else {
                                let filteredData = selectedPiersTorqueTubes["torque_tubes"].filter((r) => r !== key?.id)
                                setSelectedPiersTorqueTubes(
                                    produce((draft) => {
                                        draft["torque_tubes"] = filteredData
                                    })
                                );
                                selectedPiersTorqueTubesRef.current.torque_tubes = filteredData

                            }
                        }
                    })
            }
            if (key?.tt_part_tracker_row_type === 2) {
                d3.select(containerRef.current)
                    .append("line")
                    .attr("class", "tracker-row")
                    .attr("x1", 30)
                    .attr("y1", key?.tt_part_order * 40 + 15)
                    .attr("x2", 30)
                    .attr("y2", key?.tt_part_order * 40 + 49)
                    .attr("stroke-width", "5px")
                    .attr("stroke", "gray")
                    .attr("stroke-dasharray", "1 1")
                    .on("click", () => {
                        if (props?.type === "qc") {
                            if (props.selectedTab !== "piers") {
                                props.onSelectItem({ id: key?.id, type_id: key?.tt_type_id, part_number: Math.round(key?.tt_part_part_number) })
                            }
                        }
                        else {

                            if (!selectedPiersTorqueTubesRef.current.torque_tubes.includes(key?.id)) {
                                if (selectedPiersTorqueTubes["torque_tubes"].length > 0) {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = ([...selectedPiersTorqueTubes["torque_tubes"], key?.id]);
                                } else {

                                    setSelectedPiersTorqueTubes(
                                        produce((draft) => {
                                            draft["torque_tubes"] = [key?.id];
                                        })
                                    );
                                    selectedPiersTorqueTubesRef.current.torque_tubes = [key?.id];
                                }
                            }
                            else {
                                let filteredData = selectedPiersTorqueTubes["torque_tubes"].filter((r) => r !== key?.id)
                                setSelectedPiersTorqueTubes(
                                    produce((draft) => {
                                        draft["torque_tubes"] = filteredData
                                    })
                                );
                                selectedPiersTorqueTubesRef.current.torque_tubes = filteredData
                            }
                        }
                    })
            }
        }
        let pier = d3
            .select(containerRef.current)
            .selectAll("pier")
            .data(props.pierData)
            .enter()
            .append("g")
            .attr("class", (pr) => `trow-${pr["id"]}-${pr["pier_type_id"]}`)
            .attr("id", (pr) => `pier-${pr["id"]}-${pr["pier_type_id"]}`);

        let torqueTube = d3
            .select(containerRef.current)
            .selectAll("pier")
            .data(props.torqueTubeData)
            .enter()
            .append("g")
            .attr("class", (pr) => `trow-${pr["id"]}-${pr["tt_type_id"]}`)
            .attr("id", (pr) => `pier-${pr["id"]}-${pr["tt_type_id"]}`);


        pier.filter((pr) => {
            let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
            if (!prlegend) {
                return true
            }
        })

        pier.filter((pr) => {
            let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
            if (prlegend) {
                return prlegend.shape === "Circle";
            }
        })
            .append("circle")
            .attr("class", "pier")
            .attr("cx", 30)
            .attr("cy", (pr) => pr.pier_part_order * 40 + 10)
            .attr("r", 7)
            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props?.type === "qc") {
                    if (props.selectedTab === "piers") {
                        props.onSelectItem({ id: pr?.id, type_id: pr?.pier_type_id, part_number: pr?.pier_part_part_number })
                    }
                } else {
                    // if (props.selectedTab === "piers") {

                    if (!selectedPiersTorqueTubesRef.current.piers.includes(pr?.id)) {
                        if (selectedPiersTorqueTubes["piers"].length > 0) {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                        } else {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = [pr?.id];
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = [pr?.id];
                        }
                    }
                    else {
                        let filteredData = selectedPiersTorqueTubes["piers"].filter((r) => r !== pr?.id)
                        setSelectedPiersTorqueTubes(
                            produce((draft) => {
                                draft["piers"] = filteredData
                            })
                        );
                        selectedPiersTorqueTubesRef.current.piers = filteredData
                    }
                }
            })

        pier
            .filter((pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                if (prlegend) {
                    return prlegend.shape === "Square";
                }
            })
            .append("rect")
            .attr("class", "pier")
            .attr("id", (_, pri) => `pier-${pri + 1}`)
            .attr("height", 14)
            .attr("width", 14)
            .attr("x", 24)
            .attr("y", (pr) => pr.pier_part_order * 40 + 4)
            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props?.type === "qc") {
                    if (props.selectedTab === "piers") {
                        props.onSelectItem({ id: pr?.id, type_id: pr?.pier_type_id, part_number: pr?.pier_part_part_number })
                    }
                } else {
                    // if (props.selectedTab === "piers") {

                    if (!selectedPiersTorqueTubesRef.current.piers.includes(pr?.id)) {

                        if (selectedPiersTorqueTubes["piers"].length > 0) {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                        } else {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = [pr?.id];
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = [pr?.id];
                        }
                    }
                    else {
                        let filteredData = selectedPiersTorqueTubes["piers"].filter((r) => r !== pr?.id)
                        setSelectedPiersTorqueTubes(
                            produce((draft) => {
                                draft["piers"] = filteredData
                            })
                        );
                        selectedPiersTorqueTubesRef.current.piers = filteredData
                    }
                }
            })

        pier
            .filter((pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                if (prlegend) {
                    return prlegend.shape === "Diamond";
                }
            })
            .append("rect")
            .attr("class", "pier")
            .attr("id", (_, pri) => `pier-${pri + 1}`)
            .attr("height", 14)
            .attr("width", 14)
            .attr("x", 20)
            .attr("y", (pr) => pr.pier_part_order * 40 - 5)
            .attr(
                "transform",
                (pr) => `translate(20, ${pr?.pier_part_order * 40 + 10}) rotate(45) translate(-20, -${pr?.pier_part_order * 40 + 10})`
            )

            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[parseInt(Math.round(pr.pier_part_part_number))]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props?.type === "qc") {
                    if (props.selectedTab === "piers") {
                        props.onSelectItem({ id: pr?.id, type_id: pr?.pier_type_id, part_number: pr?.pier_part_part_number })
                    }
                } else {
                    // if (props.selectedTab === "piers") {

                    if (!selectedPiersTorqueTubesRef.current.piers.includes(pr?.id)) {

                        if (selectedPiersTorqueTubes["piers"].length > 0) {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
                        } else {
                            setSelectedPiersTorqueTubes(
                                produce((draft) => {
                                    draft["piers"] = [pr?.id];
                                })
                            );
                            selectedPiersTorqueTubesRef.current.piers = [pr?.id];
                        }
                    }
                    else {
                        let filteredData = selectedPiersTorqueTubes["piers"].filter((r) => r !== pr?.id)
                        setSelectedPiersTorqueTubes(
                            produce((draft) => {
                                draft["piers"] = filteredData
                            })
                        );
                        selectedPiersTorqueTubesRef.current.piers = filteredData
                    }
                }
            })
        // if (props?.type === "qc") {
        //     if (props.selectedTab === "piers") {
        //         pier
        //             .append("text")
        //             .attr("fill", (pr) => pr.id === props?.selectedItem ? "#F6BE00" : "black")
        //             .attr("x", 60)
        //             .attr("y", (pr) => pr.pier_part_order * 40 + 15)
        //             .attr("text-anchor", "middle")
        //             .style("font-size", "10px")
        //             .style("cursor", "pointer")
        //             .style("font-weight", (pr) => pr.id === props?.selectedItem ? 700 : 600)
        //             .text((pr) => pr.id ? Math.round(pr.id) : null)
        //             .on("click", (_, pr) => {
        //                 if (props?.type === "qc") {
        //                     if (props.selectedTab === "piers") {
        //                         props.onSelectItem({ id: pr?.id, type_id: pr?.pier_type_id, part_number: Math.round(pr?.pier_part_part_number) })
        //                     }
        //                 } else {
        //                     // if (props.selectedTab === "piers") {
        //                     if (!selectedPiersTorqueTubesRef.current.piers.includes(pr?.id)) {

        //                         if (selectedPiersTorqueTubes["piers"].length > 0) {
        //                             setSelectedPiersTorqueTubes(
        //                                 produce((draft) => {
        //                                     draft["piers"] = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
        //                                 })
        //                             );
        //                             selectedPiersTorqueTubesRef.current.piers = ([...selectedPiersTorqueTubes["piers"], pr?.id]);
        //                         } else {
        //                             setSelectedPiersTorqueTubes(
        //                                 produce((draft) => {
        //                                     draft["piers"] = [pr?.id];
        //                                 })
        //                             );
        //                             selectedPiersTorqueTubesRef.current.piers = [pr?.id];
        //                         }
        //                     }
        //                     else {
        //                         let filteredData = selectedPiersTorqueTubes["piers"].filter((r) => r !== pr?.id)
        //                         setSelectedPiersTorqueTubes(
        //                             produce((draft) => {
        //                                 draft["piers"] = filteredData
        //                             })
        //                         );
        //                         selectedPiersTorqueTubesRef.current.piers = filteredData
        //                     }
        //                 }
        //             })
        //     }
        //     else {
        //         torqueTube
        //             .append("text")
        //             .attr("fill", (pr) => pr.id === props?.selectedItem ? "#F6BE00" : "black")
        //             .attr("x", 60)
        //             .attr("y", (pr) => pr.tt_part_order * 40 + 35)
        //             .attr("text-anchor", "middle")
        //             .style("font-size", "10px")
        //             .style("cursor", "pointer")
        //             .style("font-weight", (pr) => pr.id === props?.selectedItem ? 700 : 600)
        //             .text((pr) => pr.id ? Math.round(pr.id) : null)
        //             .on("click", (_, pr) => {
        //                 if (props?.type === "qc") {
        //                     if (props.selectedTab !== "piers") {
        //                         props.onSelectItem({ id: pr?.id, type_id: pr?.tt_type_id, part_number: Math.round(pr?.tt_part_part_number) })
        //                     }
        //                 } else {

        //                     if (!selectedPiersTorqueTubesRef.current.torque_tubes.includes(pr?.id)) {

        //                         if (selectedPiersTorqueTubes["torque_tubes"].length > 0) {

        //                             setSelectedPiersTorqueTubes(
        //                                 produce((draft) => {
        //                                     draft["torque_tubes"] = ([...selectedPiersTorqueTubes["torque_tubes"], pr?.id]);
        //                                 })
        //                             );
        //                             selectedPiersTorqueTubesRef.current.torque_tubes = ([...selectedPiersTorqueTubes["torque_tubes"], pr?.id]);
        //                         } else {

        //                             setSelectedPiersTorqueTubes(
        //                                 produce((draft) => {
        //                                     draft["torque_tubes"] = [pr?.id];
        //                                 })
        //                             );
        //                             selectedPiersTorqueTubesRef.current.torque_tubes = [pr?.id]
        //                         }
        //                     }
        //                     else {
        //                         let filteredData = selectedPiersTorqueTubes["torque_tubes"].filter((r) => r !== pr?.id)
        //                         setSelectedPiersTorqueTubes(
        //                             produce((draft) => {
        //                                 draft["torque_tubes"] = filteredData
        //                             })
        //                         );
        //                         selectedPiersTorqueTubesRef.current.torque_tubes = filteredData
        //                     }
        //                 }
        //             })
        //     }
        // }

        pier
            .append("line")
            .attr("x1", 26)
            .attr("y1", (pr) => pr.pier_part_order * 40 + 10)
            .attr("x2", 34)
            .attr("y2", (pr) => pr.pier_part_order * 40 + 10)
            .attr("stroke", "black");
    }

    const getInitialScaleSize = () => {
        let initialScale = 0.9
        if (props?.pierData) {
            if (props?.pierData.length > 10) {
                initialScale = 1
            }
            return initialScale
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: `${props?.width}px`, justifyContent: "center" }}>
            {props?.type === "qc" ?

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", width: `${props.width}px` }}>
                    {/* <LiaLocationArrowSolid fontSize={32} /> */}
                    <p style={{ fontSize: "16px", marginTop: "-3px", fontWeight: "500" }}>N</p>
                    <div style={{ marginLeft: "35px" }}>
                        <svg ref={containerRef} style={{ height: "65vh", width: "100px" }} />
                    </div>
                </div>
                :
                <TransformWrapper minScale={0.5} initialScale={getInitialScaleSize()} alignmentAnimation={{ disabled: true }}>
                    <TransformComponent contentStyle={{ height: "60vh", width: 1100 }} wrapperStyle={{ height: "100%", width: "100%" }}>
                        <svg ref={containerRef} style={{ height: 700, width: "65px", margin: "auto" }} />
                    </TransformComponent>
                </TransformWrapper>
            }

        </div>


    )
}

export default IndividualTrackerRow