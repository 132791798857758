import React, { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "react-modern-drawer/dist/index.css";
import { VscClose } from "react-icons/vsc";
import { useDropzone } from "react-dropzone";
import { MdPersonAddAlt1 } from "react-icons/md";
import Modal from "react-modal"
import { RiEdit2Fill } from "react-icons/ri"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { useAtom } from "jotai";
import staffAtom, { addNewUserAtom, fetchImportStaffAtom } from "../../../atoms/staffAtom";
import { toast } from "react-hot-toast";
import { TbCameraPlus } from "react-icons/tb";
import { IoMdRemoveCircleOutline } from "react-icons/io"
import axios from "axios";
import { v4 as uuid } from "uuid";
import ReactTooltip from 'react-tooltip'
import produce from "immer";
import { countryCode } from "./countryCode";

function AddStaffMulti(props) {

    const [listStaff] = useAtom(staffAtom);
    const [, addStaff] = useAtom(addNewUserAtom);
    const [, setFetchImportStaffs] = useAtom(fetchImportStaffAtom)
    const [imageBoolean, setImageBoolean] = useState(false);
    const [images, setImages] = useState();
    const [profileImg, setProfileImg] = useState()
    const [userName, setUserName] = useState({ firstName: "", lastName: "" })
    const [idProof, setIdProof] = useState([])
    const [documents, setDocuments] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [editImageHover, setEditImageHover] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [existStaffModel, setExistStaffModel] = useState(false)
    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [idRenameBox, setIdRenameBox] = useState([])
    const [idNameEdit, setIdNameEdit] = useState([])
    const [idNameChange, setIdNameChange] = useState()
    const [idCount, setIdCount] = useState([1])
    // const [idNameSave, setIdNameSave] = useState()

    const [docRenameBox, setDocRenameBox] = useState([])
    const [docNameEdit, setDocNameEdit] = useState([])
    const [docNameChange, setDocNameChange] = useState()
    const [docCount, setDocCount] = useState([1])

    const { register, handleSubmit, reset } = useForm();

    function checkStaffDataExist(list) {
        let temp = list.find(o => o?.user?.name.toLowerCase() === userName.firstName.toLowerCase() && o?.user?.profile_data?.last_name.toLowerCase() === userName?.lastName.toLowerCase())
        let result
        if (temp) {
            result = true
        }
        else {
            result = false
        }
        return result
    }

    const checkSubmitStaff = (data) => {
        if (checkStaffDataExist(props.userList)) {
            props.setIsOpen(false)
            setDuplicateEntry(data)
            setExistStaffModel(true)
            props.existStaffModelRef.current = true
        }
        else {
            onSubmit(data)
        }
    }

    const onSubmit = async (form) => {
        try {
            setBtnLoading(true)

            let tempData = {}

            tempData.email = form?.email
            tempData.name = userName?.firstName
            tempData.profile_data = {}
            tempData.profile_data.last_name = userName?.lastName
            tempData.profile_data.number = form?.number
            tempData.profile_data.role_auth = form?.role_auth
            tempData.profile_data.country_code = form?.country_code

            tempData.profile_data.other_documents = []
            // tempData.is_staff = true

            if (idProof.length > 0) {
                for (let key in idProof) {
                    let idFormData = new FormData();
                    let idProofFile = new File([idProof[key]], `${idNameEdit[key]}_${uuid()}.${idNameEdit[key].split('.').at(-1)}`);
                    idFormData.append("file", idProofFile)
                    let IdRes = await axios.post(`/file_upload/upload/image?org=True`, idFormData, { headers: { "Content-Type": "multipart/form-data" } })
                    form.id_proof = IdRes.data;
                    tempData.profile_data.other_documents.push(
                        {
                            type: 0,
                            upload_id: IdRes.data,
                            name: idNameEdit[key]
                        }
                    )
                }
            }
            else {
                tempData.profile_data.other_documents.push(
                    {
                        type: 0,
                        upload_id: null,
                        name: null
                    }
                )
            }
            if (documents.length > 0) {
                for (let key in documents) {
                    let docFormData = new FormData();
                    let docFile = new File([documents[key]], `${docNameEdit[key]}_${uuid()}.${docNameEdit[key].split('.').at(-1)}`);
                    docFormData.append("file", docFile)
                    let docRes = await axios.post(`/file_upload/upload/image?org=True`, docFormData, { headers: { "Content-Type": "multipart/form-data" } })
                    tempData.profile_data.other_documents.push(
                        {
                            type: 1,
                            upload_id: docRes.data,
                            name: docNameEdit[key]
                        }
                    )
                }
            }
            else {
                tempData.profile_data.other_documents.push(
                    {
                        type: 1,
                        upload_id: null,
                        name: null
                    }
                )
            }
            if (profileImg) {
                let profileFormData = new FormData();
                let profileFile = new File([profileImg], `${uuid()}.${profileImg.name.split('.').at(-1)}`);
                profileFormData.append("file", profileFile)
                let profileRes = await axios.post(`/file_upload/upload/image?org=True`, profileFormData, { headers: { "Content-Type": "multipart/form-data" } })
                form.profile_photo = profileRes.data;
                tempData.profile_data.other_documents.push(
                    {
                        type: 3,
                        upload_id: profileRes.data,
                        name: profileImg?.name
                    }
                )
            }
            else {
                tempData.profile_data.other_documents.push(
                    {
                        type: 3,
                        upload_id: null,
                        name: null
                    }
                )
            }

            await addStaff({ data: tempData, org: props.orgView });
            await setFetchImportStaffs()
            reset();
            setImages();
            setIdProof([]);
            setDocuments([]);
            setIdRenameBox([])
            setIdNameEdit([])
            setIdNameChange()
            setDocRenameBox([])
            setDocNameEdit([])
            setDocNameChange()
            setProfileImg()
            setIdCount([1])
            setDocCount([1])
            props.setIsOpen(false);
            props.existStaffModelRef.current = false
            setExistStaffModel(false)
            toast.success("Staff Created")
        } catch (err) {
            console.log(err);
            toast.error("Unable to add Staff");
        }
        finally {
            setBtnLoading(false)
            setUserName({ firstName: "", lastName: "" })
        }
    };

    const handleProfileImageChange = (e) => {
        setImages(URL.createObjectURL(e.target.files[0]));
        setProfileImg(e.target.files[0])
    };

    const onDrop = useCallback((acceptedFiles) => { }, []);

    const imageHover = () => {
        setImageBoolean(true);
    };

    const imageLeave = () => {
        setImageBoolean(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleIdChange = async (e, index) => {
        let temp = e.target.files[0].name.split(".")[1]
        if (temp === "png" || temp === "jpg" || temp === "pdf") {
            let data = [...idProof]
            data[index] = e.target.files[0]
            let dataName = [...idNameEdit]
            dataName[index] = e.target.files[0].name
            setIdProof(data)
            setIdNameEdit(dataName)
        }
        else {
            toast.error("File type must be png or jpg or pdf")
        }
    }

    const handleDocumentChange = (e, index) => {
        let temp = e.target.files[0].name.split(".")[1]
        if (temp === "png" || temp === "jpg" || temp === "pdf") {
            let data = [...documents]
            data[index] = e.target.files[0]
            let dataName = [...docNameEdit]
            dataName[index] = e.target.files[0].name
            setDocuments(data)
            setDocNameEdit(dataName)
        }
        else {
            toast.error("File type must be png or jpg or pdf")
        }
    }

    const handleDocNameChange = (index) => {
        setDocRenameBox(produce((draft) => { draft[index] = false }))
        let temp = [...docNameEdit]
        temp[index] = docNameChange
        setDocNameEdit(temp)
        setDisableSubmit(false)
    }

    const handleIdNameChange = (index) => {
        setIdRenameBox(produce((draft) => { draft[index] = false }))
        let temp = [...idNameEdit]
        temp[index] = idNameChange
        setIdNameEdit(temp)
        setDisableSubmit(false)
    }

    const handleAddDoc = () => {
        let temp = [1]
        for (let i = 0; i < docCount.length;) {
            temp.push(i + 2)
            i++
        }
        setDocCount(temp)
    }

    const handleRemoveDoc = (count, index) => {
        let temp = docCount.filter(item => item !== count)
        if (documents.length > 0) {
            documents.splice(index, 1)
            docNameEdit.splice(index, 1)
        }
        setDocCount(temp)
    }

    const handleAddID = () => {
        let temp = [1]
        for (let i = 0; i < idCount.length;) {
            temp.push(i + 2)
            i++
        }
        setIdCount(temp)
    }

    const handleRemoveID = (count, index) => {
        let temp = idCount.filter(item => item !== count)
        if (idProof.length > 0) {
            idProof.splice(index, 1)
            idNameEdit.splice(index, 1)
        }
        setIdCount(temp)
    }

    const handleViewExistData = () => {
        props.viewExistData(userName)
        setExistStaffModel(false)
        reset()
    }

    const existStaffStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    return (
        <div>
            <form
                onSubmit={handleSubmit(checkSubmitStaff)}
                className="menu p-4 bg-base-100 text-base-content"
                style={{ width: "635px" }}
            >
                <div className="overflow-y-scroll mr-5" style={{ height: "85vh" }}>
                    <div className="flex justify-between mt-4">
                        <p className="text-xl font-bold ml-4 mt-2">Add Employee Details</p>
                        <VscClose
                            className="cursor-pointer mt-1 mr-3 hover:bg-gray-200 rounded-full"
                            onClick={props.onClose}
                            style={{ width: "40px", height: "40px" }}
                        />
                    </div>

                    <div className="w-28">
                        <label className="mt-8 ml-4">
                            <div
                                className="border-2 rounded-full ml-4 h-20 w-20 cursor-pointer"
                                onMouseEnter={imageHover}
                                onMouseLeave={imageLeave}
                            >
                                <input
                                    name="profile"
                                    type="file"
                                    onChange={handleProfileImageChange}
                                    style={{ display: "none" }}
                                />
                                {images ? (
                                    <div className='relative' onMouseEnter={() => { setEditImageHover(true) }} onMouseLeave={() => { setEditImageHover(false) }}>
                                        {editImageHover ?
                                            <p className='absolute text-sm font-semibold bottom-4 left-4'>Update<br />Photo</p>
                                            :
                                            null
                                        }
                                        <img src={images} alt="" className="h-20 w-20 rounded-full" style={{ opacity: editImageHover ? 0.5 : 1 }} />
                                    </div>
                                ) : imageBoolean ? (
                                    <TbCameraPlus
                                        className="m-auto mt-7 text-gray-400"
                                        style={{ width: "23px", height: "23px" }}
                                    />
                                ) : (
                                    <MdPersonAddAlt1
                                        className="m-auto mt-7 text-gray-400"
                                        style={{ width: "23px", height: "23px" }}
                                    />
                                )}
                            </div>
                        </label>
                    </div>

                    <div className="flex mt-2 mb-3 ml-4">
                        <input
                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7"
                            style={{ width: "250px" }}
                            placeholder="First Name *"
                            required
                            {...register("first_name")}
                            onChange={e => setUserName(produce((draft) => { draft.firstName = e.target.value }))}
                        />
                        <input
                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                            style={{ width: "250px" }}
                            placeholder="Last Name *"
                            required
                            {...register("last_name")}
                            onChange={e => setUserName(produce((draft) => { draft.lastName = e.target.value }))}
                        />
                    </div>
                    <div className="ml-4">
                        <input
                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                            style={{ width: "528px" }}
                            placeholder="Job Title"
                            {...register("role_auth")}
                        />
                    </div>
                    <div className="ml-4">
                        <input
                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                            style={{ width: "528px" }}
                            placeholder="Email"
                            {...register("email")}
                        />
                    </div>
                    <div className="flex ml-4 mb-5">
                        <select className="select select-bordered w-full max-w-xs appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 focus:outline-none" style={{ width: "150px" }} {...register("country_code")}>
                            <option selected hidden>Country Code</option>
                            {countryCode.map((i) => {
                                return (
                                    <option value={i.name}>
                                        {i.name}
                                        ({i.code})
                                    </option>
                                )
                            })}
                        </select>
                        <input
                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                            style={{ width: "350px" }}
                            placeholder="Mobile Number"
                            {...register("number")}
                        />
                    </div>

                    {/* <div> */}
                    <div className="text-xl font-bold ml-4">
                        <div className="flex">
                            Photo ID
                            <AiOutlineInfoCircle data-tip data-for="photo-id" className="mt-1 ml-2 text-gray-400 cursor-pointer" />
                            <ReactTooltip effect='solid' place='top' id="photo-id">
                                <div>You can add upto 5 documents</div>
                            </ReactTooltip>
                        </div>

                        {idCount?.map((count, index) => {
                            return (
                                <div className="mt-5">
                                    {!idProof[index] ?
                                        <div
                                            className=" mt-5 border-2 border-dashed border-gray rounded-2xl"
                                            style={{ width: "528px", height: "155px" }}
                                        >
                                            <div className='flex justify-end'>
                                                {idCount.length > 1 ?
                                                    <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleRemoveID(count, index)} />
                                                    :
                                                    <p className="m-4" />
                                                }
                                            </div>
                                            <label className="cursor-pointer">
                                                <input
                                                    {...getInputProps()}
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={e => handleIdChange(e, index)}
                                                />
                                                {isDragActive ? (
                                                    <div className="font-normal text-center">
                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                        <p className="text-xs mt-4">
                                                            Supported files : JPG, PNG, PDF
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="font-normal text-center">
                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                        <p className="text-xs mt-4">
                                                            Supported files : JPG, PNG, PDF
                                                        </p>
                                                    </div>
                                                )}
                                            </label>
                                        </div>
                                        :
                                        <div className="mt-5 mb-5">
                                            <div className="flex justify-between">
                                                <div className="flex">
                                                    <p className="text-sm font-semibold">{idNameEdit[index]}</p>
                                                    <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" onClick={() => { setIdRenameBox(produce((draft) => { draft[index] = true })); setDisableSubmit(true) }} style={{ width: "20px", height: "20px" }} />
                                                </div>
                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => { setIdProof(produce((draft) => { draft.splice(index, 1) })); setDisableSubmit(false) }} />
                                            </div>
                                            {idRenameBox[index] ?
                                                <div className="flex">
                                                    <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={idNameEdit[index]?.split(".")[0]} onChange={e => setIdNameChange(e.target.value + "." + idNameEdit[index]?.split(".")[1])} />
                                                    <p className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setIdRenameBox(produce((draft) => { draft[index] = false })); setDisableSubmit(false) }}>Cancel</p>
                                                    <p className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleIdNameChange(index)}>Save</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        {idCount.length < 5 ?
                            <p className="text-gray-400 flex justify-end font-medium text-base cursor-pointer m-5 mr-8" onClick={handleAddID}>+ Add More</p>
                            :
                            null
                        }

                    </div>

                    <div className="mt-5 text-xl font-bold ml-4">
                        <div className="flex">
                            Other Documents
                            <AiOutlineInfoCircle data-tip data-for="other-documents" className="mt-1 ml-2 text-gray-400 cursor-pointer" />
                            <ReactTooltip effect='solid' place='top' id="other-documents">
                                <div>You can add upto 5 documents</div>
                            </ReactTooltip>
                        </div>
                        {docCount.map((count, index) => {
                            return (
                                <div className="mt-5">
                                    {!documents[index] ?
                                        <div
                                            className=" mt-5 border-2 border-dashed border-gray rounded-2xl"
                                            style={{ width: "528px", height: "155px" }}
                                        >
                                            <div className='flex justify-end'>
                                                {docCount.length > 1 ?
                                                    <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleRemoveDoc(count, index)} />
                                                    :
                                                    <p className="m-4" />
                                                }
                                            </div>
                                            <label className="cursor-pointer">
                                                <input
                                                    {...getInputProps()}
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={e => handleDocumentChange(e, index)}
                                                />
                                                {isDragActive ? (
                                                    <div className="font-normal text-center">
                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                        <p className="text-sm mt-6">Click or Drop Files here</p>
                                                        <p className="text-xs mt-4">
                                                            Supported files : JPG, PNG, PDF
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="font-normal text-center">
                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                        <p className="text-sm mt-6">Click or Drop Files here</p>
                                                        <p className="text-xs mt-4">
                                                            Supported files : JPG, PNG, PDF
                                                        </p>
                                                    </div>
                                                )}
                                            </label>
                                        </div>
                                        :
                                        <div className="mt-5 mb-5">
                                            <div className="flex justify-between">
                                                <div className="flex">
                                                    <p className="text-sm font-semibold">{docNameEdit[index]}</p>
                                                    <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = true })); setDisableSubmit(true) }} style={{ width: "20px", height: "20px" }} />
                                                </div>

                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => { setDocuments(produce((draft) => { draft.splice(index, 1) })); setDisableSubmit(false) }} />
                                            </div>
                                            {docRenameBox[index] ?
                                                <div className="flex">
                                                    <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={docNameEdit[index]?.split(".")[0]} onChange={e => setDocNameChange(e.target.value + "." + docNameEdit[index]?.split(".")[1])} />
                                                    <p className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = false })); setDisableSubmit(false) }}>Cancel</p>
                                                    <p className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleDocNameChange(index)}>Save</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        {docCount.length < 5 ?
                            <p className="text-gray-400 flex justify-end font-medium text-base cursor-pointer m-5 mr-8" onClick={handleAddDoc}>+ Add More</p>
                            :
                            null
                        }
                    </div>
                    {/* </div> */}
                </div>
                <div className="flex mt-11 justify-end mr-16">
                    <button
                        className="btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500"
                        style={{ width: "100px" }}
                        type="reset"
                        onClick={() => {
                            setImages()
                            setDocuments([])
                            setIdProof([])
                            setIdNameEdit([])
                            setIdNameChange()
                            setIdRenameBox([])
                            setDocRenameBox([])
                            setDocNameEdit([])
                            setProfileImg()
                            setDocNameChange()
                            setDocCount([1])
                            setIdCount([1])
                            props.onClose()
                        }}
                    >
                        Cancel
                    </button>
                    {!btnLoading ?
                        <input
                            className="border-none rounded-3xl bg-red-550 text-white btn"
                            style={{ width: "96px" }}
                            disabled={disableSubmit}
                            value="Save"
                            type="submit"
                        /> :
                        <button
                            className="border-none rounded-3xl bg-red-550 text-white btn loading"
                        >
                            Saving
                        </button>
                    }
                </div>
            </form>
            <Modal
                isOpen={existStaffModel}
                onRequestClose={() => { setExistStaffModel(false); props.existStaffModelRef.current = false }}
                style={existStaffStyle}
                contentLabel="Exist Data Modal"
            >
                <div>
                    <p className='text-base font-medium text-gray-500 my-3'>
                        A record already exists with the same First & Last name, please review or confirm to create a record anyways
                    </p>
                    <hr />
                    <div className="flex justify-end space-x-4 mt-3">
                        <button className='text-red-550'
                            onClick={handleViewExistData}
                        >
                            Review
                        </button>

                        <button className="btn border-none rounded-full bg-red-550 text-white space-x-4 flex items-center"
                            onClick={() => onSubmit(duplicateEntry)}
                        >
                            {btnLoading ?
                                <p className="loader" style={{ width: "25px", height: "25px" }}></p>
                                :
                                null
                            }
                            <p>Confirm</p>
                        </button>

                        <button className='btn border-2 rounded-3xl bg-white text-gray-500 border-gray-500' onClick={() => { setExistStaffModel(false); props.existStaffModelRef.current = false; reset() }}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AddStaffMulti;