import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import React, { useRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import produce from "immer";
import proj4 from "proj4";

export default function ZimmerBlock(props) {
    const {
        deviceType,
        bulkPierData,
        bulkAllTTData,
        tableBays,
        pierLegends,
        type,
        typeData,
        userSelection,
        currentProjectData,
        selectedRow,
        setSelectedRow,
        showLegend,
        mapSize,
        selectAll,
        section,
        prevRow,
        trackerSelect,
        prevTracker,
        clearSelection,
        enableGPSLocation,
        laydown,
        scopeSelection,
        showLaydownNameHover,
        laydownData,
        currentLaydownData,
        initialLoadSelectedLaydownTracker,
        currentBlock,
        loader,
        disableSelection
    } = props;


    const [position, setPosition] = useState([0, 0])

    const containerRef = useRef()
    const downloadRef = useRef()
    const selectedRowRef = useRef([])

    let pierObjs = {}


    const getPrLegend = (data) => {
        let prlegend = pierLegends[data]
        if (pierLegends && prlegend) {
            return prlegend
        }
        else {
            return {
                shape: "circle",
                concentric: false,
                border: "black",
                background: "white",
                width: 1,
                map_color: "#4287f5"
            }
        }
    }

    const handleSelectTable = (data) => {
        if (laydown) {
            if (disableSelection === false) {
                if (bulkAllTTData[data]?.laydown_id === null || bulkAllTTData[data]?.laydown_id === currentLaydownData) {
                    let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(data))
                    let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                    if (!selectedRow.includes(rowName)) {
                        setSelectedRow(produce((draft) => {
                            draft.push(rowName)
                        }))
                    }
                    else {
                        setSelectedRow(
                            produce((draft) => {
                                draft.splice(
                                    draft.findIndex((r) => r === rowName),
                                    1
                                );
                            })
                        );
                    }
                }
            }

        } else {

            let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(data))
            let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
            if (!selectedRow.includes(rowName)) {
                setSelectedRow(produce((draft) => {
                    draft.push(rowName)
                }))
            }
            else {
                setSelectedRow(
                    produce((draft) => {
                        draft.splice(
                            draft.findIndex((r) => r === rowName),
                            1
                        );
                    })
                );
            }
        }

    }

    useEffect(() => {
        if (enableGPSLocation === true) {
            const watchId = navigator.geolocation.watchPosition(
                (pos) => {
                    const { latitude, longitude } = pos.coords;
                    const [east, north] = proj4(`+proj=utm +zone=60 +north +ellps=WGS84 +datum=WGS84 +units=m +no_defs`).forward([longitude, latitude]);
                    setPosition({
                        latitude: east,
                        longitude: north
                    });
                },
                (err) => {
                    console.error(err);
                }
            );
            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        } else {
            setPosition([0, 0])
        }
    }, [enableGPSLocation]);

    // useEffect(() => {
    //     init();
    // }, [selectedRow])

    useEffect(() => {
        sectionCallFn()
    }, [bulkPierData])

    useEffect(() => {
        if (selectAll === true && bulkPierData) {
            let tempData = new Set()
            for (let tt_trackerId in bulkAllTTData) {
                let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(tt_trackerId))
                let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                if (laydown) {
                    if (bulkAllTTData[tt_trackerId]?.laydown_id === null || bulkAllTTData[tt_trackerId]?.laydown_id === currentLaydownData) {
                        tempData.add(rowName)
                    }
                }
                else {
                    tempData.add(rowName)
                }
            }
            setSelectedRow(Array.from(tempData))
            // selectedRowRef.current = temp
        }
    }, [selectAll])

    useEffect(() => {
        if (bulkAllTTData && section) {
            let tempData = new Set()
            for (let tt_trackerId in bulkAllTTData) {
                let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(tt_trackerId))
                let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                if (section.includes(temp?.section)) {
                    if (laydown) {
                        if (bulkAllTTData[tt_trackerId]?.laydown_id === null || bulkAllTTData[tt_trackerId]?.laydown_id === currentLaydownData) {
                            tempData.add(rowName)
                        }
                    }
                    else {
                        tempData.add(rowName)
                    }
                }
            }
            selectedRow.map((i) => {
                if (prevRow !== parseInt(i.split("-")[4])) {
                    tempData.add(i)
                }
            })
            setSelectedRow(Array.from(tempData))
            // selectedRowRef.current = Array.from(tempData)
        }
    }, [section, prevRow])

    useEffect(() => {
        if (bulkAllTTData && trackerSelect) {
            let tempData = new Set()
            for (let tt_trackerId in bulkAllTTData) {
                let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(tt_trackerId))
                let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                if (trackerSelect.includes(temp?.tracker_type_id)) {
                    tempData.add(rowName)
                }
            }
            selectedRow.map((i) => {
                if (!prevTracker.includes(parseInt(i.split("-")[2]))) {
                    tempData.add(i)
                }
            })
            setSelectedRow(Array.from(tempData))
            // selectedRowRef.current = Array.from(temp)
        }
    }, [trackerSelect, prevTracker])

    useEffect(() => {
        if (laydown) {
            if (clearSelection) {
                setSelectedRow([])
                selectedRowRef.current = []
            }
        }
        else {
            setSelectedRow([])
            selectedRowRef.current = []
        }
    }, [clearSelection])

    const getLaydownColor = (laydownId) => {
        // if (laydownId === currentLaydownData) {
        //     return "yellow"
        // }
        if (laydownId !== currentLaydownData && laydownId !== null) {
            return "#66F1AD"
        }
        return "white"

    }

    const sectionCallFn = async () => {
        try {
            if (bulkPierData) {
                let temp = new Set()
                for (let i of bulkPierData) {
                    temp.add(i.section)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const init = async () => {
        let temp = new Set()
        selectedRow.map((i) => {
            temp.add(i)
        })
        try {
            setSelectedRow(selectedRow)
        } catch (err) {
            console.log(err);
        }
    };

    function isNumber(value) {
        return typeof value === 'number';
    }

    function partNumberSplit(data) {
        if (isNumber(data)) {
            return data
        }
        else {
            let temp = data
            return temp
        }
    }

    useEffect(() => {
        if (bulkPierData) {
            renderMap(bulkPierData)
        }
    }, [bulkPierData, selectAll, bulkAllTTData, position, selectedRow, type, userSelection, typeData]);

    const findD3Height = () => {
        let temp = new Set()
        let result
        bulkPierData?.map((i) => {
            temp.add(i?.section)
        })
        result = Array.from(temp).length * 26
        return result
    }

    const findD3Width = () => {
        let temp = new Set()
        let result
        bulkPierData?.map((i) => {
            temp.add(i.row)
        })
        result = Array.from(temp).length * 20
        return result
    }

    function getLaydownName(laydownId) {
        let data = laydownData.find(o => o.id === laydownId)
        return data?.name
    }

    function getTableColor(data) {
        let tracker_row_id = data?.tracker_row_id
        if (userSelection?.selection === "All") {
            if (typeData[`${tracker_row_id}_all`]) {
                if (typeData[`${tracker_row_id}_all`] > 0 && Math.round(typeData[`${tracker_row_id}_all`]) < 99) {
                    return "#FC8EAC"
                }
                if (typeData[`${tracker_row_id}_all`] === 100) {
                    return "#66F1AD"
                }
            }
            return "white"
        }
        if (userSelection?.selection !== "All") {
            if (typeData[`${tracker_row_id}_${userSelection?.selection}`]) {
                if (typeData[`${tracker_row_id}_${userSelection?.selection}`]?.status === 2) {
                    return "#66F1AD"
                }
                if (typeData[`${tracker_row_id}_${userSelection?.selection}`]?.status === 1) {
                    return "#FC8EAC"
                }
            }
            return "white"
        }
    }

    function getTableBayColor(tracker_row_id) {
        let color = "white"
        return color
    }

    const renderMap = (renderData) => {

        if (downloadRef.current) {
            downloadRef.current.innerHTML = "";
        }
        containerRef.current.innerHTML = "";

        let wrapper = document.getElementById("wrapper");

        let xScalerangeVal = 150

        let xScale = scaleLinear()
            .domain([
                mapSize?.piers?.minimum_latitude,
                mapSize?.piers?.maximum_latitude,
            ])
            .range([xScalerangeVal, wrapper.offsetWidth - xScalerangeVal]);

        let yScalerangeVal = 300

        let yScale = scaleLinear()
            .domain([
                mapSize?.piers?.maximum_longitude,
                mapSize?.piers?.minimum_longitude,
            ])
            .range([yScalerangeVal, wrapper.offsetHeight - yScalerangeVal]);

        for (let pierData of renderData) {

            let pierObj = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData.part_number)).shape}-${getPrLegend(partNumberSplit(pierData.part_number)).concentric}-${partNumberSplit(pierData.part_number)}`]
            let pierDatas = {
                bha_sg_labels: pierData?.bha_sg_labels,
                block: pierData?.block,
                block_id: pierData?.block_id,
                id: pierData?.id,
                latitude: xScale(pierData?.latitude),
                longitude: yScale(pierData?.longitude),
                pier_part_order: pierData?.pier_part_order,
                row: pierData?.row,
                section: pierData?.section,
                torque_tube_label: pierData?.torque_tube_label,
                tracker_row_id: pierData?.tracker_row_id,
                pier_type_motor: pierData?.pier_type_motor,
                tracker_type_id: pierData?.tracker_type_id,
                label_name: pierData?.label_name ? pierData?.label_name : "A_255-0-127",
                stroke: getPrLegend(partNumberSplit(pierData?.part_number))?.border ? getPrLegend(partNumberSplit(pierData?.part_number))?.border : "black",
                fill: getPrLegend(partNumberSplit(pierData?.part_number))?.background ? getPrLegend(partNumberSplit(pierData?.part_number))?.background : "white",
                stroke_width: getPrLegend(partNumberSplit(pierData?.part_number))?.width ? getPrLegend(partNumberSplit(pierData?.part_number))?.width : 1,
                map_colour: getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour ? getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour : "#4287f5"
            }

            if (!pierObj) {
                pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = [
                    pierDatas
                ]
            }

            if (pierObj) {
                pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`].concat(pierDatas)
            }
        }

        if (laydown === false) {
            for (let tt_trackerId in bulkAllTTData) {
                let ttData = bulkAllTTData[tt_trackerId]["table_coordinates"]
                let ttColorCode = bulkAllTTData[tt_trackerId]["color_code"]
                let ttHatch = bulkAllTTData[tt_trackerId]["hatch"]
                let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(tt_trackerId))
                let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                if (ttData.length === 4) {
                    d3.select(containerRef.current)
                        .append("path")
                        .attr("class", "testing-border cursor-pointer")
                        .attr("d", `M${xScale(ttData[0].x)} ${yScale(ttData[0].y)} L${xScale(ttData[1].x)} ${yScale(ttData[1].y)} L${xScale(ttData[2].x)} ${yScale(ttData[2].y)} L${xScale(ttData[3].x)} ${yScale(ttData[3].y)}  Z`)
                        .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                        .attr("stroke-width", "1px")
                        // .attr("fill", `${selectedRow.includes(rowName) ? "yellow" : getTableColor(temp)}`)
                        .attr("fill", `${selectedRow.includes(rowName) ? "yellow" : getTableColor(temp)}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "32px")
                                .style("font-weight", "700")
                                .text(`Row-${temp?.section}`);
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", () => handleSelectTable(tt_trackerId))

                    if (ttHatch) {
                        d3.select(containerRef.current)
                            .append("line")
                            .attr("class", "testing-border-diag cursor-pointer")
                            .attr("x1", xScale(ttData[0].x))
                            .attr("y1", yScale(ttData[0].y))
                            .attr("x2", xScale(ttData[2].x))
                            .attr("y2", yScale(ttData[2].y))
                            .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                    }

                }
            }
        } else {
            for (let tt_trackerId in bulkAllTTData) {
                let ttData = bulkAllTTData[tt_trackerId]["table_coordinates"]
                let ttColorCode = bulkAllTTData[tt_trackerId]["color_code"]
                let ttHatch = bulkAllTTData[tt_trackerId]["hatch"]
                let laydown_id = bulkAllTTData[tt_trackerId]["laydown_id"]
                let temp = bulkPierData.find(o => o.tracker_row_id === parseInt(tt_trackerId))
                let rowName = `trow-${temp?.tracker_row_id}-${temp?.tracker_type_id}-${temp?.row}-${temp?.section}`
                if (ttData.length === 4) {
                    d3.select(containerRef.current)
                        .append("path")
                        .attr("class", "testing-border cursor-pointer")
                        .attr("d", `M${xScale(ttData[0].x)} ${yScale(ttData[0].y)} L${xScale(ttData[1].x)} ${yScale(ttData[1].y)} L${xScale(ttData[2].x)} ${yScale(ttData[2].y)} L${xScale(ttData[3].x)} ${yScale(ttData[3].y)}  Z`)
                        .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                        .attr("stroke-width", "1px")
                        // .attr("fill", `${selectedRow.includes(rowName) ? "yellow" : getTableColor(temp)}`)
                        .attr("fill", `${selectedRow.includes(rowName) ? "yellow" : getLaydownColor(laydown_id)}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "32px")
                                .style("font-weight", "700")
                                .text(laydown ? getLaydownName(laydown_id) : `Row-${temp?.section}`);
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", () => handleSelectTable(tt_trackerId))

                    if (ttHatch) {
                        d3.select(containerRef.current)
                            .append("line")
                            .attr("class", "testing-border-diag cursor-pointer")
                            .attr("x1", xScale(ttData[0].x))
                            .attr("y1", yScale(ttData[0].y))
                            .attr("x2", xScale(ttData[2].x))
                            .attr("y2", yScale(ttData[2].y))
                            .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                    }

                }
            }
        }

        for (let tracker_row_id in tableBays) {
            let trackerBayData = tableBays[tracker_row_id]
            let rowName = `trow-${tracker_row_id}-${trackerBayData?.tracker_type_id}-${trackerBayData?.row}-${trackerBayData?.section}`
            let currentBayData = typeData[`${tracker_row_id}_${userSelection?.selection}`]
            if (userSelection?.selection !== "All" && currentBayData?.status === 1 && currentBayData?.torque_tubes?.length > 0) {
                trackerBayData?.map((temp) => {
                    let dataCheck = currentBayData?.torque_tubes.find(o => o?.torque_tube_id === temp?.id)
                    if (dataCheck && dataCheck?.status === 1) {
                        let ttData = temp?.tt_window_zimmerman
                        d3.select(containerRef.current)
                            .append("path")
                            .attr("class", "tableBays cursor-pointer")
                            .attr("d", `M${xScale(ttData[0]?.latitude)} ${yScale(ttData[0]?.longitude)} L${xScale(ttData[1]?.latitude)} ${yScale(ttData[1]?.longitude)} L${xScale(ttData[3]?.latitude)} ${yScale(ttData[3]?.longitude)} L${xScale(ttData[5]?.latitude)} ${yScale(ttData[5]?.longitude)} L${xScale(ttData[4]?.latitude)} ${yScale(ttData[4]?.longitude)} L${xScale(ttData[2]?.latitude)} ${yScale(ttData[2]?.longitude)} Z`)
                            .attr("stroke", "black")
                            .attr("stroke-width", "1px")
                            .attr("fill", `${selectedRow.includes(rowName) ? "yellow" : "lightgreen"}`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(`Row-${temp?.section}`);
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTable(tracker_row_id))
                    }
                })
            }
        }

        for (let pierData in pierObjs) {
            for (let data of pierObjs[pierData]) {
                if (userSelection?.selection !== "All") {
                    if (typeData[`${data?.tracker_row_id}_${userSelection?.selection}`]?.piers?.length && typeData[`${data?.tracker_row_id}_${userSelection?.selection}`]?.status === 1) {
                        typeData[`${data?.tracker_row_id}_${userSelection?.selection}`]?.piers.map((i) => {
                            let rowName = `trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`
                            if (!selectedRow.includes(rowName) && i?.status === 1 && data?.id === i?.pier_id) {
                                d3.select(containerRef.current)
                                    .append("circle")
                                    .attr("class", "cursor-pointer")
                                    .attr("cx", data?.latitude)
                                    .attr("cy", data?.longitude - 1)
                                    .attr("r", 5)
                                    .attr("stroke", "black")
                                    .attr("stroke-width", "1px")
                                    .attr("fill", i?.status ? "#66F1AD" : "white")
                            }
                        })
                    }
                }

                // let labelColor = data?.label_name.split("_") ? data?.label_name.split("_")[1] : "255-0-0"
                let labelColor = "0-0-0"

                d3.select(containerRef.current)
                    .append("text")
                    .attr("fill", `rgb(${labelColor.split("-")[0]}, ${labelColor.split("-")[1]}, ${labelColor.split("-")[2]})`)
                    .attr("x", data?.latitude)
                    .attr("y", data?.longitude + 2)
                    .attr("text-anchor", "middle")
                    .style("font-size", "8px")
                    // .style("font-weight", "600")
                    .style("font-weight", "700")
                    .text(data?.label_name.split("_") ? data?.label_name.split("_")[0] : "")


            }
        }

        if (deviceType !== "web" && enableGPSLocation) {
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "#A8BEE2")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 9);
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "white")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 5);
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "#3A72B6")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 4); // Adjust the radius as needed
        }

        const tooltip = d3
            .select(containerRef.current)
            .append("text")
            .attr("class", "tooltip")
            .attr("fill", "white")
            .style("pointer-events", "none")
    };

    if (deviceType === "web") {

        let transCompHeightVal = currentProjectData?.svg_dimension?.web?.web_d3_height + findD3Height()

        let transCompWidthVal = currentProjectData?.svg_dimension?.web?.web_d3_width + 0

        return (
            <div>
                <div style={{ height: "calc(100vh - 280px)" }} >
                    <TransformWrapper
                        centerZoomedOut
                        minScale={parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_min_scale)}
                        initialScale={parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_initial_scale)}
                        initialPositionX={50}  // Center X position (percentage)
                        initialPositionY={50}  // Center Y position (percentage)
                        centerOnInit={true}
                    >
                        <TransformComponent
                            contentStyle={{ height: `${transCompHeightVal}px`, width: `${transCompWidthVal}px` }}
                            wrapperStyle={{ height: "100%", width: "100%" }}
                        >
                            <div style={{ height: `${transCompHeightVal}px`, width: `${transCompWidthVal}px` }} className="flex justify-center m-auto" id="wrapper">
                                <svg ref={containerRef} style={{ width: '100%', height: `${transCompHeightVal}px` }} className="ml-2 view-svg cursor-grab" />
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </div>

                <div style={{ display: "none" }}>
                    <div className="w-full h-full flex justify-center m-auto">
                        <svg ref={downloadRef} style={{ height: `${transCompHeightVal}px`, width: `${transCompWidthVal}px` }} className="ml-2 download-svg" />
                    </div>
                </div>
                {showLegend === false ?
                    null
                    :
                    <div className='flex justify-center mt-2'>
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#66f1ad" }}></p>
                        <p className='mr-2'>Completed</p>
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#FC8EAC" }}></p>
                        <p className='mr-2'>In progress</p>
                        <p className="w-5 h-5 rounded-full mx-2 mt-0.5" style={{ backgroundColor: "#F6BE00" }}></p>
                        <p>Selected Trackers</p>
                    </div>
                }
            </div>
        )
    }

    else {
        let transCompHeight = findD3Height()
        let transCompWidth = findD3Width()

        let transCompHeightVal = ((currentProjectData?.svg_dimension?.tablet?.tab_d3_height) / 2) + transCompHeight

        let transCompWidthVal = ((currentProjectData?.svg_dimension?.tablet?.tab_d3_width + 500) / 2) + transCompWidth

        return (
            <TransformWrapper
                minScale={parseFloat(currentProjectData?.svg_dimension?.tablet?.tab_svg_min_scale)}
            >
                <TransformComponent
                    contentStyle={{ height: `${transCompHeightVal}px`, width: `${transCompWidthVal}px` }}
                    wrapperStyle={{ height: "100%", width: "95%" }}>
                    <div id="wrapper" >
                        <svg ref={containerRef} style={{ height: `${transCompHeightVal}px`, width: `${transCompWidthVal}px` }} />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        )
    }
}