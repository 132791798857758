import { useAtom } from "jotai"
import { BiTimeFive } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { MdDelete } from "react-icons/md"
import workHourAtom, { fetchIndividualAct, fetchWorkHourUsersAtom } from "../../../atoms/workHoursAtom"
import staffAtom, { fetchStaffAtom } from "../../../atoms/staffAtom"
import { useEffect, useState } from "react"

export default function GridBoxView({ key, values, title, staff, hours, rawData, date, rightClickEnable, onCLickFeature, onClick, handleBulkDelete }) {

    const [listWorkHours] = useAtom(workHourAtom)
    const [, setFetchIndividualAct] = useAtom(fetchIndividualAct)
    const [, setListUsers] = useAtom(fetchWorkHourUsersAtom)
    const [listStaff] = useAtom(staffAtom)
    const [, setFetchStaff] = useAtom(fetchStaffAtom)

    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [keyIds, setKeyIds] = useState([])

    const navigate = useNavigate()

    const handleClickOutside = () => setMenuVisible(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await setFetchStaff()
            await setListUsers()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = async () => {
        try {
            await setFetchIndividualAct({ date: date.toISOString().split('T')[0], act_id: values?.activity_id, type: values?.type })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleClick = () => {
        if (!menuVisible) {
            if (onCLickFeature === "Navigate") {
                handleSubmit()
                navigate("/staff_and_plant/workHour/activity", {
                    state: {
                        date: date,
                        act_id: values?.activity_id,
                        type: values?.type
                    }
                })
            }
            if (onCLickFeature === "MultiSelect") {
                let temp = [...keyIds]
                if (keyIds.includes(key)) {
                    let index = keyIds.indexOf(key)
                    temp.splice(index, 1)
                }
                else {
                    temp.push(key)
                }
                setKeyIds(temp)
                onClick(values?.id)
            }
        }
    }

    const handleContextMenu = (e) => {
        if (rightClickEnable) {
            e.preventDefault();
            setMenuPosition({ x: e.clientX, y: e.clientY });
            setMenuVisible(true);
        }

    }

    const findProfileImg = (data) => {
        let temp = null
        listStaff?.users?.map((i) => {
            if (i?.user?.id === data) {
                temp = i?.user?.profile_data?.profile_url
            }
        })
        return temp
    }

    let newArr = []
    rawData.map((i) => {
        if (i.Workhours?.activity_id === values.activity_id && i?.Workhours?.type === values?.type) {
            newArr.push(i?.Staff?.id)
        }
    })

    const findUserFirstLetter = (user_id) => {
        let user = listWorkHours.users.find(user => user?.user?.id === user_id);
        if (user) {
            return user.user?.name?.charAt(0).toUpperCase()
        }
        return user_id
    }

    return (
        <label className={`${onCLickFeature === "MultiSelect" ? "flex cursor-pointer space-x-2" : null}`}>
            {onCLickFeature === "MultiSelect" ?
                <input type="checkbox" className='mr-2 mt-1 cursor-pointer' checked={keyIds.includes(key)} value={key} onChange={handleClick} />
                :
                null
            }
            <div className={`border border-gray-300 rounded-xl mx-5 mt-5 cursor-pointer ${keyIds.includes(key) ? "border-red-550 border-2" : ""}`} onContextMenu={handleContextMenu} onClick={onCLickFeature === "MultiSelect" ? null : handleClick} style={{ width: '383px', height: '162px' }}>
                {menuVisible && (
                    <div
                        className="bg-white fixed shadow-lg rounded-lg py-2 min-w-[160px]"
                        style={{ top: menuPosition.y, left: menuPosition.x }}
                    >
                        <div className="flex p-2 mx-2 items-center space-x-2 hover:bg-gray-100 rounded-xl" onClick={() => handleBulkDelete(values.id)}>
                            <MdDelete />
                            <p>Delete</p>
                        </div>
                    </div>
                )}
                <div className='flex justify-between mx-6 my-4'>
                    <p className='font-semibold text-base'>{title}</p>
                </div>
                <div className='flex ml-6'>
                    <div className='w-[50%]'>
                        <p className='font-medium w-[83%] text-sm text-gray-400 mb-2 flex'>
                            <p className="w-[80%]">Assigned Staff</p>
                            <p className='font-semibold text-sm text-black'>{staff}</p>
                        </p>
                        <p className='font-medium w-[83%] text-sm text-gray-400 mb-1 flex'>
                            <p className="w-[80%]">Assigned Plant</p>
                            <p className='font-semibold text-sm text-black'>{staff}</p>
                        </p>

                        {staff > 4 ?
                            <div className="avatar-group -space-x-3">
                                <div className="avatar" >
                                    {findProfileImg(newArr[0]) !== null ?
                                        <div className="w-8 h-6 mt-2">
                                            <img src={findProfileImg(newArr[0])} alt='' />
                                        </div>
                                        :
                                        <div className='avatar'>
                                            <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[0])}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="avatar" >
                                    {findProfileImg(newArr[1]) !== null ?
                                        <div className="w-8 h-6 mt-2">
                                            <img src={`${findProfileImg(newArr[1])}`} alt='' />
                                        </div>
                                        :
                                        <div className='avatar'>
                                            <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[1])}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="avatar" >
                                    {findProfileImg(newArr[2]) !== null ?
                                        <div className="w-8 h-6 mt-2">
                                            <img src={`${findProfileImg(newArr[2])}`} alt='' />
                                        </div>
                                        :
                                        <div className='avatar'>
                                            <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[2])}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="avatar" >
                                    {findProfileImg(newArr[3]) !== null ?
                                        <div className="w-8 h-6 mt-2">
                                            <img src={`${findProfileImg(newArr[3])}`} alt='' />
                                        </div>
                                        :
                                        <div className='avatar'>
                                            <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[3])}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='avatar placeholder'>
                                    <div className="text-neutral-content rounded-full w-8 h-8 mt-1 text-center bg-gray-200 p-0.5 pt-1 text-white text-base">
                                        <span className='text-gray-500'>+{staff - 4}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="avatar-group -space-x-4">
                                {newArr?.map((j) => {
                                    return (
                                        <div className="avatar" >
                                            {findProfileImg(j) !== null ?
                                                <div className="w-8 h-6 mt-2">
                                                    <img src={`${findProfileImg(j)}`} alt='' />
                                                </div>
                                                :
                                                <div className='avatar'>
                                                    <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                        <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(j)}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>)

                                })}
                            </div>
                        }

                    </div>
                    <div className="flex-col space-y-2 w-[50%]">
                        <p className='font-medium text-sm text-gray-400'>Total Work Hours</p>
                        <div className='flex'>
                            <BiTimeFive className="text-gray-400" style={{ width: "20px", height: "20px" }} />
                            <p className='font-semibold text-sm text-gray-500 ml-2'>{hours} Hrs</p>
                        </div>
                    </div>
                </div>

            </div>
        </label>
    )
}
