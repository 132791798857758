import React, { useEffect, useState } from 'react'
import { BlockMap } from "@product-fusion/surya-fw"
import dashboardAtom, { fetchBlocks, fetchBulkDashboardDataGet, fetchCoordinates, fetchListSections, fetchMapRender, fetchMapSize, fetchQCStatus, fetchSelectedSection } from '../../atoms/dashboardAtom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaUser, FaUsers } from "react-icons/fa";
import { useAtom } from 'jotai'
import projectAtom from '../../atoms/projectAtom'
import Container from '../../components/container'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import punchListAtom, { fetchPunchListAtom, fetchStatusCountsAtom } from '../../atoms/punchListAtom'
import Svg from '../dashboard/svg'

function DashboardQc() {

    const navigate = useNavigate()

    const [showBlock, setShowBlock] = useState({ bool: true, block: "" })
    const [statusLoading, setStatusLoading] = useState(false)

    const [punchlistType, setPunchlistType] = useState(1)
    const [checklistType, setChecklistType] = useState("All")

    const [loading, setLoading] = useState(false)
    const [viewType, setViewType] = useState(true)

    const [projectsName] = useAtom(projectAtom);
    const [listblocks] = useAtom(dashboardAtom);
    const [punchlist] = useAtom(punchListAtom)
    let project_name = projectsName.find(item => item.id === parseInt(localStorage.getItem("project_id")));

    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setCoordinates] = useAtom(fetchCoordinates)

    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setFetchQCStatus] = useAtom(fetchQCStatus)
    const [, setStatusCounts] = useAtom(fetchStatusCountsAtom)

    const [, setPunchlistDatas] = useAtom(fetchPunchListAtom)

    const [dum, setDum] = useState({ bool: false, id: null, name: null });
    const [selectedBlock, setSelectedBlock] = useState([])
    const [selectedRow, setSelectedRow] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            try {
                setStatusLoading(true)
                setLoading(true)
                await setFetchMap()
                await setStatusCounts({ block_id: null, type: "All", visibility: punchlistType })
                await setFetchBlocks()
                await setPunchlistDatas({ loadImage: false })
            }
            catch (err) {
                console.log(err)
            }
            finally {
                setLoading(false)
                setStatusLoading(false)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (dum.bool) {
            handleDblClick()
        }
    }, [dum.id])

    const handleDblClick = async () => {
        try {
            setStatusLoading(true)
            setLoading(true)
            await setMapSize({ id: dum.id })
            if (project_name?.type) {
                await setCoordinates({ id: dum.id })
            }
            else {
                await setFetchBulkGet({ id: dum.id })
            }
            await setStatusCounts({ block_id: dum?.id, type: "piers", visibility: punchlistType })
            handleChangeFields()
            setShowBlock({ bool: false, block: dum.name })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setStatusLoading(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        svgInit()
    }, [listblocks.blocks, showBlock?.bool, selectedBlock])

    const svgInit = () => {
        if (listblocks?.blocks.length > 0) {
            let block_svg_id
            let block_progress_id
            listblocks?.blocks.map((i) => {
                block_svg_id = document.getElementById(project_name?.type ? i?.name : i?.block_svg_id)
                block_progress_id = document.getElementById(i?.progress_svg_id)
                if (block_svg_id) {
                    // block_svg_id.addEventListener("click", () => {
                    //     if (selectedBlock[0] === i?.id) {
                    //         setSelectedBlock([])
                    //     }
                    //     else {
                    //         setSelectedBlock([i?.id])
                    //     }
                    // })
                    block_svg_id.addEventListener("dblclick", () => {
                        setChecklistType("piers")
                        setDum({ bool: true, id: i?.id, name: i?.name })
                        setSelectedBlock([])
                    })
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = null
                    if (selectedBlock.includes(i?.id)) {
                        block_svg_id.style.fill = "#F6BE00"
                    }
                    else {
                        block_svg_id.style.fill = "white"
                    }
                }
            })
        }
    }

    useEffect(() => {
        handleChangeFields()
    }, [checklistType, punchlistType])

    const handleFindBlockName = (data) => {
        let temp = listblocks.blocks.find(o => o?.id === data)
        return temp.name
    }

    const handleChangeFields = async () => {
        try {
            setLoading(true)
            if (dum?.id) {
                await setFetchQCStatus({ checklist_type: checklistType, block_id: dum?.id, visibility: punchlistType })
            }
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const handleChangeVisibility = async (data) => {
        try {
            setStatusLoading(true)
            setPunchlistType(data)
            if (dum?.id) {
                await setStatusCounts({ block_id: dum?.id, type: checklistType, visibility: data })
            }
            else {
                await setStatusCounts({ block_id: null, type: checklistType, visibility: data })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally{
            setStatusLoading(false)
        }
    }

    const handleChangeType = async (type) => {
        try {
            setChecklistType(type)
            setStatusLoading(true)
            if (dum?.id) {
                await setStatusCounts({ block_id: dum?.id, type: type, visibility: punchlistType })
            }
            else {
                await setStatusCounts({ block_id: null, type: type, visibility: punchlistType })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setStatusLoading(false)
        }
    }

    const handleBackToSVG = async () => {
        try {
            setStatusLoading(true)
            await setStatusCounts({ block_id: null, type: "All", visibility: punchlistType })
            setShowBlock({ bool: true, block: "" })
            setChecklistType("All")
            setDum({ bool: false, id: null, name: null })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setStatusLoading(false)
        }
    }

    const handleCheckCounts = (status) => {
        let openCount = 0
        let insepctionCount = 0
        let resolvedCount = 0
        punchlist.data.data.map((i) => {
            if (punchlistType === i?.visibility && (selectedBlock[0] === i?.block_id || selectedBlock.length === 0) && (!dum.bool || dum.id === i?.block_id)) {
                if (status === "Open" && i?.status === 0) {
                    if (checklistType === "piers" && i?.scope === "piers") {
                        openCount = openCount + 1
                    }
                    else if (checklistType === "structure" && i?.scope === "structure") {
                        openCount = openCount + 1
                    }
                    else if (checklistType === "module" && i?.scope === "module") {
                        openCount = openCount + 1
                    }
                    else if (checklistType === "All") {
                        openCount = openCount + 1
                    }
                }
                if (status === "Inspection" && i?.status === 1) {
                    if (checklistType === "piers" && i?.scope === "piers") {
                        insepctionCount = insepctionCount + 1
                    }
                    else if (checklistType === "structure" && i?.scope === "structure") {
                        insepctionCount = insepctionCount + 1
                    }
                    else if (checklistType === "module" && i?.scope === "module") {
                        insepctionCount = insepctionCount + 1
                    }
                    else if (checklistType === "All") {
                        insepctionCount = insepctionCount + 1
                    }
                }
                if (status === "Resolved" && i?.status === 2) {
                    if (checklistType === "piers" && i?.scope === "piers") {
                        resolvedCount = resolvedCount + 1
                    }
                    else if (checklistType === "structure" && i?.scope === "structure") {
                        resolvedCount = resolvedCount + 1
                    }
                    else if (checklistType === "module" && i?.scope === "module") {
                        resolvedCount = resolvedCount + 1
                    }
                    else if (checklistType === "All") {
                        resolvedCount = resolvedCount + 1
                    }
                }
            }
        })
        if (status === "Open") {
            return openCount
        }
        if (status === "Inspection") {
            return insepctionCount
        }
        if (status === "Resolved") {
            return resolvedCount
        }
    }

    return (
        <Container>
            <div className='flex justify-between my-3'>
                <div>
                    <div className='flex space-x-5 items-center'>
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer ml-5 hover:bg-gray-100 rounded-xl active:bg-gray-200" onClick={() => navigate("/quality")} />
                        <p className='mt-3 ml-5 font-bold text-xl pb-5 mt-4'>QC Punchlist</p>
                        <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 py-1 bg-red-50 border-red-100'>
                            <div className={`flex items-center ${viewType ? "" : "bg-red-550 border-none rounded-full py-2"}`}>
                                <FaUsers className={`ml-3 ${viewType ? "text-gray-400" : "text-white"}`} />
                                <p className={`${viewType ? "text-gray-400 pr-3 pl-2" : "text-white pr-3 pl-2"} mr-1`} onClick={() => { setViewType(false); navigate("/quality/qc_punchlist") }}>List View</p>
                            </div>
                            <div className={`flex items-center ${viewType ? "bg-red-550 border-none rounded-full py-2" : ""}`}>
                                <FaUser className={`ml-3 ${viewType ? "text-white" : "text-gray-400"}`} />
                                <p className={`${viewType ? "text-white pr-3 pl-2" : "text-gray-400 pr-3 pl-2"} ml-1`} onClick={() => { setViewType(true); navigate("/quality/dashboard") }}>Map View</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-5 items-center mx-5'>
                    <div className='flex w-[215px] text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100'>
                        <div className={`flex ${punchlistType === 1 ? "bg-red-550 border-none rounded-full" : ""}`}>
                            <FaUsers className={`mt-3 ml-3 ${punchlistType === 1 ? "text-white" : "text-gray-400"}`} />
                            <p className={`${punchlistType === 1 ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => { handleChangeVisibility(1) }}>External</p>
                        </div>
                        <div className={`flex ${punchlistType ? "" : "bg-red-550 border-none rounded-full"}`}>
                            <FaUser className={`mt-3 ml-3 ${punchlistType ? "text-gray-400" : "text-white"}`} />
                            <p className={`${punchlistType ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => { handleChangeVisibility(0) }}>Internal</p>
                        </div>
                    </div>
                    <select className='select w-[130px] select-bordered max-w-xs px-2 rounded-xl py-0.5 cursor-pointer focus:outline-none' onChange={e => handleChangeType(e.target.value)}>
                        {showBlock.bool && <option selected={checklistType === "All"} value={"All"}>All</option>}
                        <option selected={checklistType === "piers"} value={"piers"}>Pier</option>
                        <option selected={checklistType === "structure"} value={"structure"}>Structure</option>
                        <option selected={checklistType === "module"} value={"module"}>Module</option>
                    </select>
                </div>
            </div>
            <hr />
            <div className='flex justify-between w-full'>
                <div className='w-[70%]'>
                    {loading ?
                        <div style={{ height: "calc(100vh - 390px)" }} className='w-[100%]  flex justify-center items-center'>
                            <p className='loader mb-1'></p>
                            <p className='font-bold ml-4 text-xl'>Fetching...</p>
                        </div>
                        :
                        showBlock.bool ?
                            <div style={{ height: "calc(100vh - 390px)" }}>
                                <div className='cursor-grab w-full' style={{ height: "calc(100vh - 250px)" }}>
                                    <Svg project_name={project_name} svgMap={listblocks?.map} className={""} />
                                </div>
                                <div className='flex justify-center mt-3'>
                                    <span data-tip data-for="svg" className='mt-1 ml-4'>
                                        <AiOutlineInfoCircle />
                                    </span>
                                    <p className='text-sm text-gray-400 mt-0.5 ml-2'>Double click on any block to dive in</p>
                                </div>
                            </div>
                            :
                            <div className='w-[100%]' style={{ height: "calc(100vh - 200px)" }}>
                                <div>
                                    <div className='px-8 flex gap-6 items-center p-2'>
                                        <div className='flex space-x-2 items-center'>
                                            <BiArrowBack className='cursor-pointer' onClick={() => { handleBackToSVG() }} />
                                            <div className='ml-2 font-semibold my-3'>{showBlock?.block}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <BlockMap
                                        deviceType="web"
                                        zimmerMan={project_name?.type}
                                        bulkAllData={listblocks.blockTtData}
                                        bulkPierData={listblocks?.piersList}
                                        bulkAllTTData={listblocks?.tableCoordinates}
                                        pierLegends={listblocks.pierLegends}
                                        type="QC"
                                        typeData={listblocks.qcStatus}
                                        userSelection={{ showType: "QC", selection: checklistType }}
                                        currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(dum.id))}
                                        currentProjectData={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        showLegend={false}
                                        mapSize={listblocks.mapSize}
                                        controls={true}
                                    // selectAll={selectAll}
                                    // section={sectionSelect}
                                    // prevSection={prevSection}
                                    // clearSelection={clearSt}
                                    // setSelectedSection={setSelectedSection}
                                    />
                                </div>
                            </div>
                    }
                </div>
                <div className='w-[30%] border-l-2'>
                    <p className='text-xl font-semibold my-4 mx-2'>{selectedBlock.length > 0 ? handleFindBlockName(selectedBlock[0]) : null} Punchlist</p>
                    <table className='z-0 w-full table'>
                        <thead>
                            <tr>
                                <th className='w-[70%]'>Status</th>
                                <th className='w-[30%]'>Counts</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover">
                                <td className='my-2 text-sm font-medium'>Open</td>
                                <td className='flex items-center'>
                                    {statusLoading ?
                                        <p className='loader ml-3' style={{ width: "25px", height: "25px" }}></p>
                                        :
                                        <p className='ml-3'>{punchlist?.statusCounts?.["0"]}</p>
                                    }
                                </td>
                            </tr>
                            <tr className="hover">
                                <td className='my-2 text-sm font-medium'>Ready For Inspection</td>
                                <td className='flex items-center'>
                                    {statusLoading ?
                                        <p className='loader ml-3' style={{ width: "25px", height: "25px" }}></p>
                                        :
                                        <p className='ml-3'>{punchlist?.statusCounts?.["1"]}</p>
                                    }
                                </td>
                            </tr>
                            <tr className="hover">
                                <td className='my-2 text-sm font-medium'>Resolved</td>
                                <td className='flex items-center'>
                                    {statusLoading ?
                                        <p className='loader ml-3' style={{ width: "25px", height: "25px" }}></p>
                                        :
                                        <p className='ml-3'>{punchlist?.statusCounts?.["2"]}</p>
                                    }
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    )
}

export default DashboardQc
