import React from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

function Svg({ project_name, svgMap, className }) {

    return (
        <TransformWrapper
            centerZoomedOut
            // centerOnInit
            minScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale)}
            initialScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_initial_scale)}
            initialPositionX={50}  // Center X position (percentage)
            initialPositionY={50}  // Center Y position (percentage)
            centerOnInit={true}    // Enable centering on initialization
        >
            <TransformComponent
                contentStyle={{ width: `${project_name?.svg_dimension?.web?.web_svg_width}px` }}
                wrapperStyle={{ height: "100%", width: "100%" }}
            >
                <div className='m-auto inline-block align-middle'>
                    <div className={`svgmap ${className} flex items-center justify-center`} style={{ height: `${project_name?.svg_dimension?.web?.web_svg_height}px`, width: `${project_name?.svg_dimension?.web?.web_svg_width}px`, position: "relative" }} dangerouslySetInnerHTML={{ __html: svgMap }}></div>
                </div>
            </TransformComponent>
        </TransformWrapper>
    )
}

export default Svg
