import React, { useRef } from 'react'

function SliderToggle({
    currentMode,
    setCurrentMode,
    data,
    icons,
    disabled
}) {

    const dataOneRef = useRef(null)
    const dataTwoRef = useRef(null)

    function handleClick(data) {
        if (!disabled) {
            setCurrentMode(data)
        }
    }

    return (
        <div className={`relative inline-flex ${disabled ? "bg-gray-50 border-gray-100" : "bg-red-50 border-red-100"} border p-1 rounded-full`}>
            {/* Background slider */}
            <div className={`absolute h-[80%] transition-all duration-300 ease-in-out ${disabled ? "bg-gray-200" : "bg-red-550"} rounded-full`}
                style={{
                    width: currentMode === data[0] ? `${dataOneRef.current?.offsetWidth}px` : `${dataTwoRef.current?.offsetWidth}px`,
                    left: currentMode === data[0] ? `5px` : `${dataOneRef.current?.offsetWidth + 4}px`
                }}
            />

            {/* Buttons */}
            <button
                ref={dataOneRef}
                onClick={() => handleClick(data[0])}
                className={`relative flex items-center px-4 space-x-2 py-1.5 ${disabled ? "cursor-auto" : "cursor-pointer"} rounded-full transition-colors duration-300 ${currentMode === data[0] ? 'text-white' : 'text-gray-400'
                    }`}
            >
                {icons[0]}
                <p>{data[0]}</p>
            </button>
            <button
                ref={dataTwoRef}
                onClick={() => handleClick(data[1])}
                className={`relative flex items-center px-4 space-x-2 py-1.5 ${disabled ? "cursor-auto" : "cursor-pointer"} rounded-full transition-colors duration-300 ${currentMode === data[1] ? 'text-white' : 'text-gray-400'
                    }`}
            >
                {icons[1]}
                <p>{data[1]}</p>
            </button>
        </div>
    )
}

export default SliderToggle
