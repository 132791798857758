import React from 'react'
import { useState, useRef } from 'react'
import Container from '../../../components/container'
import Modal from 'react-modal';
import { CgSoftwareDownload } from "react-icons/cg"
import { VscClose } from "react-icons/vsc"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import { TiTick } from "react-icons/ti"
import { useAtom } from 'jotai';
import dashboardAtom, { fetchBomBlock, fetchSelectedTrackerBOM, fetchBlocks, fetchCount, fetchCountOverall, fetchSelectedActId, fetchBulkDashboardDataPost, fetchMapSize, fetchCpTrackerRowAtom, fetchListRows, fetchCoordinates, fetchoverallCP, updateConstructionProgressStatus, handleLoadMarkProgressBulk, getListOfPiersTorqueTubeIdsWithStatusAtom, updateInprogressDeployedCountAtom, updateDistributedAndInstalledQuantityAtom, fetchZimmermanTableCoordinatesAtom, getZimmermanSelectedPiersDataAtom, getZimmermanSelectedBaysDataAtom } from '../../../atoms/dashboardAtom';
import { useEffect } from 'react';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';
import partsAtom, { fetchPartsAtom } from '../../../atoms/partsAtom';
import { toast } from 'react-hot-toast';
import { Parser } from 'json2csv';
import * as XLSX from "xlsx";
import projectAtom, { fetchProjectAtom } from '../../../atoms/projectAtom';
import produce from 'immer';
import { MultiSelect } from "react-multi-select-component";
import { get_distributed_and_installed_bom_for_activity, get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers, get_updated_bom_for_distributed_installed_for_selected_activity_trackerow, updated_progress_quantity_for_all_selected_trackerrows } from '../../../dal/constructionMarkProgress/construction-progress-dal';
import ZimmermanInProgressStepperModal from './zimmermanInprogressStepper';
import ZimmerBlock from '../zimmermanBlock';
import roundOff from '../../../utils/roundOff';

function ZimmermanDashboard() {

    const [loading, setLoading] = useState(false)
    const [countLoading, setCountLoading] = useState(false)
    const [bomLoading, setBomLoading] = useState(false)
    const [applyProgressLoader, setApplyProgressLoader] = useState(false)
    const [inProgressSubmitLoader, setInProgressSubmitLoader] = useState(false)
    const [inprogressTrackerTypesModal, setInprogressTrackerTypesModal] = useState(false)

    const [showDescription, setShowDescription] = useState()
    const [openTrackerModal, setTrackerModal] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [clearSt, setClearSt] = useState(false)
    const [sectionSelect, setSectionSelect] = useState([])
    const [prevSection, setPrevSection] = useState(null)
    const [currentTrackerType, setCurrentTrackerType] = useState(null)

    const [dumRow, setDumRow] = useState([])

    const [prevRow, setPrevRow] = useState(null)
    const [hightLightTracker, setHightLightTracker] = useState([])
    const [trackerSelect, setTrackerSelect] = useState([])
    const [prevTracker, setPrevTracker] = useState([])
    const [showProgress, setShowProgress] = useState(true)
    const [trackerList, setTrackerList] = useState([])
    const [prtTrackerSelect, setPrtTrackerSelect] = useState([])
    const [d3xScaleyScale, setD3xScaleyScale] = useState({})
    const [hoverActivity, setHoverActivity] = useState(-1)

    const navigate = useNavigate()
    const _ = require("lodash")

    const [listblocks, setDashboard] = useAtom(dashboardAtom)
    const [listId] = useAtom(activitiesAtom)
    const [listPartsAtom] = useAtom(partsAtom)
    const [, setFetchParts] = useAtom(fetchPartsAtom)
    const [, setCoordinates] = useAtom(fetchCoordinates)
    const [, setFetchBomBlock] = useAtom(fetchBomBlock)
    const [, setFetchoverallCP] = useAtom(fetchoverallCP)
    const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
    const [, setFetchSelectedTrackerBOM] = useAtom(fetchSelectedTrackerBOM)
    const [, setfetchBlocks] = useAtom(fetchBlocks)
    const [, setTrackerProgress] = useAtom(fetchCpTrackerRowAtom)
    const [, setUpdateConstructionProgress] = useAtom(updateConstructionProgressStatus)
    const [, setDeployCount] = useAtom(fetchCount)
    const [, setRowsList] = useAtom(fetchListRows)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setDeployOverallCount] = useAtom(fetchCountOverall)
    const [, setLoadBulkMarkProgressData] = useAtom(handleLoadMarkProgressBulk)
    const [, setActivityId] = useAtom(fetchSelectedActId)
    const [projectsList] = useAtom(projectAtom)
    const [, setFetchProjects] = useAtom(fetchProjectAtom)
    const [, setFetchBulkPost] = useAtom(fetchBulkDashboardDataPost)
    const [, updateInprogressDeployedCount] = useAtom(updateInprogressDeployedCountAtom)
    const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
    const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)
    const [, fetchZimmermanTableCoordinates] = useAtom(fetchZimmermanTableCoordinatesAtom)
    const params = useParams();

    const [selectedActivity, setSelectedActivity] = useState("All")
    const [overallProgress, setOverallProgress] = useState(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
    const [selectedRow, setSelectedRow] = useState([])
    const [enableMarkProgress, setEnableMarkProgress] = useState(false)
    const [applyStatus, setApplyStatus] = useState(null)
    const [hoverTracker, setHoverTracker] = useState(null)
    const [piersTorqueTubesDetails, setPiersTorqueTubesDetails] = useState({})
    const [trackerRows, setTrackerRows] = useState([])
    const [btnEnable, setBtnEnable] = useState(false)
    const [saveBtnEnable, setSaveBtnEnable] = useState(false)
    const [inprogressTotalPartCount, setInprogressTotalPartCount] = useState(0)
    const [updateQuantity, setUpdateQuantity] = useState({});
    const [selectedPiersTorqueTubes, setSelectedPiersTorqueTubes] = useState({ piers: [], torque_tubes: [] })
    const [inprogressDeployedPartCount, setInprogressDeployedPartCount] = useState({});
    const [isQtyExceeded, setIsQtyExceeded] = useState(false);
    const [piersTorqueTubeIdsWithStatus, setPiersTorqueTubeIdsWithStatus] = useState({})
    const [inprogressIndividualTrackerModal, setInprogressIndividualTrackerModal] = useState({ bool: false, tracker_name: null, bomdata: {}, step: 1 })
    const [inprogressPierTTStatusForAllSelectedTrackers, setInprogressPierTTStatusForAllSelectedTrackers] = useState({})


    function qcCheck() {
        let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return project_name?.project_settings?.qc
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (overallProgress !== "All" && overallProgress !== "None Selected") {
            handleDeployCounts()
        }
    }, [listId?.construction])

    const handleDeployCounts = async () => {
        try {
            if (listId?.construction) {
                setLoading(true)
                setActivityId(localStorage.getItem("selected-activity"))
                await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        activityProgressUpdate(selectedActivity)
    }, [selectedActivity])

    const activityProgressUpdate = async (e) => {
        setCountLoading(true)
        try {
            await setActivityId(e)
            localStorage.setItem("selected-activity", e)
            setOverallProgress(e)
            if (listId?.construction && e !== "All" && e !== "None Selected") {
                await setDeployCount({ activity_id: e, part_id: listId?.construction[e]?.part_id, block_id: params.id, tracker: [] })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setCountLoading(false)
        }
    }

    let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const init = async () => {
        try {
            setLoading(true)
            setCountLoading(true)
            setFetchoverallCP()
            setCoordinates({ id: params.id })
            setfetchActivitiesAtom()
            setFetchBulkPost({ id: params.id })
            setfetchBlocks()
            setMapSize({ id: params.id })
            fetchZimmermanTableCoordinates({ blockId: params?.id })
            setFetchParts()
            setFetchProjects()
            await setTrackerProgress({ block_id: params.id })
            setLoadBulkMarkProgressData()
            if (localStorage.getItem("selected-activity") !== "None Selected") {
                if (localStorage.getItem("selected-activity") !== "All" && localStorage.getItem("selected-activity") !== null) {
                    // setActivityId(localStorage.getItem("selected-activity"))
                    // await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
                }
                else {
                    setActivityId("All")
                    await setDeployOverallCount({ block_id: params.id, tracker: [] })
                }
            }
            if (localStorage.getItem("selected-activity") === "None Selected") {
                setActivityId("None Selected")
            }
            if (["All", "None Selected"].includes(localStorage.getItem("selected-activity"))) {
                setSelectedActivity(localStorage.getItem("selected-activity"))
            }
            else if (!isNaN(localStorage.getItem("selected-activity")) && localStorage.getItem("selected-activity")) {
                setSelectedActivity(parseInt(localStorage.getItem("selected-activity")))
            }
            else {
                setSelectedActivity("All")
            }
            setOverallProgress(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        } finally {
            setLoading(false)
            setCountLoading(false)
        }
    }

    function findMinMax(nestedData) {
        const allCoords = [];

        // Flattening the nested data

        nestedData.forEach(coordsArray => {
            allCoords.push(coordsArray);
        });

        const latitudes = allCoords.map(coord => coord.latitude);
        const longitudes = allCoords.map(coord => coord.longitude);

        return {
            minimum_latitude: Math.min(...latitudes),
            maximum_latitude: Math.max(...latitudes),
            minimum_longitude: Math.min(...longitudes),
            maximum_longitude: Math.max(...longitudes)
        };
    }

    function hasNullValues(data) {
        if (data) {
            const values = [
                data?.piers?.minimum_latitude,
                data?.piers?.maximum_latitude,
                data?.piers?.minimum_longitude,
                data?.piers?.maximum_longitude
            ];

            return values.some(value => value === null);
        }
    }

    useEffect(() => {
        if (listblocks?.mapSize) {
            const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
            if (d3xScaleyScaleHasNullValue === true) {
                if (listblocks?.piersList && listblocks?.tableCoordinates) {
                    const piersMinMax = findMinMax(listblocks?.piersList);
                    setD3xScaleyScale({ piers: piersMinMax, tt: null })
                }
            }
            else {
                setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
            }
        }

    }, [listblocks?.mapSize])

    useEffect(() => {
        if (listblocks.piersList) {
            rowCallFn()
        }
    }, [listblocks?.piersList])

    const rowCallFn = async () => {
        try {
            await setRowsList({ data: listblocks.piersList })
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        trackerSubmit()
    }, [selectedRow])

    const trackerSubmit = async () => {
        let temp = []
        let hightlightData = []
        selectedRow.map((i) => {
            temp.push(i?.split("-")[2])
            hightlightData.push(parseInt(i?.split("-")[2]))
        })
        setHightLightTracker(hightlightData.filter((item, index) => hightlightData.indexOf(item) === index))
    }

    const handleCheckZimmerman = () => {
        return true
    }

    useEffect(() => {
        let trackerSort = []
        let prt = []
        if (listblocks?.blocksTracker) {
            listblocks?.blocksTracker?.map((i) => {
                if (handleCheckZimmerman()) {
                    prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}`)
                }
                else {
                    prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]}`)
                }
            })

            prt = [...new Set(prt)]

            prt.map((i) => {
                let trName = {}
                trName.name = i
                let tcount = 0
                let subTrackerIds = []
                let subTrackerNames = []
                listblocks?.blocksTracker?.map((j) => {
                    let trackerName
                    if (handleCheckZimmerman()) {
                        trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`
                        if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
                            tcount += j?.count
                        }
                    }
                    else {
                        trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`
                        if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
                            tcount += j?.count
                        }
                    }
                    if (`${i}` === trackerName) {
                        subTrackerIds.push(j?.tracker_type_id)
                        subTrackerNames.push(findTrackerName(j?.tracker_type_id))
                    }
                })
                trName.subTrackerIds = subTrackerIds
                trName.subTrackerNames = subTrackerNames
                trName.count = tcount
                trackerSort.push(trName)
                listblocks?.blocksTracker?.map((j) => {
                    let trackerName
                    trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`
                    if (`${i}` === trackerName) {
                        let newtrName = {}
                        newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
                        newtrName.count = j?.count
                        newtrName.id = j.tracker_type_id
                        trackerSort.push(newtrName)
                    }
                })
            })
        }
        setTrackerList(trackerSort)
    }, [listblocks?.blocksTracker])

    const handleClickActProgress = async (e) => {
        setSelectedActivity(e)
    }

    const handleChangeMarkProgressToggle = () => {
        setEnableMarkProgress(!enableMarkProgress)
    }

    const handleSelectApplyStatus = (data) => {
        if (applyStatus === data) {
            setApplyStatus(null)
        }
        else {
            setApplyStatus(data)
        }
    }

    function checkSubmitBtnEnable() {
        let status = false
        Object.keys(getTrackerWiseDatas())?.map((tracker_name) => {
            if (isRowCompleted(tracker_name)) {
                status = true;
            }
        })
        return status
    }

    useEffect(() => {
        if (Object.keys(piersTorqueTubeIdsWithStatus) !== 0) {
            getCompletedPiersAndTorqueTubes()
        }
    }, [piersTorqueTubeIdsWithStatus])

    const getCompletedPiersAndTorqueTubes = async () => {
        let data = {}
        if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus)?.length === 0) {
            data = piersTorqueTubeIdsWithStatus
        } else {
            data = { ...listblocks?.listOfPiersTorqueTubeIdsWithStatus, ...piersTorqueTubeIdsWithStatus }
        }
        try {
            await getListOfPiersTorqueTubeIdsWithStatus(data)
        } catch (err) {
            console.log(err)
        }
    }

    const getLinkedPartName = () => {
        let partName = ""
        if (listId?.construction && selectedActivity !== "All" && selectedActivity !== "None Selected" && listId?.construction[selectedActivity]) {
            for (let i of listPartsAtom) {
                if (i.id === listId?.construction[selectedActivity]?.part_id) {
                    partName = i?.part_name
                }
            }
        }
        return partName
    }
    const getInprogressDefaultValue = () => {
        let deployedCount = 0
        if (Object.keys(listblocks?.inprogressDeployedCount).length > 0 && listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id]) {
            deployedCount = listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id]
        }
        if (Object.keys(listblocks?.inprogressDeployedCount).length === 0 && listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id] === undefined) {
            deployedCount = getDeployedPartCountOfSelectedTrackerrow()
            updateInprogressDeployedCount({ [currentTrackerType?.tracker_type_id]: deployedCount })
        }
        return deployedCount
    }

    useEffect(() => {
        // if (selectedRow?.length === 1) {
        let selectedTrackerrow = trackerRows
        let activityId = selectedActivity
        let PiersTorqueTubes = {}
        let piers = []
        let torqueTubes = []
        let pierIds = []
        let torqueTubeIds = []
        let trackerTypeId = parseInt(selectedTrackerrow[0]?.split("-")[2])
        let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
        if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus).length !== 0 && listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
            let prData = listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
            PiersTorqueTubes["piers"] = prData
            if (prData !== undefined) {
                for (let i of prData) {
                    if (i?.status === 1) {
                        pierIds.push(i?.pier_id)
                    }
                }
            }
            let ttData = listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]
            PiersTorqueTubes["torque_tubes"] = ttData
            if (ttData !== undefined) {
                for (let j of ttData) {
                    if (j?.status === 1) {
                        torqueTubeIds.push(j?.torque_tube_id)
                    }
                }
            }

        } else {
            if (selectedTrackerrow?.length === 1 && listblocks?.cpTrackerRowWise !== undefined && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key] && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
                let pierData = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["piers"]
                PiersTorqueTubes["piers"] = pierData
                for (let i of pierData) {
                    if (i?.status === 1) {
                        pierIds.push(i?.pier_id)
                    }
                }
            }
            else {
                if (piersTorqueTubesDetails["piers"]) {
                    for (let i of piersTorqueTubesDetails["piers"]) {
                        piers.push({ pier_id: i?.id, status: 0 })

                    }
                    PiersTorqueTubes["piers"] = piers
                }
            }

            if (selectedTrackerrow?.length === 1 && listblocks?.cpTrackerRowWise !== undefined && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key] && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["torque_tubes"]?.length > 0) {
                let ttData = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["torque_tubes"]
                PiersTorqueTubes["torque_tubes"] = ttData
                for (let j of ttData) {
                    if (j?.status === 1) {
                        torqueTubeIds.push(j?.torque_tube_id)
                    }
                }
            } else {
                if (piersTorqueTubesDetails["torque_tube"]) {
                    for (let i of piersTorqueTubesDetails["torque_tube"]) {
                        torqueTubes.push({ torque_tube_id: i?.id, status: 0 })
                    }
                    PiersTorqueTubes["torque_tubes"] = torqueTubes
                }
            }
        }
        let pierTTStatusObj = {}
        pierTTStatusObj[trackerTypeId] = PiersTorqueTubes
        setSelectedPiersTorqueTubes({ piers: pierIds, torque_tubes: torqueTubeIds })
        setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)
        // }
    }, [trackerRows, listblocks?.cpTrackerRowWise])


    const handleSelectPierAndTorqueTubeItem = async () => {
        let temp = {}
        let trackerTypeId = trackerRows[0]?.split("-")[2]
        if (listblocks?.selectedPiersTorqueTubesData["piers"]) {
            let piersDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
            let selectedPierData = listblocks?.selectedPiersTorqueTubesData["piers"]
            let updatedPiers = []
            for (let i of piersDataWithStatus) {
                if (selectedPierData.includes(i?.pier_id)) {
                    updatedPiers.push({ ...i, status: 1 })
                } else {
                    updatedPiers.push({ ...i, status: 0 })
                }
            }
            temp["piers"] = updatedPiers
        }
        if (listblocks?.selectedPiersTorqueTubesData["torque_tubes"]) {
            let torqueTubesDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]

            let selectedTorqueTubeData = listblocks?.selectedPiersTorqueTubesData["torque_tubes"]
            let updatedTorqueTubes = []
            for (let i of torqueTubesDataWithStatus) {
                if (selectedTorqueTubeData.includes(i?.torque_tube_id)) {
                    updatedTorqueTubes.push({ ...i, status: 1 })
                } else {
                    updatedTorqueTubes.push({ ...i, status: 0 })
                }
            }

            temp["torque_tubes"] = updatedTorqueTubes

        }
        let sortedData = sortPierAndTTData(temp)
        let pierTTStatusObj = {}
        pierTTStatusObj[trackerTypeId] = sortedData
        setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)
    }

    function sortPierAndTTData(data) {
        // Sort piers based on pier_id
        data.piers.sort((a, b) => a.pier_id - b.pier_id);

        // Sort torque_tubes based on torque_tube_id
        data.torque_tubes.sort((a, b) => a.torque_tube_id - b.torque_tube_id);
        return data;
    }

    const isRowCompleted = (tracker_name) => {
        let row = listblocks?.blocksTracker.find(o => o?.tracker_type === tracker_name)
        let hasDeployedCount = false;
        if (listblocks?.inprogressDeployedCount && listblocks?.inprogressDeployedCount[row?.tracker_type_id]) {
            hasDeployedCount = listblocks?.inprogressDeployedCount[row?.tracker_type_id] !== 0;
        }

        let hasColorsEntered = false;
        if (listblocks?.listOfPiersTorqueTubeIdsWithStatus &&
            listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id] &&
            listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id]?.piers &&
            listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id]?.torque_tubes) {

            const allPiersCompleted = listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id].piers.some(pier => pier.status === 1);
            const allTorqueTubesCompleted = listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id].torque_tubes.some(tt => tt.status === 1);
            hasColorsEntered = allPiersCompleted || allTorqueTubesCompleted;
        }

        return hasDeployedCount && hasColorsEntered;
    };

    function groupByTrackerType() {
        const groupedData = {};

        selectedRow?.forEach(row => {
            // Split the string to get the tracker type ID (second index in the array)
            const trackerTypeId = parseInt(row?.split('-')[2]);

            // If the key (trackerTypeId) already exists in the object, push the row string to the array
            if (groupedData[trackerTypeId]) {
                groupedData[trackerTypeId].push(row);
            } else {
                // Otherwise, create a new array with the row string
                groupedData[trackerTypeId] = [row];
            }
        });

        return groupedData;
    }

    const pierTtDetails = (trackerTypeId) => {
        if (listblocks?.piersList) {
            let pierTTObj = {}
            let groupedTrackerRows = groupByTrackerType()
            let selectedTrackerTypeTrackerRows = groupedTrackerRows[trackerTypeId]
            let trackerrowId = parseInt(selectedTrackerTypeTrackerRows[0]?.split("-")[1])
            let piersTorqueTubeData = listblocks?.piersList
            let selectedTableData = listblocks?.tableBays[trackerrowId]
            let selectedPierData = piersTorqueTubeData.filter(o => o?.tracker_row_id === trackerrowId)
            if (selectedPierData && selectedTableData) {
                pierTTObj["piers"] = selectedPierData
                pierTTObj["torque_tube"] = selectedTableData
                return { pierTTObj, selectedTrackerTypeTrackerRows };
            }
        }
        return null;
    };

    const handleClickInProgressMarkProgress = async (tracker_name) => {
        let temp = listblocks?.blocksTracker.find(o => o?.tracker_type === tracker_name)
        setCurrentTrackerType(temp)
        let currentSelectedTrackerTypes
        selectedRow.map((i) => {
            let trackerTemp = i.split("-")[2]
            if (parseInt(trackerTemp) === temp?.tracker_type_id) {
                currentSelectedTrackerTypes = i
            }
        })
        let bomData = await get_distributed_and_installed_bom_for_activity(
            selectedActivity,
            currentSelectedTrackerTypes,
            listblocks?.distributedInstalledBom,
            listblocks?.total_installed_distributed_quantity,
            listblocks?.existing_distributed_installed_quantity,
            true,
            {}
        );

        const result = pierTtDetails(temp?.tracker_type_id);
        if (result) {
            setPiersTorqueTubesDetails(result.pierTTObj);
            setTrackerRows(result.selectedTrackerTypeTrackerRows);
        }

        // Load the stored inprogressCount
        const storedCount = listblocks.inprogressDeployedCount[temp.tracker_type_id] || 0;
        setInprogressDeployedPartCount(prev => ({
            ...prev,
            [temp.tracker_type_id]: storedCount
        }));

        const isValid = storedCount > 0 && storedCount <= inprogressTotalPartCount;
        setBtnEnable(isValid);

        if (selectedActivity !== "All" && selectedActivity !== "None Selected") {
            let tracker_type_id = parseInt(temp?.tracker_type_id);
            let partId_trackertypeId_key = `${listId?.construction[selectedActivity]?.part_id}_${tracker_type_id}`
            let totalPartCount = 0
            if (listblocks?.part_per_tracker[partId_trackertypeId_key]) {
                totalPartCount = listblocks?.part_per_tracker[partId_trackertypeId_key]
            }
            setInprogressTotalPartCount(parseInt(totalPartCount))
        }
        setInprogressIndividualTrackerModal({ bool: true, tracker_name: tracker_name, bomdata: bomData, step: 1 })
    }

    const handleCloseWithoutSaving = () => {
        let trackerTypeId = currentTrackerType?.tracker_type_id
        if (trackerTypeId) {
            // Update inprogressDeployedCount

            setDashboard(produce((draft) => {
                let existingInprogressDeployedCountData = { ...draft.inprogressDeployedCount }
                delete existingInprogressDeployedCountData[trackerTypeId]
                draft.inprogressDeployedCount = existingInprogressDeployedCountData
            }))

            // Update listOfPiersTorqueTubeIdsWithStatus

            setDashboard(produce((draft) => {
                let existingPiersTorqueTubeIdsWithStatusData = { ...draft.listOfPiersTorqueTubeIdsWithStatus }
                delete existingPiersTorqueTubeIdsWithStatusData[trackerTypeId]
                draft.listOfPiersTorqueTubeIdsWithStatus = existingPiersTorqueTubeIdsWithStatusData
            }))

            //Update DistributedInstalledQuantity


            setDashboard(produce((draft) => {
                let existingDistributedInstalledQuantityData = { ...draft.updateDistributedAndInstalledQuantity }

                // Get all tracker rows associated with this tracker type
                const associatedTrackerRows = selectedRow.filter(row => row.split('-')[2] === trackerTypeId.toString())
                    .map(row => row.split('-')[1]);

                // Remove entries for all associated tracker rows
                Object.keys(existingDistributedInstalledQuantityData).forEach(key => {
                    const [bomId, trackerRowId] = key.split('_');
                    if (associatedTrackerRows.includes(trackerRowId)) {
                        delete existingDistributedInstalledQuantityData[key];
                    }
                });

                draft.updateDistributedAndInstalledQuantity = existingDistributedInstalledQuantityData
            }))

            // Update local states
            setInprogressDeployedPartCount((prev) => {
                const updated = { ...prev };
                delete updated[trackerTypeId];
                return updated;
            });

            setUpdateQuantity((prev) => {
                const updated = { ...prev };
                delete updated[trackerTypeId];
                return updated;
            });
        }
    }

    useEffect(() => {
        const updateQuantities = async () => {
            const groupedData = groupByTrackerType();
            let allUpdates = {};
            for (const [trackerTypeId, quantities] of Object.entries(updateQuantity)) {
                const updatedForTrackerType = await updated_progress_quantity_for_all_selected_trackerrows(
                    quantities,
                    groupedData[trackerTypeId]
                );
                allUpdates = { ...allUpdates, ...updatedForTrackerType };
            }
            if (Object.keys(allUpdates).length > 0) {
                await updateDistributedAndInstalledQuantity(allUpdates);
            }
        };

        updateQuantities();
    }, [updateQuantity]);

    function checkMarkProgressEnable() {
        let result = true
        if (selectedRow?.length > 0) {
            if (selectedActivity !== "All" && selectedActivity !== "None Selected") {
                if (applyStatus === "Not Started" || applyStatus === "Completed") {
                    result = false
                }
                if (applyStatus === "In-Progress" && listId?.construction && listId?.construction[selectedActivity]?.parent_activity_id) {
                    if (selectedRow?.length === 1) {
                        result = false
                    }
                    else {
                        result = true
                    }
                }
            }
        }
        return result
    }

    useEffect(() => {
        if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus).length > 0)
            updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers()
    }, [listblocks?.listOfPiersTorqueTubeIdsWithStatus])

    const updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers = () => {
        let updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers = get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers(
            listblocks?.piersList,
            selectedRow,
            listblocks?.listOfPiersTorqueTubeIdsWithStatus,
            true, // Set zimmerMan parameter to true
            listblocks.tableBays
        );
        setInprogressPierTTStatusForAllSelectedTrackers(updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers);
    }

    const getDeployedPartCountOfSelectedTrackerrow = () => {
        let deployedCount = 0
        if (selectedRow?.length === 1 && selectedActivity !== "None Selected") {
            let trackerrowId_activityId_key = `${selectedRow[0].split("-")[1]}_${JSON.parse(selectedActivity)}`
            if (listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]) {
                deployedCount = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["deployed_part_count"]
            }
        }
        return deployedCount
    }

    const handleClickMarkProgressApply = async () => {
        try {
            if (["Not Started", "Completed"].includes(applyStatus)) {
                setApplyProgressLoader(true)
                let checkActivityType
                let cpdata = []
                if (listId?.construction[selectedActivity]?.parent_activity_id) {
                    checkActivityType = "sub_activity"
                }
                else {
                    checkActivityType = "parent_activity"
                }
                for (let i of selectedRow) {
                    if (checkActivityType === "parent_activity") {
                        for (let actId in listId?.construction) {
                            if (listId?.construction[actId].parent_activity_id === parseInt(selectedActivity) || parseInt(actId) === parseInt(selectedActivity)) {
                                let temp = await UpdateCp(actId, i)
                                cpdata.push(temp);
                            }
                        }
                    }
                    else {
                        let parentActivityId = listId?.construction[selectedActivity]?.parent_activity_id
                        let selectedActivityData = await UpdateCp(selectedActivity, i)
                        cpdata.push(selectedActivityData);
                        let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId)
                        cpdata.push(parentActivityData);
                    }
                }
                await setUpdateConstructionProgress({ block_id: params.id, dataArr: cpdata, activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id })
                // await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
                // await setFetchBulkPost({ id: params.id })
                toast.success("Progress Updated")
                setSelectedRow([])
                setSectionSelect([])
                setPrevSection(null)
                setTrackerSelect([])
                setPrtTrackerSelect([])
                setHightLightTracker([])
                setDumRow([])
            }
            else {
                setInprogressTrackerTypesModal(true)
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setApplyProgressLoader(false)
        }
    }

    const [modalSecTracIsOpen, setSecTracIsOpen] = useState(false)

    const openSecModal = async () => {
        try {
            setBomLoading(true)
            let tSelectedRow = selectedRow.map((row) => {
                return parseInt(row.split("-")[1])
            })
            let tSelectedRowNo = selectedRow.map((row) => {
                return parseInt(row.split("-")[3])
            })
            let tSelectedSec = selectedRow.map((row) => {
                return parseInt(row.split("-")[4])
            })
            if (selectedRow.length !== 0) {
                await setFetchSelectedTrackerBOM({ block_id: parseInt(params.id), selected_rows: tSelectedRow, tSelectedSec: tSelectedSec, selected_rowsNo: tSelectedRowNo });
            }
            setSecTracIsOpen(true)
            setBomLoading(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    function closeSecTracModal() {
        setSecTracIsOpen(false);
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const openModal = async () => {
        try {
            setBomLoading(true)
            await setFetchBomBlock({ block_id: params.id })
            setIsOpen(true);
            setBomLoading(false)
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }

    function closeTrackerModal() {
        setTrackerModal(false)
    }

    function closeInprogressTrackerModal() {
        setInprogressTrackerTypesModal(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            maxHeight: "650px",
            padding: "0px 20px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const trackersCustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '800px',
            maxHeight: "650px",
            padding: "0px 20px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const inProgressTrackersCustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            maxWidth: "1400px",
            height: "85vh",
            maxHeight: "1000px",
            padding: "0px 20px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleClear = () => {
        setClearSt(!clearSt)
        setSelectAll(false)
        setSectionSelect([])
        setTrackerSelect([])
        setPrtTrackerSelect([])
        setPrevRow(null)
        setPrevTracker([])
        setDumRow([])
    }

    let block_name = listblocks?.blocks.find((i) => i?.id === parseInt(params.id))

    // let listblocks?.bomBlock = _.sortBy(listblocks?.bomBlock, [function (o) { return o.part_name }])

    // let listblocks?.selectedTrackersBom = _.sortBy(listblocks?.selectedTrackersBom, [function (o) { return o.part_name }])

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    function handleCheckDownloadData() {
        let result = false
        let temp = new Set()
        for (let pierData of listblocks?.piersList) {
            temp.add(`trow-${pierData.tracker_row_id}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
        }
        if (selectedRow.length === Array.from(temp).length) {
            result = true
        }
        return result
    }

    const handleDownloadBlock = async () => {
        try {
            toast("Download queued")
            let arr = []
            listblocks?.bomBlock.map((i) => {
                let temp = {}
                temp["Part Name"] = i.part_name?.toUpperCase()
                temp["Description"] = i.description
                temp["Block Qty"] = i.project_quantity_calc
                temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`
                temp["Distributed Quantity"] = i.distributed_quantity
                temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`
                temp["Installed Quantity"] = i.installed_quantity
                arr.push(temp)
            })
            let newFields = [
                "Part Name",
                "Description",
                "Required Qty",
                "Distribution Progress %",
                "Distributed Quantity",
                "Installed Progress %",
                "Installed Quantity"
            ]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", ${block_name?.name} BOM Status\n` + `"Report Date", ${formattedDate}\n` + `"Block", ${block_name?.name}\n` + `\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_BOM for ${block_name?.name}_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    const handleDownloadSelectedTracker = async () => {
        try {
            toast("Download queued");

            let arr = [];
            let sectionTableArr = [];

            listblocks?.selectedTrackersBom.forEach((i) => {
                if (i?.project_quantity_calc) {
                    let temp = {};
                    temp["Section"] = i?.section;
                    temp["Table"] = i?.row_no;
                    temp["Part Name"] = i?.part_name?.toUpperCase();
                    temp["Description"] = i.description;
                    temp["Required Qty"] = i.project_quantity_calc;
                    temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`;
                    temp["Distributed Quantity"] = i.distributed_quantity;
                    temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`;
                    temp["Installed Quantity"] = i.installed_quantity;
                    arr.push(temp);

                    sectionTableArr.push({
                        "Section": i?.section,
                        "Table": i?.row_no,
                    });
                }
            });

            const projectInfo = [
                ["Project Number", project_name?.project_number],
                ["Project Name", project_name?.name],
                ["Report", "Selected Tracker BOM"],
                ["Report Date", formattedDate],
                ["Block", block_name?.name],
                [],
            ];

            const ws1 = XLSX.utils.json_to_sheet([]);
            const ws2 = XLSX.utils.json_to_sheet([]);

            XLSX.utils.sheet_add_aoa(ws1, projectInfo, { origin: "A1" });
            XLSX.utils.sheet_add_aoa(ws2, projectInfo, { origin: "A1" });

            XLSX.utils.sheet_add_json(ws1, arr, { origin: "A7", skipHeader: false });
            XLSX.utils.sheet_add_json(ws2, sectionTableArr, { origin: "A7", skipHeader: false });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, "Selected Trackers BOM");
            XLSX.utils.book_append_sheet(wb, ws2, "Sections & Tables");

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: "application/octet-stream" });
            const url = URL.createObjectURL(data);

            const element = document.createElement("a");
            element.href = url;
            element.download = `${project_name.project_number}_Selected_Trackers_BOM_${project_name.name}.xlsx`;
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            URL.revokeObjectURL(url);

        } catch (err) {
            console.log(err);
            toast.error("Download failed");
        }
    };


    const handleProgressChange = async (e) => {
        if (e === "All" || e === "None Selected") {
            setSelectedActivity(e)
            localStorage.setItem("selected-activity", e)
        }
        else {
            localStorage.setItem("selected-activity", e[0])
            setSelectedActivity(e[0])
        }
        try {
            setCountLoading(true)
            if (e === "None Selected") {
                await setActivityId(e)
            }
            if (e !== "None Selected") {
                let temp = []
                selectedRow.map((i) => {
                    temp.push(i.split("-")[1])
                })
                if (e === "All") {
                    await setActivityId(e)
                    await setDeployOverallCount({ block_id: params.id, tracker: temp })
                }
                else {
                    await setActivityId(e[0])
                    await setDeployCount({ activity_id: e[0], part_id: listId?.construction[e[0]]?.part_id, block_id: params.id, tracker: temp })
                }
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setCountLoading(false)
        }
    }

    function getTrackerWiseDatas() {
        let temp = {}
        selectedRow.map((i) => {
            let tempKey = parseInt(i?.split("-")[2])
            let obj = temp[findTrackerName(tempKey)]
            if (!obj) {
                temp[findTrackerName(tempKey)] = 1
            }
            if (obj) {
                temp[findTrackerName(tempKey)] = temp[findTrackerName(tempKey)] + 1
            }
        })
        return temp
    }

    const findTrackerName = (data) => {
        let name = listblocks?.trackerName.find(k => k?.id === data)
        return name?.tracker_type
    }

    let CPparActIds = []
    let QCparActIds = []

    if (listblocks?.overallCP) {
        for (let cp in listblocks?.overallCP) {
            if (listId?.construction && !listId.construction[cp]?.parent_activity_id) {
                CPparActIds.push(cp)
            }
        }
    }

    if (listblocks.blocksQC) {
        listblocks.blocksQC.map((qc) => {
            if (!listId.qc[qc.activity_id]?.parent_activity_id) {
                QCparActIds.push(qc.activity_id)
            }
        })
    }

    let finalCPArr = []
    let finalQCArr = []

    CPparActIds.map((pt) => {
        finalCPArr.push(parseInt(pt))
        Object.values(listId?.construction)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalCPArr.push(ij.id)
            }
        })
    })

    QCparActIds.map((pt) => {
        finalQCArr.push(parseInt(pt))
        Object.values(listId?.qc)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalQCArr.push(ij.id)
            }
        })
    })

    const findCPprogressCalc = (data) => {
        let temp = 0
        if (listblocks.blocksCP) {
            listblocks.blocksCP.map((i) => {
                if (i.activity_id === data) {
                    temp = i.progress
                }
            })
        }
        return temp
    }

    const findCPprogress = (data) => {
        let temp = 0
        if (listblocks.blocksCP) {
            listblocks.blocksCP.map((i) => {
                if (i.activity_id === data) {
                    temp = i.progress
                }
            })
        }
        if (listId?.construction[data]?.parent_activity_id) {
            return temp
        }
        else {
            let sub_weight_progress = []
            let sub_weight = []
            for (let actKey in listId?.construction) {
                if (listId?.construction[actKey]?.parent_activity_id === data) {
                    sub_weight_progress.push(listId.construction[actKey]?.weight_decimal * findCPprogress(parseInt(actKey)))
                    sub_weight.push(listId.construction[actKey]?.weight_decimal)
                }
            }
            let progressData = sub_weight_progress.reduce((partialSum, a) => partialSum + a, 0) / sub_weight.reduce((partialSum, a) => partialSum + a, 0)
            return progressData
        }
    }

    const findQCprogress = (data) => {
        let temp = 0
        if (listblocks.blocksQC) {
            listblocks.blocksQC.map((i) => {
                if (i?.activity_id === data) {
                    temp = i?.progress
                }
            })
        }
        return temp
    }

    const ttlOverAllPercent = () => {
        let subList = []
        let subWight = []
        if (listId.construction) {
            Object?.keys(listId?.construction)?.map((i) => {
                if (listId?.construction[i]?.parent_activity_id) {
                    subWight.push(listId?.construction[i]?.weight)
                    subList.push(listId?.construction[i]?.weight * findCPprogressCalc(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const ttlOverAllQCPercent = () => {
        let subList = []
        let subWight = []
        if (listId.qc) {
            Object?.keys(listId?.qc)?.map((i) => {
                if (listId?.qc[i]?.parent_activity_id) {
                    subWight.push(listId?.qc[i]?.weight)
                    subList.push(listId?.qc[i]?.weight * findQCprogress(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        if (listblocks?.rowList) {
            setPrevRow(null)
            let temp = []
            listblocks?.rowList.map((i) => {
                temp.push(i?.value)
            })
            setSectionSelect(temp)
            setDumRow(listblocks?.rowList)
        }
        setPrevTracker([])
        let temp = []
        let tempPrt = []
        trackerList.map((i) => {
            if (i?.id) {
                temp.push(i?.id)
            }
            if (i?.name.split("+_+").length !== 2) {
                tempPrt.push(i?.name)
            }
        })
        setPrtTrackerSelect(tempPrt)
        setTrackerSelect(temp)
    }

    useEffect(() => {
        let temp = {}
        selectedRow.map((i) => {
            if (isNaN(temp[i.split("-")[2]])) {
                temp[i.split("-")[2]] = 1
            }
            else {
                temp[i.split("-")[2]] = temp[i.split("-")[2]] + 1
            }
        })
        trackerList.map((i) => {
            if (temp[i?.id] === i?.count) {
                if (!trackerSelect.includes(i.id)) {
                    setTrackerSelect(produce((draft) => { draft.push(i.id) }))
                }
            }
            else if (temp[i?.id] !== i?.count && trackerSelect.includes(i.id) && temp[i?.id]) {
                let index = trackerSelect.findIndex(o => o === i?.id)
                setTrackerSelect(produce((draft) => { draft.splice(index, 1) }))
            }
            else if (trackerSelect.includes(i?.id) && !temp[i?.id]) {
                let tempData = [...trackerSelect]
                let data = tempData.filter(o => o?.id === i?.id)
                setTrackerSelect(data)
            }
        })
    }, [selectedRow])

    const handleMultiSelect = (e) => {
        setDumRow(e)
        let temp = []
        e.map((i) => {
            temp.push(i?.value)
        })
        if (sectionSelect?.length > e?.length) {
            sectionSelect?.map((ij) => {
                let temp = e.filter(o => parseInt(o?.value) !== ij)
                if (temp?.length === e?.length) {
                    setPrevRow(ij)
                }
            })
        }

        setSectionSelect(temp)
        if (e.length === 0) {
            setPrevRow(sectionSelect[0])
            setSectionSelect([])
        }
    }

    const handleRowSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevRow(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevRow(null)
        }
    }

    useEffect(() => {
        let selectedTrackerNames = []
        trackerSelect.map((trackerId) => {
            selectedTrackerNames.push(findTrackerName(trackerId))
        })
        let trackerObj = {}
        selectedTrackerNames.map((trackerName) => {
            let prtTrackerName = `${trackerName.split("_")[0]}_${trackerName.split("_")[1]}`
            if (trackerObj[prtTrackerName]) {
                trackerObj[prtTrackerName] = trackerObj[prtTrackerName] + 1
            }
            else {
                trackerObj[prtTrackerName] = 1
            }
        })
        trackerList?.map((trackerData) => {
            if (trackerData?.subTrackerIds?.length > 0) {
                if (trackerData?.subTrackerIds.length === trackerObj[trackerData.name]) {
                    // setPrtTrackerSelect(produce((draft) => { draft.push(trackerData.name) }))
                }
                else {
                    // setPrtTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === trackerData.name), 1) }))
                }
            }
        })
    }, [trackerSelect])

    const handleTrackerRowSelection = (dataTT) => {
        if (dataTT?.name.split("+_+").length === 2) {
            if (trackerSelect.includes(dataTT.id)) {
                setTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === dataTT.id), 1) }))
                setPrevTracker([dataTT.id])
            }
            else {
                setTrackerSelect(produce((draft) => { draft.push(dataTT.id) }))
                setPrevTracker([])
            }
        }
        else {
            let sub_tracker_types = []
            dataTT?.subTrackerIds?.map((sub_tracker_ids) => {
                let temp = trackerList.find(o => o?.id === sub_tracker_ids)
                sub_tracker_types.push(temp)
            })

            // Check if all sub_tracker_types are already selected
            const allSubTrackersSelected = sub_tracker_types.every(subTracker =>
                trackerSelect.includes(subTracker.id)
            )

            if (allSubTrackersSelected) {
                // Remove all sub trackers
                let sub_tracker_ids = []
                sub_tracker_types?.map((subTracker) => {
                    sub_tracker_ids.push(subTracker?.id)
                    setTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === subTracker.id), 1) }))
                })
                setPrtTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === dataTT.name), 1) }))
                setPrevTracker(sub_tracker_ids)
            }
            else {
                // Add all sub trackers that aren't already selected
                setPrtTrackerSelect(produce((draft) => { draft.push(dataTT?.name) }))
                setTrackerSelect(produce((draft) => {
                    sub_tracker_types.forEach(subTracker => {
                        if (!draft.includes(subTracker.id)) {
                            draft.push(subTracker.id)
                        }
                    })
                    return draft
                }))
            }
        }
    }
    useEffect(() => {
        if (listblocks?.zimmermanSelectedPiersData || listblocks?.zimmermanSelectedBaysData) {
            setSaveBtnEnable(true)
        }
    }, [listblocks?.zimmermanSelectedPiersData, listblocks?.zimmermanSelectedBaysData])

    const handleSaveInProgressSteps = () => {
        const trackerTypeId = currentTrackerType?.tracker_type_id;

        if (!trackerTypeId) {
            toast.error("No tracker type selected");
            return;
        }

        // Get selected pier IDs from the listblocks state for this tracker type
        const selectedPierIds = listblocks?.zimmermanSelectedPiersData[trackerTypeId] || [];
        const selectedBayIds = listblocks?.zimmermanSelectedBaysData[trackerTypeId] || [];

        // Create pier objects with status based on the selected pier IDs
        const allPiers = piersTorqueTubesDetails?.piers || [];
        const piersWithStatus = allPiers.map(pier => ({
            pier_id: pier.id,
            status: selectedPierIds.includes(pier.id) ? 1 : 0
        }));

        // Create torque tube objects with status based on the selected bay IDs
        const allBays = piersTorqueTubesDetails?.torque_tube || [];
        const baysWithStatus = allBays.map(bay => ({
            torque_tube_id: bay.id,
            status: selectedBayIds.includes(bay.id) ? 1 : 0
        }));

        // Update the listOfPiersTorqueTubeIdsWithStatus atom with the updated pier and bay data
        setDashboard(produce((draft) => {
            if (!draft.listOfPiersTorqueTubeIdsWithStatus) {
                draft.listOfPiersTorqueTubeIdsWithStatus = {};
            }

            if (!draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
                draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId] = {
                    piers: [],
                    torque_tubes: []
                };
            }

            draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId].piers = piersWithStatus;
            draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId].torque_tubes = baysWithStatus;
        }));

        // Close the modal
        setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 });

        // Show success message
        toast.success("Progress saved successfully");
    };
    // Submit button logic for in-progress
    const handleSubmitInProgress = async () => {
        try {
            if (applyStatus !== "In-Progress") {
                toast.error("Please select In-Progress status");
                return;
            }

            setInProgressSubmitLoader(true);
            let cpdata = [];
            let checkActivityType = "";

            for (let i of selectedRow) {
                const trackerTypeId = i?.split("-")[2].toString();

                // Check if this tracker type has selected piers or quantities
                if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus)?.includes(trackerTypeId)) {
                    // Determine activity type
                    if (listId.construction[selectedActivity]?.parent_activity_id === null) {
                        checkActivityType = "parent_activity";
                    } else {
                        checkActivityType = "sub_activity";
                    }

                    // Handle parent activities
                    if (checkActivityType === "parent_activity") {
                        for (let actId in listId?.construction) {
                            if (listId.construction[actId].parent_activity_id === parseInt(selectedActivity) ||
                                parseInt(actId) === parseInt(selectedActivity)) {
                                let temp = await UpdateCp(actId, i);
                                cpdata.push(temp);
                            }
                        }
                    } else {
                        // Handle sub-activities
                        let parentActivityId = listId?.construction[selectedActivity]?.parent_activity_id;
                        let selectedActivityData = await UpdateCp(selectedActivity, i);
                        cpdata.push(selectedActivityData);
                        let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId);
                        cpdata.push(parentActivityData);
                    }
                }
            }

            // Update construction progress status in the backend
            await setUpdateConstructionProgress({
                block_id: params.id,
                dataArr: cpdata
            });

            // Reset state variables
            await updateInprogressDeployedCount({});
            await setDeployCount({
                activity_id: localStorage.getItem("selected-activity"),
                part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id,
                block_id: params.id,
                tracker: []
            });

            // Fetch updated data
            await setFetchBulkPost({ id: params.id });

            // Close modals
            setInprogressTrackerTypesModal(false);
            setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 });

            // Clear selected items
            setInprogressDeployedPartCount({});
            getListOfPiersTorqueTubeIdsWithStatus({});
            setSectionSelect([]);
            setPrevSection(null);
            setTrackerSelect([]);
            setPrtTrackerSelect([]);
            setHightLightTracker([]);
            setSelectedRow([]);

            toast.success("Progress Updated");
        } catch (err) {
            console.log(err);
            toast.error("Something went wrong");
        } finally {
            setInProgressSubmitLoader(false);
        }
    };

    // UpdateCp function for Zimmerman specific logic
    const UpdateCp = async (selected_activity_id, selectedTrackerrow, activityType, parentActivityId) => {
        let statusValue = 0;
        if (applyStatus === "Not Started") {
            statusValue = 0;
        }
        if (applyStatus === "In-Progress") {
            statusValue = 1;
        }
        if (applyStatus === "Completed") {
            statusValue = 2;
        }

        let updatedData = {};
        let bomData = await get_distributed_and_installed_bom_for_activity(
            selected_activity_id,
            selectedTrackerrow,
            listblocks?.distributedInstalledBom,
            listblocks?.total_installed_distributed_quantity,
            listblocks?.existing_distributed_installed_quantity,
            true, // isZimmerman = true
            {}
        );

        let foundType = "";
        if (bomData) {
            if (Object.keys(bomData).length === 1) {
                for (let i in bomData) {
                    foundType = i;
                }
            } else {
                foundType = "distributed_installed";
            }
        }
        updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(
            listblocks?.piersList,
            {
                block_id: params.id,
                block: block_name?.name
            },
            bomData,
            selected_activity_id,
            listblocks?.updateDistributedAndInstalledQuantity,
            parseInt(statusValue),
            foundType,
            listblocks?.part_per_tracker,
            listId?.construction,
            selectedTrackerrow,
            listblocks?.inprogressDeployedCount, // user entered value in-progress
            inprogressPierTTStatusForAllSelectedTrackers, // Use the consolidated pier data
            true, // isZimmerman = true
            activityType,
            parentActivityId,
            listblocks?.cpTrackerRowWise,
            listblocks?.tableCoordinates
        );

        return updatedData;
    };

    return (
        <Container>
            <div className='flex justify-between overflow-auto w-full h-full'>
                <div className="mr-2 h-full" style={{ width: showProgress ? "calc(100vw - 620px)" : "100%" }}>
                    <div className='my-4 py-0.5 flex justify-between'>
                        <div className='font-semibold mx-5' style={{ textTransform: "capitalize" }}>
                            Construction - {selectedActivity === "All" ?
                                "Overall"
                                :
                                selectedActivity !== "None selected" ?
                                    listId?.construction && listId?.construction[selectedActivity]?.name
                                    :
                                    null
                            } Progress
                        </div>
                        <div className='flex items-center'>
                            {showProgress ?
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowProgress(false)} />
                                </div>
                                : (
                                    <div className='text-xl text-black border bg-gray-200 mx-2'>
                                        <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowProgress(true)} />
                                    </div>
                                )}
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center space-x-5'>
                        <div className='flex pt-1.5'>
                            <BiArrowBack className='cursor-pointer mx-5 mt-0.5' style={{ width: "20px", height: "20px" }} onClick={() => navigate("/dashboard")} />
                            <p className='font-semibold'>{block_name?.name}</p>
                        </div>

                        <div className='flex items-center'>
                            <div className='m-2'>
                                {/* <p className='text-gray-400 text-sm font-semibold mb-2'>Row</p> */}
                                {/* <MultiSelect
                                    className='w-[150px] rounded-2xl cursor-pointer'
                                    options={listblocks?.rowList}
                                    value={dumRow}
                                    onChange={e => handleMultiSelect(e)}
                                    labelledBy={`Select Row(s)`}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    ClearSelectedIcon={null}
                                /> */}
                                {/* <MultiSelect
                                    className='w-[150px] rounded-2xl cursor-pointer'
                                    options={[
                                        { value: 'clear-all', label: 'Clear All' },
                                        ...(listblocks?.rowList || [])
                                    ]}
                                    value={dumRow}
                                    onChange={e => handleMultiSelect(e)}
                                    labelledBy={`Select Row(s)`}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    ClearSelectedIcon={null}
                                    // closeOnClickOutside={true}
                                    ItemRenderer={({ checked, option, onClick, disabled }) => (
                                        <div>
                                            {option.value === 'clear-all' ? (
                                                <div className="relative h-4">
                                                    <div
                                                        className="absolute top-1 right-2 text-xs text-red-550 font-medium cursor-pointe"
                                                        onClick={() => {
                                                            handleClear();
                                                        }}
                                                    >
                                                        Clear All
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={`px-2 py-1 flex items-center`}
                                                    onClick={onClick}
                                                >
                                                    <input type="checkbox" checked={checked} onChange={() => { }} className="mx-1" />
                                                    <span>{option.label}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                /> */}

                                <div className="relative mb-2">
                                    <MultiSelect
                                        className='w-[150px] h-8 rounded-2xl cursor-pointer text-xs p-0'
                                        options={[
                                            { value: 'clear-all', label: 'Clear All' },
                                            ...(listblocks?.rowList || [])
                                        ]}
                                        value={dumRow}
                                        onChange={e => handleMultiSelect(e)}
                                        labelledBy="Select Row(s)"
                                        closeOnClickOutside={true}
                                        valueRenderer={(selected) => {
                                            if (selected.length === 0) {
                                                return <span className="px-2 text-xs">Select Row(s)</span>;
                                            } else {
                                                return (
                                                    <span className="px-2 text-xs truncate">
                                                        {selected.map(item => item.label).join(', ')}
                                                    </span>
                                                );
                                            }
                                        }}
                                        hasSelectAll={false}
                                        disableSearch={true}
                                        ClearSelectedIcon={null}
                                        ItemRenderer={({ checked, option, onClick, disabled }) => (
                                            <div>
                                                {option.value === 'clear-all' ? (
                                                    <div className="relative h-4">
                                                        <div
                                                            className="absolute top-1 right-2 text-xs text-red-550 font-medium cursor-pointer"
                                                            onClick={() => {
                                                                handleClear();

                                                            }}
                                                        >
                                                            Clear All
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`px-2 py-0.5 flex items-center text-xs`}
                                                        onClick={onClick}
                                                    >
                                                        <input type="checkbox" checked={checked} onChange={() => { }} className="mx-1 h-3 w-3" />
                                                        <span>{option.label}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={`cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm`} disabled={!selectedRow.length > 0} onClick={() => setTrackerModal(true)}>
                                View Selected Tables
                            </div>
                            <div className='flex items-center ml-2 mr-1'>
                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleSelectAll}>Select All</p>
                            </div>
                            <div className='flex items-center ml-2 mr-1'>
                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</p>
                            </div>
                        </div>
                    </div>
                    <hr />

                    {loading ?
                        <div style={{ marginTop: "20%", paddingLeft: "50%", width: "calc(100vw - 705px)" }}>
                            <p className='loader ml-5 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p>
                        </div>
                        :
                        <div className='cursor-grab m-auto' style={{ width: showProgress ? "100%" : "98%", height: "100%" }}>
                            {/* <BlockMap
                                deviceType="web"
                                zimmerMan={1}
                                bulkPierData={listblocks?.piersList}
                                bulkAllTTData={listblocks?.tableCoordinates}
                                tableBays={listblocks?.tableBays}
                                pierLegends={listblocks?.pierLegends}
                                type="CP"
                                typeData={listblocks.cpTrackerRowWise}
                                userSelection={{ showType: "pier", selection: listblocks.selectedActivity }}
                                currentProjectData={projectsList.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                showLegend={true}
                                mapSize={d3xScaleyScale}
                                selectAll={selectAll}
                                section={sectionSelect}
                                prevRow={prevRow}
                                trackerSelect={trackerSelect}
                                prevTracker={prevTracker}
                                clearSelection={clearSt}
                            /> */}
                            <ZimmerBlock
                                deviceType="web"
                                zimmerMan={1}
                                bulkPierData={listblocks?.piersList}
                                bulkAllTTData={listblocks?.tableCoordinates}
                                tableBays={listblocks?.tableBays}
                                pierLegends={listblocks?.pierLegends}
                                type="CP"
                                typeData={listblocks.cpTrackerRowWise}
                                userSelection={{ showType: "pier", selection: listblocks.selectedActivity }}
                                currentProjectData={projectsList.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                showLegend={true}
                                mapSize={d3xScaleyScale}
                                selectAll={selectAll}
                                section={sectionSelect}
                                prevRow={prevRow}
                                trackerSelect={trackerSelect}
                                prevTracker={prevTracker}
                                clearSelection={clearSt}
                                laydown={false}
                            />
                        </div>
                    }

                </div>
                {showProgress && <div className='border-x h-full'>
                    <div className="overflow-auto relative h-full pr-2" style={{ height: "calc(100vh - 100px)", width: "600px" }}>
                        <div>
                            <div className='flex w-full justify-between items-center bg-white my-6 text-blue-500 font-semibold text-sm pl-6 pr-2'>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="BOM status"
                                >
                                    <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                        <p className='mt-2'>{block_name?.name} - Bill of Materials</p>
                                        <div className='flex items-center space-x-5'>
                                            <p className='text-sm underline underline-offset-2 cursor-pointer hover:text-blue-500 text-blue-400' onClick={() => setTrackerModal(true)}>View Selected Tables</p>
                                            <div className='flex font-semibold text-sm mr-10 cursor-pointer' onClick={handleDownloadBlock}>
                                                <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
                                                <p className='text-gray-500 ml-1.5'>Download</p>
                                            </div>
                                            <VscClose onClick={closeModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <table className={`table table-compact z-0 w-[100%]`}>
                                            <thead>
                                                <tr className='sticky z-20' style={{ top: "76px" }}>
                                                    <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
                                                    <th className='normal-case' rowSpan='2' style={{ width: "45%" }}>Part Description</th>
                                                    <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Block Qty</th>
                                                    <th className='normal-case' colspan="2" style={{ width: "13%" }}>Distributed</th>
                                                    <th className='normal-case' colspan="2" style={{ width: "13%" }}>Installed</th>
                                                </tr>
                                                <tr className='sticky z-20' style={{ top: "112.5px" }}>
                                                    <th className='normal-case'>%</th>
                                                    <th className='normal-case'>Qty</th>
                                                    <th className='normal-case'>%</th>
                                                    <th className='normal-case'>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listblocks?.bomBlock.map((i) => {
                                                    return (
                                                        <tr className='hover'>
                                                            <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                            <td className='pr-12 relative' style={{ width: "22vw" }} onMouseEnter={() => { setShowDescription(i.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                {showDescription === i?.bom_id ?
                                                                    <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                        {i?.description}
                                                                    </p>
                                                                    :
                                                                    <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "22vw" }}>{i.description}</p>
                                                                }
                                                            </td>
                                                            <td>{i?.project_quantity_calc}</td>
                                                            <td>
                                                                {roundOff(i?.distribution_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                    :
                                                                    roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {roundOff(i?.distribution_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                    :
                                                                    roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {roundOff(i?.installed_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                                                                    :
                                                                    roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_progress)}%</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{roundOff(i?.installed_progress)}%</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {roundOff(i?.installed_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                                                                    :
                                                                    roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_quantity)}</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{roundOff(i?.installed_quantity)}</p>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal>
                                <div className='flex space-x-2'>
                                    <div className='flex'>
                                        {selectedRow && selectedRow?.length > 0 ?
                                            handleCheckDownloadData() ?
                                                <div className='mr-10' onClick={openModal}>
                                                    <div className='flex cursor-pointer'>
                                                        <p>BOM Status for {block_name?.name}</p>
                                                        {bomLoading ?
                                                            <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                            :
                                                            <BsBoxArrowUpRight alt='' className='ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div onClick={openSecModal}>
                                                    <div className='flex cursor-pointer'>
                                                        <p>BOM Status for Selected Tables</p>
                                                        {bomLoading ?
                                                            <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                            :
                                                            <BsBoxArrowUpRight className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                                        }
                                                    </div>
                                                </div>

                                            :
                                            <div className='text-gray-300'>
                                                <div className='flex'>
                                                    <p>BOM Status for Selected Tables</p>
                                                    <BsBoxArrowUpRight className='ml-1' style={{ width: '17px', height: '17px' }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flex items-center cursor-pointer" onClick={handleChangeMarkProgressToggle}>
                                    <button
                                        className={`relative inline-flex items-center w-12 h-5 rounded-full transition-colors duration-300 focus:outline-none ${enableMarkProgress ? 'bg-red-200' : 'bg-gray-200'
                                            }`}
                                        role="switch"
                                        aria-checked={enableMarkProgress}
                                    >
                                        <div
                                            className={`absolute left-1 transform transition-transform duration-300 w-4 h-4 rounded-full ${enableMarkProgress ? 'translate-x-6 bg-red-550' : 'translate-x-0 bg-white'
                                                } shadow-md`}
                                            style={{ top: '2px' }} // Ensures perfect vertical centering
                                        />
                                    </button>
                                    <span className={`ml-3 text-sm ${enableMarkProgress ? "text-gray-700" : "text-gray-400"}`}>
                                        Mark Progress
                                    </span>
                                </div>
                                <Modal
                                    isOpen={modalSecTracIsOpen}
                                    onRequestClose={closeSecTracModal}
                                    style={customStyles}
                                    contentLabel="BOM status For Selected Tables"
                                >
                                    <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                        <p className='mt-2'>{block_name?.name} - Selected Tables - Bill of Materials</p>
                                        <div className='flex items-center space-x-5'>
                                            <p className='text-sm underline underline-offset-2 cursor-pointer hover:text-blue-500 text-blue-400' onClick={() => setTrackerModal(true)}>View Selected Tables</p>
                                            <div className='flex font-semibold text-sm mr-10 cursor-pointer' onClick={handleDownloadSelectedTracker}>
                                                <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
                                                <p className='text-gray-500 ml-1.5'>Download</p>
                                            </div>
                                            <VscClose onClick={closeSecTracModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                                        </div>
                                    </div>
                                    <table className="table table-compact z-0 overflow-auto w-full" >
                                        <thead>
                                            <tr className='sticky z-20' style={{ top: "76px" }}>
                                                {/* <th className='normal-case' colspan='2' style={{ width: "14%" }}>Selected Tables</th> */}
                                                <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
                                                <th className='normal-case' rowSpan='2' style={{ width: "36%" }}>Part Description</th>
                                                <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Required Qty</th>
                                                <th className='normal-case' colspan="2" style={{ width: "10%" }}>Distributed</th>
                                                <th className='normal-case' colspan="2" style={{ width: "10%" }}>Installed</th>
                                            </tr>
                                            <tr className='sticky z-20' style={{ top: "112.5px" }}>
                                                {/* <th className='normal-case'>Row</th>
                                                <th className='normal-case'>Table</th> */}
                                                <th className='normal-case'>%</th>
                                                <th className='normal-case'>Qty</th>
                                                <th className='normal-case'>%</th>
                                                <th className='normal-case'>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listblocks?.selectedTrackersBom.map((i) => {
                                                if (i?.project_quantity_calc) {
                                                    return (
                                                        <tr className="hover">
                                                            {/* <td>{i?.section}</td>
                                                            <td>{i?.row_no}</td> */}
                                                            <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                            <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                {i?.bom_id && showDescription === i?.bom_id ?
                                                                    <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                        {i?.description}
                                                                    </p>
                                                                    :
                                                                    <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{i?.description}</p>
                                                                }
                                                            </td>
                                                            <td>{i?.project_quantity_calc}</td>
                                                            <td>
                                                                {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                    :
                                                                    roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{i?.distribution_progress ? roundOff(i?.distribution_progress) : 0}%</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                    :
                                                                    roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{i?.distributed_quantity ? roundOff(i?.distributed_quantity) : 0}</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                                                                    :
                                                                    roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{i?.installed_progress ? roundOff(i?.installed_progress) : 0}%</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                                                                    <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                                                                    :
                                                                    roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                        <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                                                        :
                                                                        <p style={{ color: "black" }}>{i?.installed_quantity ? roundOff(i?.installed_quantity) : 0}</p>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            })}
                                        </tbody>
                                    </table>
                                </Modal>
                            </div>
                        </div>

                        <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>
                            <div>
                                {hoverActivity === "All" && !loading ?
                                    <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-10 bg-white py-1 px-1 border' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }} onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)} onClick={() => handleClickActProgress("All")}>
                                        <p>Construction Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {ttlOverAllPercent()}%</>}
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                                {selectedActivity === "All" && !loading ?
                                    <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-9 bg-white py-1 px-1 border-2 border-red-550' style={{ textTransform: "capitalize", boxShadow: "2px 5px #f5a6a6" }} onClick={() => handleClickActProgress("None Selected")}>
                                        <p>Construction Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {ttlOverAllPercent()}%</>}
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                                <div className='flex justify-between font-bold text-base' onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)}>
                                    <p>Construction Progress</p>
                                    <p className='text-red-550'>
                                        {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                            {ttlOverAllPercent()}%</>}
                                    </p>
                                </div>
                                <div className='font-medium text-xs mt-3'>
                                    {loading ?
                                        <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                            <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                            <p className='font-medium text-sm'>Fetching...</p>
                                        </div>
                                        :
                                        finalCPArr.map((i) => {
                                            return (
                                                <div className='relative' onMouseEnter={() => setHoverActivity(i)} onMouseLeave={() => setHoverActivity(-1)}>
                                                    {hoverActivity === i && selectedActivity !== i ?
                                                        <div className='flex justify-between cursor-pointer w-full absolute z-10 bg-white py-1 px-1 border' style={{ boxShadow: "2px 5px #c2c0c0" }} onClick={() => handleClickActProgress(i)}>
                                                            {listId.construction[i]?.parent_activity_id ?
                                                                <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                :
                                                                <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                            }
                                                            <div className='flex justify-between w-[50%]'>
                                                                <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                <p>{roundOff(findCPprogress(i))}%</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {selectedActivity !== "All" && selectedActivity !== "None Selected" && selectedActivity === i ?
                                                        <div className='flex flex-col space-y-3 w-full absolute z-9 bg-white py-1 px-1 border-2 border-red-550'>
                                                            <div className='flex cursor-pointer justify-between' onClick={() => { handleClickActProgress("None Selected"); setOverallProgress("None Selected"); setActivityId("None Selected"); localStorage.setItem("selected-activity", "None Selected") }}>
                                                                {listId.construction[i]?.parent_activity_id ?
                                                                    <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                    :
                                                                    <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                }
                                                                <div className='flex justify-between w-[50%]'>
                                                                    <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                    <p>{roundOff(findCPprogress(i))}%</p>
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-end cursor-pointer' onClick={() => { handleClickActProgress("None Selected"); setOverallProgress("None Selected"); setActivityId("None Selected"); localStorage.setItem("selected-activity", "None Selected") }}>
                                                                {countLoading ?
                                                                    <p className='loader' style={{ width: "15px", height: "15px" }}></p>
                                                                    :
                                                                    <div className='flex space-x-1'>
                                                                        <p className=''>{listblocks?.count?.deployed_part_count && listblocks?.count?.deployed_part_count[0]?.deployed_count !== null ? listblocks?.count?.deployed_part_count[0]?.deployed_count?.toLocaleString() : 0}</p>
                                                                        <p style={{ textTransform: "lowercase" }}>out of</p>
                                                                        <p className=''>{listblocks?.count?.total_part_count?.toLocaleString()}</p>
                                                                        <p className="pl-2">
                                                                            {listPartsAtom.map((j) => {
                                                                                return <>
                                                                                    {j.id === listId?.construction[overallProgress]?.part_id ?
                                                                                        j?.part_name
                                                                                        : null}
                                                                                </>
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {enableMarkProgress ?
                                                                <div>
                                                                    <div className='flex flex-col space-y-3 m-1'>
                                                                        {selectedRow?.length > 0 ?
                                                                            <div className='flex justify-around text-xs'>
                                                                                <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "Not Started" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("Not Started")}>Not Started</p>
                                                                                {listId?.construction && listId?.construction[selectedActivity]?.parent_activity_id && selectedRow?.length === 1 ?
                                                                                    <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "In-Progress" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("In-Progress")}>In-Progress</p>
                                                                                    :
                                                                                    <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>In-Progress</p>
                                                                                }
                                                                                <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "Completed" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("Completed")}>Completed</p>
                                                                            </div>
                                                                            :
                                                                            <div className='flex justify-around text-xs'>
                                                                                <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>Not Started</p>
                                                                                <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>In-Progress</p>
                                                                                <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>Completed</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {checkMarkProgressEnable() ?
                                                                        <p className='mt-5 bg-gray-300 rounded-full text-center mx-10 mb-2 py-2 text-gray-500 cursor-pointer text-white m-auto'>
                                                                            Apply
                                                                        </p>
                                                                        :
                                                                        <p className='mt-5 bg-red-550 rounded-full flex justify-center space-x-3 items-center mx-10 mb-2 py-2 cursor-pointer text-white m-auto' onClick={handleClickMarkProgressApply}>
                                                                            {applyProgressLoader ?
                                                                                <span className='loader' style={{ width: "20px", height: "20px" }}></span>
                                                                                :
                                                                                null
                                                                            }
                                                                            <p>Apply</p>
                                                                        </p>
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className={`flex justify-between py-1.5 my-1.5 w-full ${selectedActivity === i ? enableMarkProgress ? "pb-36" : "pb-8" : "border"}`}>
                                                        {listId.construction[i]?.parent_activity_id ?
                                                            <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                            :
                                                            <p className='font-extrabold max-w-[155px] w-[50%] pl-1'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                        }
                                                        {selectedActivity !== i ?
                                                            <div className='flex justify-between z-1 w-[50%]'>
                                                                <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                <p>{roundOff(findCPprogress(i))}%</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`border rounded-xl ml-6 mt-6 p-3`} style={{ width: '550px' }}>
                            <div>
                                <div className='flex justify-between font-bold text-base'>
                                    <p>Quality Check Progress</p>
                                    <p className='text-red-550'>
                                        {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                            {qcCheck() ? ttlOverAllQCPercent() : 0}%</>}
                                    </p>
                                </div>
                                {qcCheck() ?
                                    <div className='font-medium text-xs'>
                                        {loading ?
                                            <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                <p className='font-medium text-sm'>Fetching...</p>
                                            </div>
                                            :
                                            finalQCArr.map((i) => {
                                                return <>
                                                    <div className='flex justify-between mt-3'>
                                                        {listId.qc[i]?.parent_activity_id ?
                                                            <p className='flex pl-4 w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                            :
                                                            <p className='font-extrabold w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                        }
                                                        <div className='flex justify-between' style={{ width: '215px' }}>
                                                            <progress className={`progress w-40 mt-1 ${findQCprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findQCprogress(i)} max="100"></progress>
                                                            <p>{roundOff(findQCprogress(i))}%</p>
                                                        </div>
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                        <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>                            <div>
                            <div className='flex justify-between font-bold text-base'>
                                <p>Table Type</p>
                                <p>Quantities</p>
                            </div>
                            <div className='font-medium text-xs'>
                                {loading ?
                                    <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                        <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                        <p className='font-medium text-sm'>Fetching...</p></div>
                                    :
                                    trackerList?.map((i, index) => {
                                        return (
                                            <div key={index} onMouseEnter={() => setHoverTracker(i?.name)} onMouseLeave={() => setHoverTracker(null)}>
                                                {hoverTracker === i?.name && !trackerSelect.includes(i?.id) && !prtTrackerSelect.includes(i?.name) ?
                                                    <div className={`flex justify-between w-[530px] cursor-pointer absolute z-10 bg-white py-0.5 px-1 border`} style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }} onClick={() => handleTrackerRowSelection(i)}>
                                                        {i?.name.split("+_+").length === 2 ?
                                                            <p className={`ml-4 px-4 py-1 `}>
                                                                {i?.name.split("+_+")[0]}
                                                            </p>
                                                            :
                                                            <p className='font-extrabold py-1 pl-1'>{i?.name}</p>
                                                        }
                                                        <p className='mr-1'>{i?.count}</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className={`${hightLightTracker.includes(i?.id) ? "border-red-550 border-2" : ""} ${trackerSelect.includes(i?.id) || prtTrackerSelect.includes(i?.name) ? "bg-red-550 text-white border-red-550" : ""} border cursor-pointer flex items-center justify-between mt-4`} onClick={() => handleTrackerRowSelection(i)}>
                                                    {i?.name.split("+_+").length === 2 ?
                                                        <p className={`ml-4 px-4 py-1 `}>
                                                            {i?.name.split("+_+")[0]}
                                                        </p>
                                                        :
                                                        <p className='font-extrabold py-1 pl-1'>{i?.name}</p>
                                                    }
                                                    <p className='mr-1'>{i?.count}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                }
                <Modal
                    isOpen={openTrackerModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeTrackerModal}
                    style={trackersCustomStyles}
                    contentLabel='Tracker items'
                >
                    <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                        <p className='mt-2'>{`Selected Table(s)`}</p>
                        <div className='flex'>
                            <VscClose onClick={closeTrackerModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                        </div>
                    </div>
                    <div>
                        <div className='mb-3 w-[45%]'>
                            {Object.keys(getTrackerWiseDatas()).map((i) => {
                                return (
                                    <div className='flex items-center justify-between text-sm'>
                                        <p className='text-base my-1' style={{ textTransform: "capitalize" }}>{i}</p>
                                        <div className='flex space-x-5 w-[20%]'>
                                            <p className='text-base font-medium my-1'>-</p>
                                            <p className='text-sm my-1'>{getTrackerWiseDatas()[i]}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='flex'>
                            <p className='font-bold text-xl w-[36%] pb-2' style={{ top: "76px" }}>Total</p>
                            <div className='flex space-x-5'>
                                <p className='text-base font-medium'>-</p>
                                <p className='text-base ml-2'>{selectedRow.length} {`table(s)`}</p>
                            </div>
                        </div>
                        <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                            <thead>
                                <tr className='sticky z-20' style={{ top: "75px" }}>
                                    <th className='normal-case'>Row</th>
                                    <th className='normal-case'>Table</th>
                                    <th className='normal-case'>Table Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedRow.map((i) => {
                                    return (
                                        <tr>
                                            <td>{i?.split("-")[4]}</td>
                                            <td>{i?.split("-")[3]}</td>
                                            <td style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(i?.split("-")[2]))}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Modal>
                <Modal
                    isOpen={inprogressTrackerTypesModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeInprogressTrackerModal}
                    style={inProgressTrackersCustomStyles}
                    contentLabel='In-Progress Tracker Types'
                >
                    <div className='h-full'>
                        <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-30'>
                            <div className='flex flex-col'>
                                <p className='my-2'>
                                    {`Block ${block_name?.name}`}
                                </p>
                                <p className='text-gray-500 text-sm font-medium'><span className='text-black font-semibold'>{selectedRow?.length}</span> Table(s) selected</p>
                                {!["All", "None Selected"].includes(selectedActivity) && listId?.construction ?
                                    <p className='text-gray-500 text-sm flex justify-between items-center space-x-2 font-medium mt-2'>
                                        <span className='text-black font-semibold'>{listId?.construction[listId?.construction[selectedActivity]?.parent_activity_id]?.name}</span>
                                        <span className=''><FaLongArrowAltRight /></span>
                                        <span className='text-black font-semibold'>{listId?.construction[selectedActivity]?.name}</span>
                                    </p>
                                    :
                                    null
                                }
                            </div>
                            <div className='flex'>
                                <VscClose onClick={closeInprogressTrackerModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                            </div>
                        </div>
                        <div className='w-full h-[70%] overflow-auto'>
                            <table className='table table-compact z-0 overflow-auto w-full'>
                                <thead>
                                    <tr className='sticky z-20 top-0'>
                                        <th className='normal-case w-[60%]'>Tracker Type Name</th>
                                        <th className='normal-case w-[20%]'>Quantities</th>
                                        <th className='w-[10%]'></th>
                                        <th className='w-[10%]'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(getTrackerWiseDatas()).map((i) => {
                                        const isTickMarkEnable = isRowCompleted(i)
                                        return (
                                            <tr>
                                                <td className='pl-2'>
                                                    <p style={{ textTransform: "capitalize" }}>{i}</p>
                                                </td>
                                                <td>{getTrackerWiseDatas()[i]}</td>
                                                <td>
                                                    <p className='bg-red-550 text-white px-4 py-1.5 cursor-pointer rounded-full' onClick={() => handleClickInProgressMarkProgress(i)}>
                                                        Mark Progress
                                                    </p>
                                                </td>
                                                <td>
                                                    {isTickMarkEnable ?
                                                        <div className='flex justify-center'>
                                                            <TiTick className='text-white text-xl w-5 h-5 rounded-full bg-green-500' />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='flex justify-end my-6'>
                            {inProgressSubmitLoader ?
                                <div className='flex items-center space-x-3 bg-red-550 text-white rounded-full px-6 py-2'>
                                    <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                    <p>Submit</p>
                                </div>
                                :
                                checkSubmitBtnEnable() ?
                                    <p className='bg-red-550 text-white rounded-full px-6 py-2 cursor-pointer' onClick={handleSubmitInProgress}>Submit</p>
                                    :
                                    <p className='bg-gray-400 text-white rounded-full px-6 py-2'>Submit</p>
                            }
                        </div>
                    </div>
                </Modal>

                {inprogressIndividualTrackerModal?.bool ?
                    <ZimmermanInProgressStepperModal
                        zimmerMan={true}
                        listblocks={listblocks}
                        listId={listId}
                        isQtyExceeded={isQtyExceeded}
                        currentTrackerType={currentTrackerType}
                        enableSaveButton={saveBtnEnable}
                        enableNextButton={btnEnable}
                        selectedActivity={selectedActivity}
                        inprogressTotalPartCount={inprogressTotalPartCount}
                        getInprogressDefaultValue={getInprogressDefaultValue}
                        getLinkedPartName={getLinkedPartName}
                        setBtnEnable={setBtnEnable}
                        inprogressCount={inprogressDeployedPartCount[currentTrackerType?.tracker_type] || 0}
                        setInprogressDeployedPartCount={setInprogressDeployedPartCount}
                        setIsQtyExceeded={setIsQtyExceeded}
                        updateInprogressDeployedCount={updateInprogressDeployedCount}
                        setUpdateQuantity={setUpdateQuantity}
                        handleCloseWithoutSaving={handleCloseWithoutSaving}
                        handleSaveInProgressSteps={handleSaveInProgressSteps}
                        piersTorqueTubesDetails={piersTorqueTubesDetails}
                        inprogressIndividualTrackerModal={inprogressIndividualTrackerModal}
                        setInprogressIndividualTrackerModal={setInprogressIndividualTrackerModal}
                        selectedPiersTorqueTubes={selectedPiersTorqueTubes}
                        setSelectedPiersTorqueTubes={setSelectedPiersTorqueTubes}
                        selectedTrackerRows={trackerRows}
                        setDashboard={setDashboard}
                        cpTrackerRowWise={listblocks?.cpTrackerRowWise}
                    />
                    :
                    null
                }
            </div>
        </Container >
    )
}

export default ZimmermanDashboard
