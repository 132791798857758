import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
import toast from "react-hot-toast";

const plantAtom = atom({
    data: [],
    orgData: []
})

export const fetchPlantAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/org_plant?only_org=False`)
        for (let i of res.data) {
            let temp = {}
            if (i?.document) {
                temp = i?.document.find(k => k?.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                try {
                    let picRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                    i["profile_url"] = picRes.status === 200 ? picRes.data : null
                } catch (error) {
                    i["profile_url"] = null
                }
            }
            else {
                i["profile_url"] = null
            }
        }
        set(plantAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const fetchorgPlants = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/org_plant?only_org=True`)
        for (let i of res.data) {
            let temp = {}
            if (i?.document) {
                temp = i?.document.find(k => k?.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                try {
                    let picRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                    i["profile_url"] = picRes.status === 200 ? picRes.data : null
                } catch (error) {
                    // toast.error("Can't load images")
                    i["profile_url"] = null
                }
            }
            else {
                i["profile_url"] = null
            }
        }
        set(plantAtom, produce((draft) => { draft.orgData = res.data }))
    }
)

export const createPlantAtom = atom(
    null,
    async (get, set, args) => {
        if (args.org) {
            await axios.post(`projects/org_plant`, args.data)
        }
        else {
            await axios.post(`projects/org_plant?is_plant_project=True`, args.data)
        }
        // let res = await axios.get(`/projects/org_plant`)
        // for (let i of res.data) {
        //     let temp = {}
        //     if (i?.document) {
        //         temp = i?.document.find(k => k?.type === 3)
        //     }
        //     else {
        //         temp = {}
        //     }
        //     if (temp?.upload_id) {
        //         let picRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
        //         i["profile_url"] = picRes.data
        //     }
        //     else {
        //         i["profile_url"] = null
        //     }
        // }
        // set(plantAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const deletePlant = atom(
    null,
    async (get, set, args) => {
        if (args.org === true) {
            await axios.delete(`/projects/plant_project/delete?plant_project_id=${args.id}`)
        }
        else {
            await axios.delete(`/projects/org_plant?org_plant_id=${args.id}`)
        }
        // let res = await axios.get(`/projects/org_plant`)
        // for (let i of res.data) {
        //     let temp = {}
        //     if (i?.document) {
        //         temp = i?.document.find(k => k?.type === 3)
        //     } /plant_project/delete?force=True&plant_project_id=${args.id}
        //       /org_plant?force=True&org_plant_id
        //     else {
        //         temp = {}
        //     }
        //     if (temp?.upload_id) {
        //         let picRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
        //         i["profile_url"] = picRes.data
        //     }
        //     else {
        //         i["profile_url"] = null
        //     }
        // }
        // set(plantAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const handleForceDeleteAtom = atom(
    null,
    async (get, set, args) => {
        if (args.org === true) {
            // for project
            await axios.delete(`/projects/plant_project/delete?force=True&plant_project_id=${args.id}`)
        }
        else {
            await axios.delete(`/projects/org_plant?force=True&org_plant_id=${args?.id}`)
        }
    }
)


export const updateImportPlantAtom = atom(
    null,
    async (get, set, args) => {
        let data = { org_plant_ids: args }
        await axios.post(`/projects/plant_project`, data)
        let res = await axios.get(`/projects/org_plant`)
        // for (let pic of res.data) {
        //     let temp = {}
        //     if (pic?.user?.profile_data?.other_documents) {
        //         temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
        //     }
        //     else {
        //         temp = {}
        //     }
        //     if (temp?.upload_id) {
        //         let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
        //         pic.user.profile_data["profile_url"] = pisRes.data
        //     }
        //     else {
        //         pic.user.profile_data["profile_url"] = null
        //     }
        // }
        set(plantAtom, produce((draft) => { draft.users = res.data }))
    }
)

export default plantAtom