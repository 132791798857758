import React, { useState } from 'react'
import Container from '../../../components/container'
import { BiArrowBack } from "react-icons/bi"
import { useNavigate, useParams } from 'react-router-dom'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { VscClose } from "react-icons/vsc"
import { useAtom } from 'jotai';
import { AiOutlineInfoCircle } from "react-icons/ai"
import projectAtom from '../../../atoms/projectAtom';
import dashboardAtom, { fetchBlocks, fetchBulkDashboardDataGet, fetchCoordinates, fetchCpTrackerRowAtom, fetchListRows, fetchListSections, fetchMapRender, fetchMapSize, fetchSelectedRow, fetchSelectedSection } from '../../../atoms/dashboardAtom';
import { useEffect } from 'react';
import logsAtom, { fetchAuditAtom, fetchChecklistBlockAtom, fetchChecklistTrackerAtom, fetchStatusCheckAtom } from '../../../atoms/logsAtom';
import toast from 'react-hot-toast';
import project_orgAtom, { fetchProjOrgAtom } from '../../../atoms/permissionAtom'
import { Qc_Enable } from '../../constants';
import produce from 'immer';
import Modal from 'react-modal';
import { BlockMap } from "@product-fusion/surya-fw";
import { FaUser, FaUsers } from 'react-icons/fa';
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import Svg from '../../dashboard/svg';

function AuditLogs() {

    function checkQcEnable() { return Qc_Enable }

    const navigate = useNavigate()

    const [projectsName] = useAtom(projectAtom)
    const [listblocks] = useAtom(dashboardAtom)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [listLogs] = useAtom(logsAtom)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setCoordinates] = useAtom(fetchCoordinates)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setFetchSelectedRow] = useAtom(fetchSelectedRow)
    const [, setFetchAuditLogs] = useAtom(fetchAuditAtom)
    const [, setFetchQcPunchlist] = useAtom(fetchChecklistTrackerAtom)
    const [, setFetchQcPunchlistBlock] = useAtom(fetchChecklistBlockAtom)
    const [, setStatusCheck] = useAtom(fetchStatusCheckAtom)
    const [, fetchProjOrgPermission] = useAtom(fetchProjOrgAtom)
    const [getPermission] = useAtom(project_orgAtom)
    const [, setSectionList] = useAtom(fetchListSections)
    const [, setRowsList] = useAtom(fetchListRows)

    const [viewType, setViewType] = useState(true)
    const [clearSt, setClearSt] = useState(false)
    const [showBlock, setShowBlock] = useState({ bool: true, block: "" })
    const [selectAll, setSelectAll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState([])
    const [sectionSelect, setSectionSelect] = useState([])
    const [prevSection, setPrevSection] = useState(null)
    const [dum, setDum] = useState({ bool: false, id: null, name: null })
    const [selectedRow, setSelectedRow] = useState(listblocks?.selectedRow?.length ? listblocks?.selectedRow : [])
    const [dumRow, setDumRow] = useState([])
    const [prevRow, setPrevRow] = useState(null)

    const [downloadModal, setModalDownload] = useState({ boolean: false, data: null });
    const [, setSelectedSection] = useAtom(fetchSelectedSection);
    const [, setTrackerProgress] = useAtom(fetchCpTrackerRowAtom);
    const params = useParams();
    const [projectsList] = useAtom(projectAtom);
    const [punchBool, setPunch] = useState(false);
    const [current, setCurrent] = useState('internal');
    const [select, setSelect] = useState('');
    const [loader, setloader] = useState(false);


    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        svgInit()
    }, [listblocks.blocks, showBlock?.bool, selectedBlock])

    useEffect(() => {
        if (project_name?.type) {
            if (listblocks.piersList) {
                rowCallFn()
            }
        }
        else {
            if (listblocks.blockTtData) {
                sectionCallFn()
            }
        }
    }, [listblocks?.blockTtData, listblocks?.piersList])

    const sectionCallFn = async () => {
        try {
            await setSectionList({ data: listblocks.blockTtData.piers })
        }
        catch (err) {
            console.log(err)
        }
    }

    const rowCallFn = async () => {
        try {
            await setRowsList({ data: listblocks.piersList })
        }
        catch (err) {
            console.log(err)
        }
    }

    const init = async () => {
        try {
            setLoading(true)
            await setFetchMap()
            await setFetchBlocks()
            await fetchProjOrgPermission()
            // await setTrackerProgress()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (dum.bool) {
            handleDblClick()
        }
    }, [dum.id])

    const handleDblClick = async () => {
        try {
            setLoading(true)
            await setMapSize({ id: dum.id })
            if (project_name?.type) {
                await setCoordinates({ id: dum.id })
            }
            else {
                await setFetchBulkGet({ id: dum.id })
            }
            setShowBlock({ bool: false, block: dum.name })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const svgInit = () => {
        if (listblocks?.blocks.length > 0) {
            let block_svg_id
            let block_progress_id
            listblocks?.blocks.map((i) => {
                block_svg_id = document.getElementById(project_name?.type ? i?.name : i?.block_svg_id)
                block_progress_id = document.getElementById(i?.progress_svg_id)
                if (block_svg_id) {
                    block_svg_id.addEventListener("click", () => {
                        let temp = []
                        if (selectedBlock.length > 0 && selectedBlock.includes(i?.id)) {
                            temp = selectedBlock.filter((item) => item !== i?.id)
                        }
                        else {
                            temp = [...selectedBlock, i?.id]
                        }
                        setSelectedBlock(temp)
                    })
                    block_svg_id.addEventListener("dblclick", () => {
                        setDum({ bool: true, id: i?.id, name: i?.name })
                    })
                    if (selectedBlock.includes(i?.id)) {
                        block_svg_id.style.fill = "#F6BE00"
                    }
                    else {
                        block_svg_id.style.fill = "white"
                    }
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = null
                }
            })
        }
    }

    const handleSelectAll = () => {
        if (showBlock.bool) {
            if (selectedBlock.length === listblocks?.blocks.length) {
                setSelectedBlock([])
            }
            else {
                let temp = []
                listblocks?.blocks.map((i) => {
                    temp.push(i?.id)
                })
                setSelectedBlock(temp)
            }
        }
        else {
            setSelectAll(!selectAll)
            setPrevRow(null)
            if (project_name?.type) {
                if (listblocks?.rowList) {
                    setPrevRow(null)
                    let temp = []
                    listblocks?.rowList.map((i) => {
                        temp.push(i?.value)
                    })
                    setSectionSelect(temp)
                    setDumRow(listblocks?.rowList)
                }
            }
            else {
                if (listblocks?.sectionList) {
                    setSectionSelect(listblocks?.sectionList)
                    setPrevSection(null)
                }
            }
        }
    }

    const handleSectionSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevSection(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevSection(null)
        }
    }

    const handleMultiSelect = (e) => {
        console.log(e)
        setDumRow(e)
        let temp = []
        e.map((i) => {
            temp.push(i?.value)
        })
        console.log("sectionSelect", sectionSelect)
        if (sectionSelect?.length > e?.length) {
            sectionSelect?.map((ij) => {
                let temp = e.filter(o => parseInt(o?.value) !== ij)
                if (temp?.length === e?.length) {
                    console.log(temp)
                    setPrevRow(ij)
                }
            })
        }

        setSectionSelect(temp)
        if (e.length === 0) {
            setPrevRow(sectionSelect[0])
            setSectionSelect([])
        }
    }

    const handleClear = () => {
        if (showBlock.bool) {
            setSelectedBlock([])
            setSelectAll(false)
        }
        else {
            setSectionSelect([])
            setPrevRow(null)
            setClearSt(!clearSt)
            setFetchSelectedRow([])
            setSelectAll(false)
            setDumRow([])
        }
    }

    const handleDownload = async (data) => {
        try {
            toast("Download queued")
            if (showBlock.bool) {
                if (selectedBlock.length > 0) {
                    await setFetchAuditLogs({ activity_type: data.type, block_ids: selectedBlock })
                }
                else {
                    await setFetchAuditLogs({ activity_type: data.type })
                }
            }
            else {
                if (listblocks?.selectedRow.length > 0) {
                    let temp = []
                    listblocks?.selectedRow.map((i) => {
                        temp.push(parseInt(i.split("-")[1]))
                    })
                    await setFetchAuditLogs({ activity_type: data.type, tracker_row_ids: temp })
                }
                else {
                    await setFetchAuditLogs({ activity_type: data.type })
                }
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    const handleQcChecklistDownload = async () => {
        try {
            if (showBlock.bool) {
                await setFetchQcPunchlistBlock({ block_id: selectedBlock[0] })
            }
            else {
                let temp = []
                listblocks?.selectedRow.map((i) => {
                    temp.push(i.split("-")[1])
                })
                await setFetchQcPunchlist({ tracker_id: temp, block_id: dum.id })
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to download")
        }
    }


    const handleDownloadDocs = async () => {
        try {
            await setStatusCheck()
        }
        catch (err) {
            console.log(err)
        }
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const handleNavigate = () => {
        if (showBlock.bool) {
            navigate("/reports")
        }
        else {
            setShowBlock({ bool: true, block: "" })
            setSelectAll(false)
            setDum({ bool: false, id: null, name: null })
            setSelectedBlock([])
        }
    }

    let subtitle;

    function afterOpenModal() {
    }

    function closeTrackerModal() {
        setModalDownload({ boolean: false, data: null })
    }

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            maxHeight: "650px",
            padding: "0px 20px",
            borderRadius: '10px'
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleChange = (e) => {
        setSelect(e?.target?.value)
    }

    const handleDownloadITC = async () => {
        setloader(true)
        try {
            for (let rowId of selectedRow) {
                console.log(rowId?.split('-')?.[1])
                let res = await axios.get(`/projects/get_qc_report_upload_id?visibility=${current === 'internal' ? 0 : 1}&tracker_row_id=${rowId?.split('-')?.[1]}&type=${select}`)
                if (res.data) {
                    let imageRes = await axios.get(`/file_upload/download?id=${res?.data?.upload_id}`)
                    if (imageRes?.data) {
                        const link = document.createElement('a');
                        link.href = imageRes.data;
                        link.setAttribute('download', 'ITC Report');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    closeTrackerModal()
                }
                // else {
                //     toast.error("No ITC Record found for this tracker row id");
                //     setloader(false)
                // }
            }
            setloader(false)
        }
        catch (err) {
            console.log(err)
            setloader(false)
        }
    }

    const handleBack = () => {
        if (showBlock.bool) {
            navigate("/quality")
        }
        else {
            setShowBlock({ bool: true, block: "" })
        }
    }

    function qcCheck() {
        return project_name?.project_settings?.qc
    }

    return (
        <Container>
            <div className='overflow-auto' style={{ width: "calc(100vw-250px)", height: "calc(100vh - 80px)" }}>
                <div className='flex justify-between mb-6 w-full items-center'>
                    <div className="px-4">
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-3 ml-5 hover:bg-gray-100 rounded-xl active:bg-gray-200" onClick={handleBack} />
                        <div className='ml-6 mt-5 text-xl font-bold'>
                            Logs and Checklists
                        </div>
                    </div>
                    {qcCheck() ?
                        <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 mt-5 bg-red-50 mr-5 border-red-100 h-[50px]'>
                            <div className={`flex items-center ${viewType ? "" : "bg-red-550 border-none rounded-full"}`}>
                                <FaUsers className={`ml-3 ${viewType ? "text-gray-400" : "text-white"}`} />
                                <p className={`${viewType ? "text-gray-400 pr-3 pl-2" : "text-white pr-3 pl-2"} mr-1`} onClick={() => { setViewType(false); navigate("/quality/qc_lockandunlock") }}>Lock / Unlock</p>
                            </div>
                            <div className={`flex items-center ${viewType ? "bg-red-550 border-none rounded-full" : ""}`}>
                                <FaUser className={`ml-3 ${viewType ? "text-white" : "text-gray-400"}`} />
                                <p className={`${viewType ? "text-white pr-3 pl-2" : "text-gray-400 pr-3 pl-2"} ml-1`} onClick={() => { setViewType(true) }}>Logs</p>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <hr />
                <div className='flex'>
                    {loading ?
                        <div className='w-[75%] flex flex-col justify-center items-center'>
                            <p className='loader mb-1'></p>
                            <p className='font-bold ml-4 text-xl'>Fetching...</p>
                        </div>
                        :
                        showBlock.bool ?
                            <div className='w-[75%]' style={{ height: "calc(100vh - 390px)" }}>
                                <div className='cursor-grab' style={{ height: "calc(100vh - 250px)" }}>
                                    <Svg project_name={project_name} svgMap={listblocks?.map} className={""} />
                                </div>
                                <div className='flex justify-center mt-3'>
                                    <span data-tip data-for="svg" className='mt-1 ml-4'>
                                        <AiOutlineInfoCircle />
                                    </span>
                                    <p className='text-sm text-gray-400 mt-0.5 ml-2'>Double click on any block to dive in</p>
                                </div>
                            </div>
                            :
                            <div className='w-[75%]' style={{ height: "calc(100vh - 300px)" }}>
                                <div>
                                    <div className='flex justify-between px-5'>
                                        <p className='ml-1 font-semibold my-3'>{showBlock?.block}</p>
                                        <div className='flex items-center'>
                                            <p className='text-gray-400 text-sm font-semibold mr-2'>{project_name?.type ? "Row" : "Section"}</p>
                                            {project_name?.type ?
                                                <MultiSelect
                                                    className='w-[150px] rounded-2xl cursor-pointer'
                                                    options={listblocks?.rowList}
                                                    value={dumRow}
                                                    onChange={e => handleMultiSelect(e)}
                                                    labelledBy={`Select Row(s)`}
                                                    hasSelectAll={false}
                                                    disableSearch={true}
                                                    ClearSelectedIcon={null}
                                                />
                                                :
                                                listblocks?.sectionList.map((i) => {
                                                    return (
                                                        <p className={`cursor-pointer text-center m-1 w-10 py-1 text-xs ${sectionSelect?.includes(i) ? "bg-red-550 text-white border-red-550 border" : "bg-white text-red-550 border"}`} onClick={() => handleSectionSelect(i)}>{i}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <BlockMap
                                        deviceType="web"
                                        zimmerMan={project_name?.type}
                                        bulkAllData={listblocks?.blockTtData}
                                        bulkPierData={listblocks?.piersList}
                                        bulkAllTTData={listblocks?.tableCoordinates}
                                        pierLegends={listblocks?.pierLegends}
                                        type="CP"
                                        typeData={listblocks.cpTrackerRowWise}
                                        userSelection={{ showType: 'pier', selection: 'All' }}
                                        currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(params.id))}
                                        currentProjectData={projectsList.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        showLegend={true}
                                        mapSize={listblocks.mapSize}
                                        selectAll={selectAll}
                                        section={sectionSelect}
                                        prevSection={prevSection}
                                        prevRow={prevRow}
                                        clearSelection={clearSt}
                                    />
                                </div>
                            </div>
                    }
                    <div className='pl-5 flex flex-col pt-5 space-y-5 border-l-2 w-[25%]' style={{ height: "calc(100vh - 200px)" }}>
                        {showBlock.bool ?
                            <div className='flex items-center justify-center mb-5'>
                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm mr-5' onClick={handleSelectAll}>Select All</button>
                                <div className='flex items-center'>
                                    <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedBlock.length > 0} onClick={handleClear}>Clear Selection</p>
                                </div>
                            </div>
                            :
                            <div className='flex items-center justify-center mb-5'>
                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm mr-5' onClick={handleSelectAll}>Select All</button>
                                <div className='flex items-center'>
                                    <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</p>
                                </div>
                            </div>
                        }
                        {getPermission["mech-Construction Progress"] ? <p className='text-blue-500 cursor-pointer font-medium' onClick={() => handleDownload({ type: "cp" })}>
                            Download CP log
                        </p> : null}
                        {qcCheck() && getPermission["mech-Quality Check"] ? <p className={`font-medium ${checkQcEnable() ? "text-blue-500 cursor-pointer" : "cursor-not-allowed text-gray-400"}`} onClick={() => checkQcEnable() ? handleDownload({ type: "qc" }) : null}>
                            Download QC log
                        </p> : null}
                        {qcCheck() &&
                            <button disabled={selectedRow.length === 0} className={`${selectedRow.length !== 0 ? "text-blue-500 cursor-pointer" : "text-gray-400 cursor-auto"} text-left font-medium`} onClick={() => setModalDownload(produce((draft) => { draft.boolean = true; draft.data = "ITC" }))}>Download ITC</button>}
                        {qcCheck() && <p className='text-blue-500 cursor-pointer font-medium' onClick={() => setModalDownload(produce((draft) => { draft.boolean = true; draft.data = "ITR" }))}>Generate and Download ITR</p>}
                    </div>
                    <Modal
                        isOpen={downloadModal.boolean}
                        onRequestClose={closeTrackerModal}
                        style={customStyle}
                        contentLabel='Download Model'
                    >
                        <div className='w-full'>
                            <div className='flex justify-between items-center my-5'>
                                <p className='text-xl font-medium'>Download {downloadModal.data}</p>
                                <VscClose className='cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full w-[25px] h-[25px]' onClick={closeTrackerModal} />
                            </div>
                            <hr />
                            {downloadModal.data === "ITC" ?
                                <div className='flex flex-col space-y-5 w-full my-5'>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>Logs Generation</p>
                                        <div className='flex w-[215px] mr-4 text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100'>
                                            <div className={`flex ${punchBool ? "" : "bg-red-550 border-none rounded-full"}`}>
                                                <FaUsers className={`mt-3 ml-3 ${punchBool ? "text-gray-400" : "text-white"}`} />
                                                <p className={`${punchBool ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => { setPunch(false); setCurrent('internal') }}>Internal</p>
                                            </div>
                                            <div className={`flex ${punchBool ? "bg-red-550 border-none rounded-full" : ""}`}>
                                                <FaUser className={`mt-3 ml-3 ${punchBool ? "text-white" : "text-gray-400"}`} />
                                                <p className={`${punchBool ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => { setPunch(true); setCurrent('external') }}>External</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITC module</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]' onChange={(e) => handleChange(e)}>
                                            <option disabled selected hidden>Select an option</option>
                                            <option value={'piers'} >Pier ITC</option>
                                            <option value={'structure'}>Structure ITC</option>
                                            <option value={'module'}>Modules ITC</option>
                                        </select>
                                    </div>
                                    <hr />
                                    <div className='flex justify-end space-x-5'>
                                        <p className='btn border text-gray-500 rounded-full bg-white hover:text-white' onClick={closeTrackerModal}>Cancel</p>
                                        <button className='btn border-none rounded-full px-4 py-2 cursor-pointer text-white bg-red-550' onClick={handleDownloadITC}>
                                            {loader ? <div className='loaders text-sm mr-2'></div> : <div></div>}
                                            Download
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className='flex flex-col space-y-5 w-full my-5'>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>Punchlist Generation</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Internal</option>
                                            <option>External</option>
                                        </select>
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITR reference number</p>
                                        <input className='border px-2 py-1 rounded-xl w-[50%]' placeholder='Enter ITR reference number' />
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITC module</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Piling ITR</option>
                                            <option>Structure ITR</option>
                                            <option>Modules ITR</option>
                                        </select>
                                    </div>
                                    <hr />
                                    <div className='flex justify-end space-x-5'>
                                        <p className='btn border text-gray-500 rounded-full bg-white hover:text-white' onClick={closeTrackerModal}>Cancel</p>
                                        <button className='btn border-none rounded-full px-4 py-2 cursor-pointer text-white bg-red-550'>Proceed</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                </div>
            </div>
        </Container>
    )
}

export default AuditLogs