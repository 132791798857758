import React from 'react'
import { useLocation } from 'react-router-dom'

function PdfViewerComp() {
    const location = useLocation()
    const [isLoading, setIsLoading] = React.useState(true)

    return (
        <div>
            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 1000
                }}>
                    Loading PDF...
                </div>
            )}
            <iframe
                title="PDF Viewer"
                target="_blank"
                src={`https://docs.google.com/gview?url=${encodeURIComponent(location.search.split("?url=")[1])}&embedded=true`}
                style={{ width: '100%', height: '100vh', border: 'none' }}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
            />
        </div>
    )
}

export default PdfViewerComp