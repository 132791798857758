import React, { useState, useRef, useEffect } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { TiTick } from "react-icons/ti"
import { RxCross2 } from "react-icons/rx"
import { HiFilter } from "react-icons/hi";

const MultiSelectDropdown = ({
    options,
    placeholder,
    onChange,
    selectedValues = [],
    className = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(selectedValues);
    const [tempSelected, setTempSelected] = useState(selectedValues);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                // Reset temp selections to actual selections when closing without applying
                setTempSelected(selected);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selected]);

    useEffect(() => {
        setSelected(selectedValues);
        setTempSelected(selectedValues);
    }, [selectedValues]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            // When opening, initialize temp selections with current selections
            setTempSelected([...selected]);
        }
    };

    const handleSelect = (value) => {
        console.log(value, tempSelected)
        let newSelected;

        if (value === 'all') {
            if (tempSelected.includes('all')) {
                newSelected = [];
            } else {
                console.log("object")
                newSelected = ['all', ...options.map(opt => opt.value)];
            }
        } else {
            if (tempSelected.includes(value)) {
                newSelected = tempSelected.filter(item => item !== value);
                if (tempSelected.includes('all')) {
                    newSelected = newSelected.filter(item => item !== 'all');
                }
            } else {
                newSelected = [...tempSelected, value];
                if (newSelected?.length === options?.length) {
                    newSelected.push('all');
                }
            }
        }

        setTempSelected(newSelected);
    };

    const handleApply = () => {
        setSelected(tempSelected);
        onChange(tempSelected);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setTempSelected(selected);
        setIsOpen(false);
    };

    const isOptionSelected = (value) => {
        return tempSelected.includes(value);
    };


    return (
        <div className={`relative ${className}`} ref={dropdownRef}>
            <div
                className={`flex items-center justify-between px-2 py-1 ${selected?.includes('all') ? "bg-red-550" : "bg-red-200"} text-white cursor-pointer rounded-md`}
                onClick={handleToggle}
            >
                <div className="text-sm">
                    {placeholder}
                </div>
                <div className="ml-2">
                    <HiFilter />
                </div>
            </div>

            {isOpen && (
                <div className={`absolute z-30 ${placeholder === "Status" ? "w-[130px]" : "w-full min-w-[140px]"} mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-96 text-black overflow-y-auto`}>
                    <div
                        className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${isOptionSelected('all') ? 'bg-red-50 text-red-600 font-medium' : ''}`}
                        onClick={() => handleSelect('all')}
                    >
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                className="checkbox checkbox-xs checkbox-primary mr-2 square"
                                checked={isOptionSelected('all')}
                                onChange={() => { }}
                            />
                            <span className="text-sm font-normal">
                                {isOptionSelected('all') ? "Unselect All" : "Select All"}
                            </span>
                        </div>
                    </div>

                    <div className="border-t border-gray-200"></div>

                    <div className="max-h-60 overflow-y-auto">
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${isOptionSelected(option.value) ? 'bg-red-50 text-red-600 font-normal' : ''}`}
                                onClick={() => handleSelect(option.value)}
                            >
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        className="checkbox checkbox-xs checkbox-primary mr-2 square"
                                        checked={isOptionSelected(option.value)}
                                        onChange={() => { }}
                                    />
                                    <span className="text-sm truncate font-normal">{option.label}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="border-t border-gray-200 sticky bottom-0 bg-white">
                        <div className="flex justify-between p-2">
                            <button
                                className="px-3 py-1 bg-gray-200 text-gray-700 rounded-md text-xs hover:bg-gray-300"
                                onClick={handleCancel}
                            >
                                <RxCross2 />
                            </button>
                            <button
                                className="px-3 py-1 bg-red-550 text-white rounded-md text-xs hover:bg-red-600"
                                onClick={handleApply}
                            >
                                <TiTick />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;