import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
import toast from "react-hot-toast";

const punchListAtom = atom({
    data: {
        data: [],
        internalCount: 0,
        externalCount: 0
    },
    statusCounts: {},
    details: [],
    filterData: {},
    blockName: [],
    photos: []
})

const handleImage = async (data) => {
    try {
        let res = await axios.get(`/file_upload/download?image_id=${data}`)
        return res?.data
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export const punchlistFilterAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/get_filter_values`)
        set(punchListAtom, produce((draft) => { draft.filterData = res.data }))
    }
)

export const fetchBlocks = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/blocks?is_svg=True`, {
            "is_svg": true
        })
        set(punchListAtom, produce((draft) => { draft.blocks = res.data }))
    }
)

export const fetchStatusCountsAtom = atom(
    null,
    async (get, set, args) => {
        let res
        if (args?.block_id) {
            res = await axios.get(`/projects/get_qc_punchlist_status_counts?block_id=${args?.block_id}&scope=${args?.type}&visibility=${args?.visibility}`)
        }
        else {
            if (args?.type === "All") {
                res = await axios.get(`/projects/get_qc_punchlist_status_counts?visibility=${args?.visibility}`)
            }
            else {
                res = await axios.get(`/projects/get_qc_punchlist_status_counts?scope=${args?.type}&visibility=${args?.visibility}`)
            }
        }
        set(punchListAtom, produce((draft) => { draft.statusCounts = res?.data }))
    }
)

export const fetchPunchListAtom = atom(
    null,
    async (get, set, args) => {
        let res
        if (args?.page && args?.per_page) {
            let payload = {
                "page": args.page,
                "per_page": args.per_page,
            }

            if (args?.filters?.block && !args.filters.block.includes('all')) {
                payload["block"] = args.filters.block.map(block => parseInt(block))
            }
            if (args?.filters?.section && !args.filters.section.includes('all')) {
                payload["section"] = args.filters.section.map(section => parseInt(section))
            }
            if (args?.filters?.row && !args.filters.row.includes('all')) {
                payload["row"] = args.filters.row.map(row => parseInt(row))
            }
            if (args?.filters?.scope && !args.filters.scope.includes('all')) {
                payload["scope"] = args.filters.scope
            }
            if (args?.filters?.checkpoint && !args.filters.checkpoint.includes('all')) {
                payload["checkpoint_name"] = args.filters.checkpoint
            }
            if (args?.filters?.status && !args.filters.status.includes('all')) {
                payload["status"] = args.filters.status
            }

            res = await axios.post(`/projects/get_qc_punchlist_internal_and_external`, payload)
        }
        else {
            console.log("Unable to fetch Punchlist")
            // toast.error("Unable to fetch punchlist")
        }
        let tempObj = []
        let resIndex = 0
        // for (let blkId in res.data) {
        for (let type in res.data) {
            for (let ids in res.data[type]) {
                resIndex = resIndex + 1
                if (ids.split("_").length === 3) {
                    res.data[type][ids]["checklistType"] = 1
                }
                else if (ids.split("_").length === 2) {
                    res.data[type][ids]["checklistType"] = 0
                }
                let pics = []
                for (let key of res.data[type][ids]?.document) {
                    let tempRes
                    tempRes = await handleImage(key)
                    pics.push(tempRes)
                }
                res.data[type][ids]["photos"] = pics
                tempObj.push(res.data[type][ids])
            }
        }
        let punchListData = {
            data: tempObj,
            internalCount: res.data?.qcpunchlist_internal_total,
            externalCount: res.data?.qcpunchlist_external_total
        }
        set(punchListAtom, produce((draft) => { draft.data = punchListData }))
    }
)

export const fetchQcDataAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/get_qc_punchlist_kv`)
        ///progress/v2/get_qc_punchlist_kv?visibility=
        set(punchListAtom, produce((draft) => { draft.details = res.data }))
    }
)

export const movePunchListAtom = atom(
    null,
    async (get, set, args) => {
        for (let i of args.data.data) {
            await axios.patch(`/progress/update_qc_progress?block_id=${i.block_id}`, { data: [i] })
        }
        console.log("object")
        let res = await axios.get(`/progress/v2/get_qc_punchlist_kv?visibility=${args.visibility}`)
        let temp = []

        for (let data in res?.data) {
            let pics = []
            for (let key of res?.data[data]?.QCProgress?.photos) {
                let tempRes = await handleImage(key?.upload_id)
                pics.push(tempRes)
            }
            res.data[data]["photos"] = pics
            temp.push(res?.data[data])
        }
        let finalTemp = []
        temp.map((i) => {
            if (i?.QCProgress?.visibility === args?.visibility) {
                finalTemp.push(i)
            }
        })
        set(punchListAtom, produce((draft) => { draft.data = finalTemp }))
    }
)

export const updatePunchlistAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/update_punchlist_status`, args?.data)
        let res
        if (args?.page && args?.per_page) {
            let payload = {
                "page": args.page,
                "per_page": args.per_page,
            }

            if (args?.filters?.block && !args.filters.block.includes('all')) {
                payload["block"] = args.filters.block.map(block => parseInt(block))
            }
            if (args?.filters?.section && !args.filters.section.includes('all')) {
                payload["section"] = args.filters.section.map(section => parseInt(section))
            }
            if (args?.filters?.row && !args.filters.row.includes('all')) {
                payload["row"] = args.filters.row.map(row => parseInt(row))
            }
            if (args?.filters?.scope && !args.filters.scope.includes('all')) {
                payload["scope"] = args.filters.scope
            }
            if (args?.filters?.checkpoint && !args.filters.checkpoint.includes('all')) {
                payload["checkpoint_name"] = args.filters.checkpoint
            }
            if (args?.filters?.status && !args.filters.status.includes('all')) {
                payload["status"] = args.filters.status
            }

            res = await axios.post(`/projects/get_qc_punchlist_internal_and_external`, payload)
        }
        else {
            console.log("Unable to fetch Punchlist")
            // toast.error("Unable to fetch punchlist")
        }
        let tempObj = []
        let resIndex = 0
        // for (let blkId in res.data) {
        for (let type in res.data) {
            for (let ids in res.data[type]) {
                resIndex = resIndex + 1
                if (ids.split("_").length === 3) {
                    res.data[type][ids]["checklistType"] = 1
                }
                else if (ids.split("_").length === 2) {
                    res.data[type][ids]["checklistType"] = 0
                }
                let pics = []
                for (let key of res.data[type][ids]?.document) {
                    let tempRes
                    tempRes = await handleImage(key)
                    pics.push(tempRes)
                }
                res.data[type][ids]["photos"] = pics
                tempObj.push(res.data[type][ids])
            }
        }
        let punchListData = {
            data: tempObj,
            internalCount: res.data?.qcpunchlist_internal_total,
            externalCount: res.data?.qcpunchlist_external_total
        }
        set(punchListAtom, produce((draft) => { draft.data = punchListData }))
    }
)

export const deletePunchlistAtom = atom(
    null,
    async (get, set, args) => {
        console.log("object")
        await axios.post(`/progress/v2/delete_qc_punchlist?visibility=${args.visibility}`, args.id)
        let res = await axios.get(`/progress/v2/get_qc_punchlist_kv?visibility=${args.visibility}`)
        let temp = []
        for (let data in res?.data) {
            let pics = []
            for (let key of res?.data[data]?.QCProgress?.photos) {
                let tempRes = await handleImage(key?.upload_id)
                pics.push(tempRes)
            }
            res.data[data]["photos"] = pics
            temp.push(res?.data[data])
        }
        let finalTemp = []
        temp.map((i) => {
            if (i?.QCProgress?.visibility === args?.visibility) {
                finalTemp.push(i)
            }
        })
        set(punchListAtom, produce((draft) => { draft.data = finalTemp }))
    }
)

export const fetchQcPhotos = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/public/images/${args?.project_id}/${args?.id}`)
        // let pics = []
        // for(let data of res.data){
        //     let temp = await handleImage(data)
        //     pics.push(temp)
        // }
        set(punchListAtom, produce((draft) => { draft.photos = res.data }))
    }
)

export default punchListAtom
