import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useAtom } from 'jotai'
import layoutAtom, { fetchGetLayout, fetchUpdateLayout } from '../../../atoms/layoutAtom'
import toast from 'react-hot-toast'
import Modal from 'react-modal';
import Container from '../../../components/container'
import LaydownList from './laydownList'
import ManageDelivery from './manageDelivery'
import ViewByDropDown from '../components/viewByDropDown'
import projectAtom, { fetchProjectAtom } from '../../../atoms/projectAtom'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import InventoryLog from './inventoryLog'
import dashboardAtom, { fetchBlocks, fetchMapRender } from '../../../atoms/dashboardAtom'
import produce from 'immer'
import { useForm } from 'react-hook-form'
import ScopeLaydown from './scopeLaydown'

const Index = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()

    const [loading, setLoading] = useState(false)
    const [addlayoutLoader, setAddLayoutLoader] = useState(false)
    const [viewMode, setViewMode] = useState("Logistics Management")
    const [showRightPanel, setShowRightPanel] = useState(true)
    const [currentLaydown, setCurrentLaydown] = useState(null)
    const [scopeEditMode, setScopeEditMode] = useState(false)
    const [viewToggle, setViewToggle] = useState("Inventory")
    const [svgMap, setSvgMap] = useState()
    const [laydown, setLaydown] = useState({ boolean: false, enableAdd: false })
    const [showManageDelivery, setShowManageDelivery] = useState(false)
    const [svgScale, setSvgScale] = useState(0.8)
    const [currentBlockId, setCurrentBlockId] = useState(null)
    const [addLayoutmodal, setAddLayoutModal] = useState({ boolean: false, data: { name: "", x: null, y: null } })

    const [listLaydown] = useAtom(layoutAtom)
    const [, setFetchLaydown] = useAtom(fetchGetLayout)
    const [, setUpdateLayout] = useAtom(fetchUpdateLayout)
    const [projectsName] = useAtom(projectAtom)
    const [, setFetchProject] = useAtom(fetchProjectAtom)
    const [listblocks] = useAtom(dashboardAtom)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchMapRender] = useAtom(fetchMapRender)

    let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        handleFindRouter()
    }, [location.pathname])

    useEffect(() => {
        if (viewMode === "Logistics Management") {
            if (listLaydown?.data?.length) {
                listLaydown.data.map((laydownData) => {
                    handleCreateBeacon(laydownData.latitude, laydownData.longitude, `${laydownData.id}__${laydownData.name}`)
                })
            }
        }
    }, [listLaydown?.data, currentLaydown])

    useEffect(() => {
        svgBlocksSelect();
    }, [listblocks, currentLaydown, loading])

    useEffect(() => {
        if (listblocks?.map) {
            const svgElement = document.createElement('div');
            svgElement.innerHTML = listblocks.map;
            const svgWidth = svgElement.querySelector('svg')?.getAttribute('width');
            const svgHeight = svgElement.querySelector('svg')?.getAttribute('height');
            if (svgWidth && svgHeight) {
                setSvgMap(listblocks?.map)
            } else {
                if (typeof listblocks.map === 'string') {
                    let temp = listblocks.map.replace(/<svg/, `<svg width="${currentProject?.svg_dimension?.web?.web_svg_width / 2}" height="${currentProject?.svg_dimension?.web?.web_svg_height / 2}"`);
                    setSvgMap(temp);
                }
            }
        }
    }, [listblocks?.map])

    const svgBlocksSelect = useCallback(() => {
        if (listblocks.blocks.length > 0) {
            let block_progress_id;
            listblocks.blocks.forEach((block) => {
                block_progress_id = document.getElementById(block?.progress_svg_id);
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = ``;
                }
            })
        }
    }, [listblocks, currentLaydown])

    useEffect(() => {
        if (listblocks?.map) {
            const svgElement = document.createElement('div');
            svgElement.innerHTML = listblocks.map;
            const svgWidth = svgElement.querySelector('svg')?.getAttribute('width');
            const svgHeight = svgElement.querySelector('svg')?.getAttribute('height');
            if (svgWidth && svgHeight) {
                setSvgMap(listblocks?.map)
            } else {
                if (typeof listblocks.map === 'string') {
                    let temp = listblocks.map.replace(/<svg/, `<svg width="${currentProject?.svg_dimension?.web?.web_svg_width / 2}" height="${currentProject?.svg_dimension?.web?.web_svg_height / 2}"`);
                    setSvgMap(temp);
                }
            }
        }
    }, [listblocks?.map])

    const init = async () => {
        try {
            setLoading(true)
            await setFetchBlocks()
            await setFetchMapRender()
            await setFetchProject()
            await setFetchLaydown()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    //onchange function
    const handleChangeView = (value) => {
        if (value === "Mechanical Progress") {
            navigate("/dashboard")
        } else {
            navigate("/dashboard/logistics-management")
        }
    }

    const handleCreateBeacon = (x, y, classId) => {
        let data = document.getElementsByClassName("svgParentDiv")[0]
        if (data) {
            var z = document.createElement('div')
            z.setAttribute("style", `position: absolute; top: ${y}%; left: ${x}%; color: #F16253; border: 1px solid #F16253; padding: 5px; border-radius: 100%; background-color: #F16253;`)
            z.setAttribute("xml:space", "preserve")
            z.setAttribute("font-family", "Tenor Sans")
            z.setAttribute("font-size", "28")
            if (classId) {
                z.setAttribute("class", classId)
            }
            z.setAttribute("letter-spacing", "0em")
            var layName = document.createElement('div')
            layName.setAttribute("style", `position: absolute; top: ${y - 5}%; left: ${x}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
            if (classId) {
                layName.setAttribute("class", `${classId}__name`)
                layName.innerHTML = classId?.split("__")[1]
            }
            data.appendChild(layName)
            data.appendChild(z)
        }
    }

    //find function
    const handleFindRouter = () => {
        let router = location.pathname.split('/')
        if (router?.length === 3) {
            setCurrentLaydown(null)
            setViewMode("Logistics Management")
            console.log("You are in laydown Page")
        }
        if (router?.length === 5) {
            setCurrentLaydown(parseInt(router[3]))
            if (router[4] === 'inventory') {
                setViewMode("Inventory")
                console.log("You are in inventory Page")
            }
            if (router[4] === 'scope') {
                setViewMode("Scope")
                setViewToggle("Scope")
                console.log("You are in scope Page")
            }
        }
        if (router?.length === 6) {
            setCurrentLaydown(parseInt(router[3]))
            setViewMode("Block Scope")
            setCurrentBlockId(parseInt(router[5]))
            console.log(parseInt(router[5]))
            console.log("You are in Block scope Page")
        }
    }

    function handleCheckZimmerman() {
        let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    const handleCoordinates = (e) => {
        let rect = e.currentTarget.getBoundingClientRect();
        let svgParentDiv = document.getElementsByClassName("svgParentDiv")[0]
        console.log(svgParentDiv.offsetHeight)
        let x = (e.clientX - rect.left) / (svgScale);
        let y = (e.clientY - rect.top) / (svgScale);
        if (laydown.enableAdd) {
            setLaydown(produce((draft) => { draft.enableAdd = false }))
            setAddLayoutModal(produce((draft) => { draft.boolean = true; draft.data = { name: "", x: x, y: y } }))
        }
    }

    //onclick function 
    const handleClickLaydown = (laydown) => {
        if (scopeEditMode) {
            toast.error("Please Save your changes before switching Laydown")
        }
        else {
            setCurrentLaydown(laydown)
            if (["Logistics Management", "Inventory"].includes(viewMode)) {
                navigate(`/dashboard/logistics-management/${laydown}/inventory`)
            }
            if (viewMode === "Scope") {
                navigate(`/dashboard/logistics-management/${laydown}/scope`)
            }
            if (viewMode === "Block Scope" && currentBlockId) {
                navigate(`/dashboard/logistics-management/${laydown}/scope/${currentBlockId}`)
            }
        }
    }

    const handleOpenLaydown = (e) => {
        if (laydown.enableAdd) {
            handleCoordinates(e)
        }
        else {
            if (e.target.className && !["svg", "tspan", "path"].includes(e.target.nodeName)) {
                let temp = listLaydown.data.filter(o => o.id === parseInt(e.target?.className?.split("__")[0]))
                if (temp.length > 0) {
                    handleClickLaydown(parseInt(e.target.className?.split("__")[0]))
                }
            }
        }
    }

    const createLayoutSubmit = async (data) => {
        try {
            let prtSvg = document.getElementsByClassName("svgParentDiv")[0]
            setAddLayoutLoader(true)
            await setUpdateLayout({
                data: {
                    name: data.name,
                    latitude: ((parseInt(addLayoutmodal.data.x)) / prtSvg.offsetWidth) * 100,
                    longitude: ((parseInt(addLayoutmodal.data.y)) / prtSvg.offsetHeight) * 100
                }
            })
            toast.success("Created Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to create layout")
        }
        finally {
            setAddLayoutLoader(false)
            setAddLayoutModal(produce((draft) => { draft.boolean = false }))
            reset()
        }
    }

    const closeAddLayoutModal = async () => {
        setAddLayoutModal(produce((draft) => { draft.boolean = false }))
        setLaydown(produce((draft) => { draft.enableAdd = false }))
        for (let key of listLaydown.data) {
            let data = document.getElementsByClassName("svgParentDiv")[0]
            var layName = document.createElement('div')
            layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
            layName.setAttribute("class", `${key.id}__${key.name}__name`)
            layName.innerHTML = key.name
            data.appendChild(layName)
        }
    }

    const handleAddLaydown = () => {
        if (!laydown.enableAdd) {
            for (let key of listLaydown.data) {
                let data = document.getElementsByClassName(`${key.id}__${key.name}__name`)[0]
                data.remove()
            }
        }
        else {
            for (let key of listLaydown.data) {
                let data = document.getElementsByClassName("svgParentDiv")[0]
                var layName = document.createElement('div')
                layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
                layName.setAttribute("class", `${key.id}__${key.name}__name`)
                layName.innerHTML = key.name
                data.appendChild(layName)
            }
        }
        setLaydown(produce((draft) => { draft.enableAdd = !laydown.enableAdd }))
    }

    const customStylesAddLayout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    return (
        <Container>
            <div className='flex justify-between'>
                <div className='h-full' style={{ width: showRightPanel ? "calc(100vw - 450px)" : "100%" }}>
                    {viewMode === "Logistics Management" && (
                        <div>
                            <div className='flex justify-between'>
                                <div className='flex space-x-4 items-center pl-5'>
                                    <p className='my-2 py-2 font-bold text-xl'>Logistics Management</p>
                                </div>
                                <div className='flex items-center space-x-4'>
                                    {laydown.enableAdd ?
                                        <button className='rounded-xl border-2 px-3 py-2 border-gray-500 bg-white text-gray-500 hover:bg-gray-500 hover:text-white text-xs font-semibold cursor-pointer' onClick={handleAddLaydown}>
                                            Cancel
                                        </button>
                                        :
                                        <button className='border-none rounded-xl px-3 py-2 text-xs font-semibold focus:outline-none bg-red-550 text-white hover:bg-gray-700' onClick={handleAddLaydown}>
                                            + Add new laydown
                                        </button>
                                    }
                                    <div>
                                        <button className='cursor-pointer border-none px-3 py-2 hover:bg-gray-700 text-xs font-semibold bg-red-550 text-white rounded-xl' onClick={() => setShowManageDelivery(true)}>Manage Delivery</button>
                                    </div>
                                    {showManageDelivery && <ManageDelivery deliveryModal={showManageDelivery} formattedDate={formattedDate} setDeliveryModal={setShowManageDelivery} project_name={currentProject} />}
                                    <div className='flex space-x-4 items-center mr-4 w-36'>
                                        <ViewByDropDown onSelect={handleChangeView} defaultValue="Logistics Management" />
                                    </div>
                                    {showRightPanel ?
                                        <div className='text-xl text-black border bg-gray-200 mx-2'>
                                            <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowRightPanel(false)} />
                                        </div>
                                        :
                                        <div className='text-xl text-black border bg-gray-200 mx-2'>
                                            <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowRightPanel(true)} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr />
                            {loading ?
                                <div className='absolute opacity-100 left-[35%] top-[50%] z-50'>
                                    <p className='loader ml-12 mb-1'></p>
                                    <p className='font-bold text-xl'>Fetching Laydown...</p>
                                </div>
                                :
                                <div className='cursor-grab w-full flex items-center justify-center' style={{ height: "calc(100vh - 170px)" }}>
                                    <TransformWrapper
                                        centerZoomedOut
                                        minScale={parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale)}
                                        initialScale={svgScale}
                                        initialPositionX={50}  // Center X position (percentage)
                                        initialPositionY={50}  // Center Y position (percentage)
                                        centerOnInit={true}    // Enable centering on initialization
                                        onZoom={e => setSvgScale(e?.state?.scale)}
                                    >
                                        <TransformComponent
                                            contentStyle={{ width: `${currentProject?.svg_dimension?.web?.web_svg_width}px` }}
                                            wrapperStyle={{ height: "100%", width: "100%" }}
                                        >
                                            <div className='m-auto inline-block align-middle flex items-center justify-center'>
                                                <div className="svgmap svgParentDiv flex items-center justify-center cursor-pointer"
                                                    onClick={e => handleOpenLaydown(e)}
                                                    style={{ position: "relative", padding: "50px" }}
                                                    dangerouslySetInnerHTML={{ __html: svgMap }}></div>
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>
                                </div>}
                        </div>
                    )}
                    {viewMode === "Inventory" && (
                        <InventoryLog
                            listLaydown={listLaydown}
                            currentLaydown={currentLaydown}
                            formattedDate={formattedDate}
                            projectsName={projectsName}
                            showRightPanel={showRightPanel}
                            setShowRightPanel={setShowRightPanel}
                            handleChangeView={handleChangeView}
                        />
                    )
                    }
                    {["Block Scope", "Scope"].includes(viewMode) && (
                        <ScopeLaydown
                            listLaydown={listLaydown}
                            currentLaydown={currentLaydown}
                            formattedDate={formattedDate}
                            projectsName={projectsName}
                            showRightPanel={showRightPanel}
                            viewMode={viewMode}
                            viewToggle={viewToggle}
                            currentProject={currentProject}
                            setScopeEditModeBool={setScopeEditMode}
                            setShowRightPanel={setShowRightPanel}
                            handleChangeView={handleChangeView}
                        />
                    )}
                    {/* {viewMode === "Block Scope" && (
                        <ScopeD3
                            listLaydown={listLaydown}
                            currentLaydown={currentLaydown}
                            formattedDate={formattedDate}
                            projectsName={projectsName}
                            showRightPanel={showRightPanel}
                            viewToggle={viewToggle}
                        />
                    )} */}
                </div>
                {showRightPanel &&
                    <LaydownList
                        scopeEditMode={scopeEditMode}
                        currentLaydown={currentLaydown}
                        listLaydown={listLaydown}
                        viewToggle={viewToggle}
                        loading={loading}
                        setViewToggle={setViewToggle}
                        inventoryLoader={{ bool: loading }}
                        handleClickLaydown={handleClickLaydown}
                    />
                }
            </div>
            <Modal
                isOpen={addLayoutmodal.boolean}
                style={customStylesAddLayout}
                contentLabel="Add Layout table">
                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                    <p className='mt-2'>Add Laydown</p>
                </div>
                <hr />
                <form className='w-[400px] m-auto' onSubmit={handleSubmit(createLayoutSubmit)}>
                    <div className='flex justify-between space-x-5'>
                        <p className='mt-7'>Name :</p>
                        <input required className="appearance-none w-[80%] block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7" placeholder='Enter Laydown Name' {...register("name")} />
                    </div>
                    <div className='flex justify-end space-x-5 mt-5'>
                        <p className='btn rounded-full px-4 cursor-pointer bg-white border-2 text-gray-500 hover:text-white' onClick={closeAddLayoutModal}>Cancel</p>
                        {addlayoutLoader ?
                            <button
                                className="border-none rounded-3xl bg-red-550 text-white btn loading"
                            >
                                Creating
                            </button>
                            :
                            <input type='submit' value={"Create"} className='bg-red-550 text-white px-4 py-2 rounded-3xl cursor-pointer' />
                        }
                    </div>
                </form>
            </Modal>
        </Container>
    )
}

export default Index
