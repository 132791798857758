import React, { useEffect, useRef } from 'react'
import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

function IndividualZimmerTable(props) {

    const setSelectedPiersTorqueTubes = props?.setSelectedPiersTorqueTubes
    const selectedPiersTorqueTubes = props?.selectedItem

    const selectedPiersTorqueTubesRef = useRef({ piers: [], torque_tubes: [] });
    const containerRef = useRef()

    useEffect(() => {
        renderMap()
    }, [props?.selectedItem, props.selectedTab])

    useEffect(() => {
        if (props.clearState === false) {
            setSelectedPiersTorqueTubes({ piers: [], torque_tubes: [] })
            selectedPiersTorqueTubesRef.current = { piers: [], torque_tubes: [] }
        }
    }, [props.clearState])

    useEffect(() => {
        if (props?.selectedItem) {
            setSelectedPiersTorqueTubes({ piers: props?.selectedItem["piers"], torque_tubes: props?.selectedItem["torque_tubes"] })
            selectedPiersTorqueTubesRef.current = { piers: props?.selectedItem["piers"], torque_tubes: props?.selectedItem["torque_tubes"] }
        }
    }, [])

    console.log(props?.selectedItem)

    const renderMap = () => {

        containerRef.current.innerHTML = "";

        let minimum_latitude = Math.min(...props.data.torque_tube.table_coordinates.map(coord => coord.x))
        let maximum_latitude = Math.max(...props.data.torque_tube.table_coordinates.map(coord => coord.x))
        let minimum_longitude = Math.min(...props.data.torque_tube.table_coordinates.map(coord => coord.y))
        let maximum_longitude = Math.max(...props.data.torque_tube.table_coordinates.map(coord => coord.y))

        console.log(minimum_latitude, maximum_latitude, minimum_longitude, maximum_longitude)

        let xScale = scaleLinear()
            .domain([
                minimum_latitude,
                maximum_latitude,
            ])
            .range([0, 500]);

        let yScale = scaleLinear()
            .domain([
                maximum_longitude,
                minimum_longitude,
            ])
            .range([0, 200]);

        let ttData = props?.data?.torque_tube["table_coordinates"]
        let ttColorCode = props?.data?.torque_tube["color_code"]
        let ttHatch = props?.data?.torque_tube["hatch"]
        if (ttData.length === 4) {
            d3.select(containerRef.current)
                .append("path")
                .attr("class", "testing-border")
                .attr("d", `M${xScale(ttData[0].x)} ${yScale(ttData[0].y)} L${xScale(ttData[1].x)} ${yScale(ttData[1].y)} L${xScale(ttData[2].x)} ${yScale(ttData[2].y)} L${xScale(ttData[3].x)} ${yScale(ttData[3].y)}  Z`)
                .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                .attr("stroke-width", "1px")
                .attr("fill", `white`)
            // .on("click", () => handleSelectTable(tt_trackerId))

            if (ttHatch) {
                d3.select(containerRef.current)
                    .append("line")
                    .attr("class", "testing-border-diag")
                    .attr("x1", xScale(ttData[0].x))
                    .attr("y1", yScale(ttData[0].y))
                    .attr("x2", xScale(ttData[2].x))
                    .attr("y2", yScale(ttData[2].y))
                    .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
            }

            for (let pierData of props?.data?.piers) {
                let labelColor = pierData?.label_name.split("_") ? pierData?.label_name.split("_")[1] : "255-0-0"

                d3.select(containerRef.current)
                    .append("text")
                    .attr("class", "cursor-pointer")
                    .attr("fill", `rgb(${labelColor.split("-")[0]}, ${labelColor.split("-")[1]}, ${labelColor.split("-")[2]})`)
                    .attr("x", xScale(pierData?.latitude))
                    .attr("y", yScale(pierData?.longitude) + 2)
                    .attr("text-anchor", "middle")
                    .style("font-size", "14px")
                    .style("font-weight", "600")
                    .text(pierData?.label_name.split("_") ? pierData?.label_name.split("_")[0] : "")
            }

        }

    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <TransformWrapper centerZoomedOut minScale={0.5} initialScale={1} alignmentAnimation={{ disabled: true }} initialPositionX={50} initialPositionY={50}>
                <TransformComponent contentStyle={{ height: "60vh", width: 1100 }} wrapperStyle={{ height: "100%", width: "100%" }}>
                    <div id="wrapper">
                        <svg ref={containerRef} style={{ height: 700, width: 700, margin: "auto" }} />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
}

export default IndividualZimmerTable
