import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from '../../../components/container';
import { useAtom } from 'jotai';
import Modal from "react-modal"
import { FaLongArrowAltRight, FaUser, FaUsers } from 'react-icons/fa';
import 'react-modern-drawer/dist/index.css'
import { AiOutlineSearch } from "react-icons/ai"
import workHourAtom, {
    createNewActivity,
    createWorkHoursAtom,
    dateAtom,
    deleteWorkHoursAtom,
    editWorkHoursAtom,
    fetchImportWorkHoursAtom,
    fetchPlantId,
    fetchTotalWorkHours,
    fetchWorkHourActivitiesAtom,
    fetchWorkHoursAtom,
    fetchWorkHourUsersAtom
} from '../../../atoms/workHoursAtom';
import GridBoxView from './view';
import StaffList from './staffList';
import EditStaffList from './editList';
import { toast } from 'react-hot-toast';
import { MdTimer } from "react-icons/md";
import { TbForklift, TbUsers } from "react-icons/tb"
import { RiFolderDownloadFill } from "react-icons/ri"
import { BiArrowBack } from 'react-icons/bi'
import { VscClose } from 'react-icons/vsc'
import { BsGridFill, BsFillFileEarmarkImageFill } from 'react-icons/bs'
import { IoChevronBackCircleOutline } from "react-icons/io5"
import { IoIosArrowDropright } from "react-icons/io"
import { AiOutlineUnorderedList } from 'react-icons/ai'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import DrawerComp from './drawer';
import { CgSoftwareDownload } from 'react-icons/cg';
import projectAtom from '../../../atoms/projectAtom';
import { Parser } from 'json2csv';
import SliderToggle from '../../../components/sliderToggle';

export function WorkHour() {

    const searchref = useRef(null)
    const importSearchRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [importLoading, setImportLoading] = useState(false)
    const [importBtnLoading, setImportBtnLoading] = useState(false)

    const [projectsName] = useAtom(projectAtom)
    const [listWorkHour] = useAtom(workHourAtom)
    const [, fetchWorkHourActivities] = useAtom(fetchWorkHourActivitiesAtom);
    const [, fetchWorkHourUsers] = useAtom(fetchWorkHourUsersAtom);
    const [, createWorkHours] = useAtom(createWorkHoursAtom);
    const [, setFetchPlantId] = useAtom(fetchPlantId)
    const [, setFetchImportWorkhours] = useAtom(fetchImportWorkHoursAtom)

    const [editStaffId, setEditStaffId] = useState(null)
    const [editActId, setEditActId] = useState(null)
    const [searchBooleanGrid, setSearchBooleanGrid] = useState(true)
    const [searchBooleanList, setSearchBooleanList] = useState(true)
    const [serachImportStaff, setSearchImportStaff] = useState(false)
    const [view, setView] = useState(false);
    const [importView, setImportView] = useState("List")
    const [date, setDate] = useAtom(dateAtom);
    const [importDate, setImportDate] = useState(new Date())
    const [importedData, setImportedData] = useState([])
    const [importStaffList, setImportStaffList] = useState([])
    const [importWorkhoursGrid, setImportWorkHoursGrid] = useState([])
    const [currentMode, setCurrentMode] = useState("Staff")
    const [searchImportData, setSearchImportData] = useState("")

    const [, fetchWorkHours] = useAtom(fetchWorkHoursAtom);
    const [, updateWorkHours] = useAtom(editWorkHoursAtom);
    const [, deleteWorkHours] = useAtom(deleteWorkHoursAtom);
    const [, setFetchTotalWorkHours] = useAtom(fetchTotalWorkHours)
    const [, setCreateNewAct] = useAtom(createNewActivity)

    const [selectAct, setSelectAct] = useState([])
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const [selectHour, setSelectHour] = useState()
    const [selectActType, setSelectActType] = useState()
    const [selectUser, setSelectUser] = useState()
    const [plantid, setPlantId] = useState()

    const [listFilter, setListFilter] = useState([])

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const tempData = new Date();
    const day = tempData.getDate();
    const month = months[tempData.getMonth()];
    const year = tempData.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    useEffect(() => {
        setListFilter(listWorkHour.data)
    }, [listWorkHour.data, view])

    useEffect(() => {
        setImportStaffList(listWorkHour.importData)
        setImportWorkHoursGrid(listWorkHour.importDataGrid)
    }, [listWorkHour.importData, listWorkHour.importDataGrid])

    useEffect(() => {
        init()
        fetchWorkHours(`${date.toISOString().split('T')[0]}`);
    }, [date])

    useEffect(() => {
        handleImportWH()
    }, [importDate])

    useEffect(() => {
        if (importView === "List") {
            var updateList = [...listWorkHour.importData]
            updateList = updateList.filter((item) =>
                item.firstname.toLowerCase().indexOf(searchImportData.toLowerCase()) !== -1
            )
            if (updateList.length === 0) {
                updateList = [...listWorkHour.importData].filter((item) =>
                    findUserLastName(item?.user_app_id).toLowerCase().indexOf(searchImportData.toLowerCase()) !== -1
                )
            }
            setImportStaffList(updateList)
        }
        else {
            var updateList = [...listWorkHour.importDataGrid]
            updateList = updateList.filter((item) =>
                findActivityName(item.activity_id, item.type).toLowerCase().indexOf(searchImportData.toLowerCase()) !== -1)
            setImportWorkHoursGrid(updateList)
        }
    }, [searchImportData])

    const handleClickOutside = (e) => {
        if (searchref.current && !searchref.current.contains(e.target)) {
            setSearchBooleanList(true)
        }
        if (importSearchRef.current && !importSearchRef.current.contains(e.target)) {
            setSearchImportData("")
            setSearchImportStaff(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            // Get list of activities
            await fetchWorkHourActivities()
            // Get staffs
            await fetchWorkHourUsers()
            // Get total work hours
            await setFetchTotalWorkHours({ date: date.toISOString().split('T')[0] })
            // Get WH data to import 
            await setFetchImportWorkhours(`${importDate.toISOString().split('T')[0]}`)
            // Get plant list data
            await setFetchPlantId()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    const handleImportWH = async () => {
        try {
            setImportLoading(true)
            await setFetchImportWorkhours(`${importDate.toISOString().split('T')[0]}`)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setImportLoading(false)
        }
    }

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const [editFormData, setEditFormData] = useState(
        { work_activity: '', work_staff: '', work_hours: '' }
    )


    const [newActLoading, setNewActLoading] = useState(false)

    const handleAddNewAct = async (e) => {
        try {
            setNewActLoading(true)
            await setCreateNewAct(e)
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
        finally {
            setNewActLoading(false)
        }
    }

    useEffect(() => {
        fetchAct()
    }, [newActLoading])

    const fetchAct = async () => {
        try {
            await fetchWorkHourActivities()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleAddFormSubmit = async (data) => {
        try {
            let dataTemp = []
            setBtnLoading(true)
            console.log(selectAct)
            for (let get of selectedStaffs) {
                let temp = {}
                if (plantid) {
                    temp.activity_id = selectAct[0]
                    temp.hours = selectHour
                    temp.user_app_id = get
                    temp.date = Math.round(date.getTime() / 1000)
                    temp.type = selectAct[1].toString()
                    temp.plant_pk = plantid
                }
                else {
                    temp.activity_id = selectAct[0]
                    temp.hours = selectHour
                    temp.user_app_id = get
                    temp.date = Math.round(date.getTime() / 1000)
                    temp.type = selectAct[1].toString()
                }
                dataTemp.push(temp)
            }
            await createWorkHours({ data: dataTemp, date: date.toISOString().split('T')[0] })
            await fetchWorkHours(`${date.toISOString().split('T')[0]}`);
            await setFetchImportWorkhours(`${date.toISOString().split('T')[0]}`)
            toast.success("Successfully updated")
        } catch (err) {
            console.log(err);
            toast.error("Work Hours could not be added")
        }
        finally {
            setBtnLoading(false)
            setPlantId()
            setSelectAct([])
            setSelectHour(null)
            setSelectedStaffs([])
            setIsOpen((prevState) => !prevState)
        }
    }

    const handleEditFormChange = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name")
        const fieldValue = e.target.value
        const newFormData = { ...editFormData }
        newFormData[fieldName] = fieldValue
        setEditFormData(newFormData)
    }

    const handleEditActChange = (e) => {
        const newFormData = { ...editFormData }
        newFormData["work_activity"] = e
        setEditFormData(newFormData)
        setSelectActType(e[1])
    }

    const handleEditUserChange = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name")
        const fieldValue = e.target.value
        const newFormData = { ...editFormData }
        newFormData[fieldName] = fieldValue
        setEditFormData(newFormData)
        setSelectUser(e.target.value)
    }

    const handleChangePlant = (e) => {
        // e.preventDefault()
        const fieldValue = e
        const newFormData = { ...editFormData }
        newFormData["plant_project_id"] = fieldValue
        setEditFormData(newFormData)
        // setSelectUser(e.target.value)
    }

    const [dum, setDum] = useState()

    const options = []

    listWorkHour.users.map((stf) => {
        options.push({
            value: stf.id,
            label: stf.name + stf.user.profile_data.last_name
        })
    })

    const handleEditSave = async (e) => {
        try {
            setLoading(true)
            e.preventDefault()
            if (editFormData?.plant_project_id) {
                await updateWorkHours({
                    id: editStaffId,
                    hours: parseInt(editFormData.work_hours),
                    type: selectActType ? selectActType : dum.type,
                    activity_id: parseInt(editFormData.work_activity),
                    date: dum.date,
                    user_app_id: selectUser ? parseInt(selectUser) : parseInt(editFormData.work_staff),
                    plant_pk: editFormData.plant_project_id
                })
            }
            else {
                await updateWorkHours({
                    id: editStaffId,
                    hours: parseInt(editFormData.work_hours),
                    type: selectActType ? selectActType : dum.type,
                    activity_id: parseInt(editFormData.work_activity),
                    date: dum.date,
                    user_app_id: selectUser ? parseInt(selectUser) : parseInt(editFormData.work_staff),
                })
            }
            setEditStaffId(null)
            setEditActId(null)
            setSelectActType(null)
            setSelectUser(null)
            await fetchWorkHours(`${date.toISOString().split('T')[0]}`);
            toast.success("Successfully updated")
        } catch (err) {
            toast.error("Unable to update work hours")
        }
        finally {
            setLoading(false)
        }
    }

    const handleEditClick = (e, workhour) => {
        setDum(workhour)
        e.preventDefault()
        setEditStaffId(workhour.id)
        setEditActId(workhour.type)
        const formValues = {
            work_activity: workhour.activity_id,
            work_hours: workhour.hours,
            work_staff: workhour.user_app_id,
            act_type: workhour.type
        }
        setEditFormData(formValues)
    }

    const handleEditCancel = (e) => {
        e.preventDefault()
        setEditStaffId(null)
        setEditActId(null)
        setSelectActType(null)
    }

    const handleDeletetable = async (e, wh_id) => {
        try {
            setLoading(true)
            e.preventDefault()
            console.log(wh_id)
            await deleteWorkHours({ id: wh_id, multiple: false });
            await fetchWorkHours(`${date.toISOString().split('T')[0]}`);
            toast.success("Successfully updated")
        } catch (err) {
            toast.error("Unable to delete work hours")
        }
        finally {
            setLoading(false)
        }
    }

    const handleBulkDelete = async (ids) => {
        try {
            setLoading(true)
            await deleteWorkHours({ id: ids, multiple: true });
            await fetchWorkHours(`${date.toISOString().split('T')[0]}`);
            toast.success("Deleted Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    const handleChangeImportData = (id) => {
        let temp = [...importedData]
        if (importedData.includes(id)) {
            let index = importedData.indexOf(id)
            temp.splice(index, 1)
        }
        else {
            temp.push(id)
        }
        setImportedData(temp)
    }

    const handleSelectImportGridDatas = (dataIds) => {
        let temp = [...importedData]
        dataIds?.map((id) => {
            if (temp.includes(id)) {
                let index = temp.indexOf(id)
                temp.splice(index, 1)
            }
            else {
                temp.push(id)
            }
        })
        setImportedData(temp)
    }

    const handleImportSelectAll = () => {
        if (importedData.length === listWorkHour?.importData.length) {
            setImportedData([])
        }
        else {
            let temp = []
            listWorkHour?.importData.map((i) => {
                temp.push(i?.id.toString())
            })
            setImportedData(temp)
        }
    }


    const handleSubmitImportData = async (e) => {
        e.preventDefault()
        try {
            setImportBtnLoading(true)
            let dataTemp = []
            for (let data of listWorkHour?.importData) {
                for (let key of importedData) {
                    if (parseInt(key) === data?.id) {
                        let temp = {}
                        if (data?.plant_project_id) {
                            temp.activity_id = data?.activity_id
                            temp.hours = data?.hours
                            temp.user_app_id = data?.user_app_id
                            temp.date = Math.round(date.getTime() / 1000)
                            temp.type = data?.type
                            temp.plant_pk = data?.plant_project_id
                            // await createWorkHours({
                            //     data: {
                            //         activity_id: data?.activity_id,
                            //         hours: data?.hours,
                            //         user_app_id: data?.user_app_id,
                            //         date: Math.round(new Date().getTime() / 1000),
                            //         type: data?.type,
                            //         plant_pk: data?.plant_pk
                            //     },
                            //     date: new Date().toISOString().split('T')[0]
                            // })
                        }
                        else {
                            temp.activity_id = data?.activity_id
                            temp.hours = data?.hours
                            temp.user_app_id = data?.user_app_id
                            temp.date = Math.round(date.getTime() / 1000)
                            temp.type = data?.type
                            // await createWorkHours({
                            //     data: {
                            //         activity_id: data?.activity_id,
                            //         hours: data?.hours,
                            //         user_app_id: data?.user_app_id,
                            //         date: Math.round(new Date().getTime() / 1000),
                            //         type: data?.type
                            //     },
                            //     date: new Date().toISOString().split('T')[0]
                            // })
                        }
                        // await fetchWorkHours(`${date.toISOString().split('T')[0]}`)
                        dataTemp.push(temp)
                    }
                }
            }
            await createWorkHours({ data: dataTemp, date: date.toISOString().split('T')[0] })
            await fetchWorkHours(`${date.toISOString().split('T')[0]}`)
            toast.success("Workhours Imported")
        }
        catch (err) {
            console.log(err)
            toast.error("Couldn't import Work Hours")
        }
        finally {
            setImportedData([])
            setModalActivityOpen(false)
            setImportDate(new Date())
            setImportBtnLoading(false)
        }
    }

    const findActivityTitle = (activity_id, type) => {
        let activity = listWorkHour.activities.find(lw => lw.id.toString() === activity_id.toString() && type === lw.type)
        if (activity) {
            if (activity?.type === "construction_activity" && activity?.parent_activity_id) {
                let parent_activity_name = listWorkHour?.activities.find(act => act?.id === activity?.parent_activity_id)
                return (
                    <div className='flex item-center space-x-2' style={{ textTransform: "capitalize" }}>
                        <p>{parent_activity_name?.name}</p>
                        <p className='mt-1'><FaLongArrowAltRight /></p>
                        <p>{activity?.name}</p>
                    </div>
                )
            }
            else {
                return activity.name
            }
        }
        return activity_id
    }

    const dateIncrement = () => {
        setDate(new Date(date.setDate(date.getDate() + 1)))
    }

    const dateDecrement = () => {
        setDate(new Date(date.setDate(date.getDate() - 1)))
    }

    const datePickerStyle = ({ className, children }) => {
        return (
            <div>
                <calendarContainer className={className}>
                    <div style={{ position: "relative" }}>
                        {children}
                    </div>
                </calendarContainer>
            </div>
        )
    }

    const findUserLastName = (data) => {
        let temp
        listWorkHour?.users.map((i) => {
            if (i?.id === data) {
                temp = i?.profile_data?.last_name
            }
        })
        return temp
    }

    const searchFilterListView = (e) => {
        let quaryFilter = e.target.value
        var updateList = [...listWorkHour.data]
        updateList = updateList.filter((item) =>
            item.firstname.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        if (updateList.length === 0) {
            updateList = [...listWorkHour.data].filter((item) =>
                findUserLastName(item?.user_app_id).toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
            )
        }
        setListFilter(updateList)
    }

    const searchFilterGridView = (e) => {
        setView(true)
        let quaryFilter = e.target.value
        var updateList = [...listWorkHour.data]
        updateList = updateList.filter((item) =>
            item.firstname.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        if (updateList.length === 0) {
            updateList = [...listWorkHour.data].filter((item) =>
                findUserLastName(item?.user_app_id).toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
            )
        }
        setListFilter(updateList)
    }

    const handleImportSearch = (e) => {
        setSearchImportData(e.target.value)
    }

    let ttlWh = 0
    listFilter.map((i) => {
        ttlWh += i.hours
    })

    const [modalActivityOpen, setModalActivityOpen] = useState(false)

    function closeModal() {
        setImportedData([])
        setModalActivityOpen(false)
        setImportDate(new Date())
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            // maxHeight: '72vh',
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    };

    const findUserFirstLetter = (user_id) => {
        let user = listWorkHour.users.find(user => user.id === user_id);
        if (user) {
            return user.name?.charAt(0).toUpperCase()
        }
        return user_id
    }

    console.log(listWorkHour.users)

    const findUserName = (user_id) => {
        let user = listWorkHour.users.find(user => user.staff_project_id === user_id);
        if (user) {
            let firstName = user?.user?.name
            let lastName = user?.user?.profile_data?.last_name
            return firstName + " " + lastName
        }
        return user_id
    }

    const findActivityName = (activity_id, type) => {
        let activity = listWorkHour.activities.find(lw => lw.id.toString() === activity_id.toString() && type === lw.type)
        if (activity) {
            return activity.name
        }
        return activity_id
    }

    const handleDownloadWorkHours = () => {
        try {
            toast("Download queued")
            let arr = []
            listFilter.map((workhour) => {
                let temp = {}
                console.log(workhour?.user_app_id)
                temp["Staff"] = findUserName(workhour?.user_app_id)
                temp["Plant Type"] = workhour?.plant_type ? workhour?.plant_type : "-"
                temp["Plant ID"] = workhour?.plant_id ? workhour?.plant_id : "-"
                temp["Activity"] = findActivityName(workhour?.activity_id, workhour.type)
                temp["Work Hours"] = workhour?.hours
                arr.push(temp)
            })
            let newFields = [
                "Staff",
                "Plant Type",
                "Plant Type",
                "Activity",
                "Work Hours"
            ]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", WorkHours\n` + `"Report Date", ${formattedDate}\n` + `\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_WorkHours_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    return (
        <Container>
            <div className='flex justify-between mb-5'>
                <div>
                    <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/staff_and_plant")} />
                    <div className='ml-6 mt-5 text-xl font-bold'>
                        Work Hours
                    </div>
                </div>
                <div className='flex'>
                    <div ref={searchref}>
                        {view ?
                            //List
                            searchBooleanList ?
                                <AiOutlineSearch style={{ width: '25px', height: '25px' }} className="mt-10 cursor-pointer mr-6" onClick={() => setSearchBooleanList(false)} />
                                :
                                <input className='appearance-none text-gray-700 border border-gray-300 leading-tight py-3 px-5 rounded-3xl mr-8 mt-7' type="search" placeholder='Search Staff' style={{ width: '280px', height: '48px' }} onChange={searchFilterListView} />

                            :
                            //grid
                            searchBooleanGrid ?
                                <AiOutlineSearch style={{ width: '25px', height: '25px' }} className="mt-10 cursor-pointer mr-6" onClick={() => { setSearchBooleanList(false); setView(true) }} />
                                :
                                <input className='appearance-none text-gray-700 border border-gray-300 leading-tight py-3 px-5 rounded-3xl mr-8 mt-7' type="search" placeholder='Search Staff' style={{ width: '280px', height: '48px' }} onChange={searchFilterGridView} />

                        }
                    </div>
                    <div style={{ width: '100px' }} className='flex mt-10 mr-2 cursor-pointer' onClick={() => setModalActivityOpen(true)}>
                        <RiFolderDownloadFill className='text-gray-500 ' style={{ width: '25px', height: '25px' }} />
                        <p className='mx-2 font-semibold text-gray-500'>Import</p>
                    </div>
                    <button className='mt-7 mr-7 bg-red-550 text-white border-none rounded-3xl btn' style={{ width: '180px' }} onClick={toggleDrawer}>
                        Add Work Hours +
                    </button>
                </div>
            </div>
            <hr />

            <div className='flex justify-between'>
                <div className='flex' style={{ height: '80px' }}>
                    <div className='border-r-2 border-gray-300 flex justify-center items-center px-5 gap-4' >
                        <div>
                            <IoChevronBackCircleOutline
                                className=' cursor-pointer '
                                alt=''
                                style={{ width: '25px', height: '25px', color: "#696F79" }}
                                onClick={dateDecrement}
                            />
                        </div>
                        <div >
                            <DatePicker
                                dateFormat="dd-MMMM-yyyy"
                                showIcon
                                className='border border-2 rounded-xl w-48 text-center py-2 cursor-pointer'
                                calendarContainer={datePickerStyle}
                                onChange={setDate}
                                selected={date}
                                maxDate={new Date()}
                            />
                        </div>
                        <div>
                            {date.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] ?
                                <IoIosArrowDropright className='text-gray-200' style={{ width: '25px', height: '25px' }} />
                                :
                                <IoIosArrowDropright className='cursor-pointer' style={{ width: '25px', height: '25px', color: "#696F79" }} onClick={dateIncrement} />
                            }
                        </div>
                    </div>
                    <div className='border-r-2 border-gray-300 ' style={{ width: '100px' }}>
                        <p className='mt-3 ml-3 font-medium text-sm text-gray-600'>Total {currentMode}</p>
                        <div className='flex items-center mt-2 ml-4'>
                            <TbUsers fontSize="25px" />
                            <p className='ml-3 font-semibold'>{listFilter?.length}</p>
                        </div>
                    </div>
                    <div className='border-r-2 border-gray-300' style={{ width: '150px' }}>
                        <p className='mt-3 ml-4 font-medium text-sm text-gray-600'>Total Work Hours</p>
                        <div className='flex items-center mt-2 ml-5'>
                            <MdTimer fontSize="25px" />
                            <p className='font-semibold ml-3'>{ttlWh} Hrs</p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <div className='mr-4'>
                        <SliderToggle
                            currentMode={currentMode}
                            setCurrentMode={setCurrentMode}
                            data={["Staff", "Plant"]}
                            icons={[<FaUser />, <TbForklift />]}
                        />
                    </div>
                    <div className='flex font-semibold text-sm mr-4 text-gray-500 hover:text-gray-600 cursor-pointer' onClick={handleDownloadWorkHours}>
                        <CgSoftwareDownload style={{ width: '20px', height: '20px' }} />
                        <p className=' ml-1.5'>Download</p>
                    </div>
                    <Modal
                        isOpen={modalActivityOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Import Work Hours">
                        <div>
                            <div className='flex justify-between'>
                                <p className='font-bold text-xl'>Select Work Hours</p>
                                <VscClose onClick={closeModal} className="cursor-pointer mt-1 active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full " style={{ width: '25px', height: '25px' }} />
                            </div>
                            <div className='flex justify-between'>
                                <div className='flex'>
                                    <div className='border-gray-300 z-20 flex space-x-7 justify-center items-center mt-5'>
                                        <p className='text-gray-400 font-medium'>Copy from</p>
                                        <DatePicker dateFormat="dd-MMMM-yyyy" showIcon className='border border-2 rounded-xl w-48 py-2 pl-2 cursor-pointer' calendarContainer={datePickerStyle} onChange={setImportDate} selected={importDate} maxDate={new Date()} />
                                    </div>
                                </div>
                                <div className='flex space-x-4 items-center'>
                                    <div ref={importSearchRef}>
                                        {serachImportStaff ?
                                            <input className='appearance-none text-gray-700 border border-gray-300 leading-tight py-2 px-5 rounded-3xl mt-5' type="search" placeholder={`${importView === "List" ? "Search Staff" : "Search Activity"}`} style={{ width: '200px', height: "40px" }} onChange={handleImportSearch} />
                                            :
                                            <AiOutlineSearch style={{ width: '25px', height: '25px' }} className={`mt-4 cursor-pointer`} onClick={() => setSearchImportStaff(!serachImportStaff)} />
                                        }
                                    </div>
                                    <div className='flex px-2 mt-5'>
                                        <BsGridFill className={`cursor-pointer ${importView === "Grid" ? "text-red-550 border-red-550" : "text-gray-400"} bg-white text-[28px] px-1 py-0.5 border rounded-l-lg`} onClick={() => { setImportView("Grid"); setImportedData([]) }} />
                                        <AiOutlineUnorderedList className={`cursor-pointer ${importView === "List" ? "text-red-550 border-red-550" : "text-gray-400"} bg-white text-[28px] px-1 py-0.5 border rounded-r-lg`} onClick={() => { setImportView("List"); setImportedData([]) }} />
                                    </div>
                                </div>
                            </div>
                            {importLoading ?
                                <div className='h-[30vh] my-10' style={{ marginTop: "20%", marginLeft: "44%" }}>
                                    <p className='loader ml-3 mb-1'></p>
                                    <p className='font-bold text-xl'>Fetching...</p>
                                </div>
                                :
                                <div className='h-full'>
                                    <form className='h-full'>
                                        <div className='overflow-auto my-4 bg-white z-20 border rounded-xl h-[44vh]'>
                                            {importStaffList?.length > 0 ?
                                                importView === "List" ?
                                                    <table className='table table-compact w-full z-1'>
                                                        <thead>
                                                            <tr className='text-left font-semibold text-xs text-gray-400 sticky top-0 z-10 w-full'>
                                                                <td className='normal-case pl-8'>Staff</td>
                                                                <td className='normal-case'>Activity</td>
                                                                <td className='normal-case'>Work Hours</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <label className='flex mt-2 mx-2'>
                                                                <input type='checkbox' checked={importedData.length === importStaffList.length} className='cursor-pointer' onClick={handleImportSelectAll} />
                                                                <p className='text-gray-400 ml-3 cursor-pointer'>Select All</p>
                                                            </label>
                                                            {importStaffList.map((i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <label>
                                                                                <div className='flex cursor-pointer'>
                                                                                    <input type="checkbox" className='mr-2 mt-1' checked={importedData.includes(i.id.toString())} value={i.id} onChange={e => handleChangeImportData(e.target.value)} />
                                                                                    {i?.profile_url === null ?
                                                                                        <div className="avatar z-0">
                                                                                            <div className="mt-1.5 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base z-0">
                                                                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{i?.firstname?.charAt(0).toUpperCase()}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="avatar z-0" >
                                                                                            <div className="w-8 rounded-full">
                                                                                                <img src={i?.profile_url} alt='' />
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    <p className='mt-2 ml-4 underline underline-offset-2' style={{ textTransform: 'capitalize' }}>{i?.firstname}</p>
                                                                                </div>
                                                                            </label>
                                                                        </td>
                                                                        <td style={{ textTransform: "capitalize" }}>{findActivityName(i?.activity_id, i?.type)}</td>
                                                                        <td>{i?.hours}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div className='overflow-auto flex flex-wrap mb-5 w-full'>
                                                        {importWorkhoursGrid?.map((gridData, index) => {
                                                            return (
                                                                <div className='mx-2'>
                                                                    <label className='flex space-x-2 cursor-pointer'>
                                                                        <GridBoxView
                                                                            key={`wh-grid-${index}`}
                                                                            title={findActivityTitle(gridData.activity_id, gridData.type)}
                                                                            staff={gridData.users}
                                                                            rawData={listWorkHour.rawData}
                                                                            hours={gridData.hours}
                                                                            values={gridData}
                                                                            date={date}
                                                                            onCLickFeature={"MultiSelect"}
                                                                            onClick={e => handleSelectImportGridDatas(e)}
                                                                            rightClickEnable={false}
                                                                            handleBulkDelete={ids => console.log(ids)}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                :
                                                <div className='flex justify-center h-full items-center'>
                                                    <div>
                                                        <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                        <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`flex justify-end mt-`}>
                                            <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setModalActivityOpen(false); setImportedData([]) }}>Cancel</button>
                                            {importedData.length > 0 ?
                                                importBtnLoading ?
                                                    <button className='btn flex space-x-2 items-center border-none rounded-3xl bg-red-550 text-white px-5 mr-4'>
                                                        <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                                        <p>Import</p>
                                                    </button>
                                                    :
                                                    <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={e => { handleSubmitImportData(e) }}>Import</button>
                                                :
                                                <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' disabled>Import</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </Modal>
                    <div style={{ width: '100px' }} className='flex mx-3 '>
                        {view ?
                            <BsGridFill style={{ width: '40px', height: '35px' }} className=' px-1.5 py-1.5 border rounded-l-lg border-gray-300 cursor-pointer text-gray-400' onClick={() => { setView(false); setSearchBooleanList(true) }} /> :
                            <BsGridFill style={{ width: '40px', height: '35px' }} className=' px-1.5 py-1.5 border rounded-l-lg border-gray-300 cursor-pointer text-red-550' />}
                        {view ?
                            <AiOutlineUnorderedList style={{ width: '40px', height: '35px' }} className=' px-2 py-1 border-y border-r rounded-r-lg border-gray-300 cursor-pointer text-red-550' /> :
                            <AiOutlineUnorderedList style={{ width: '40px', height: '35px' }} className=' px-2 py-1 border-y border-r rounded-r-lg border-gray-300 cursor-pointer text-gray-400' onClick={() => { setView(true); setSearchBooleanGrid(true) }} />}
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <DrawerComp
                    open={isOpen}
                    onClose={toggleDrawer}
                    activity={listWorkHour?.activities}
                    staff={listWorkHour?.users}
                    plant={listWorkHour?.plantId}
                    btnLoading={btnLoading}
                    selectedHour={selectHour}
                    onChangeAct={e => setSelectAct(e)}
                    onChangeStaff={e => setSelectedStaffs(e)}
                    onChangePlant={e => { setPlantId(e); }}
                    onChangeHour={e => { setSelectHour(e); }}
                    onClickCancel={() => { setIsOpen(false); setSelectedStaffs([]); setSelectHour() }}
                    onSubmitSave={handleAddFormSubmit}
                    handleAddNewAct={e => handleAddNewAct(e)}
                    actLoad={newActLoading}
                />
            </div>
            {view ?
                <div className="overflow-scroll" style={{ height: 'calc(100vh - 275px)' }}>
                    {loading ?
                        <div style={{ marginTop: "14%", marginLeft: "48%" }}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p></div>
                        :
                        <form onSubmit={handleEditSave}>
                            {listFilter.length > 0 ?
                                <table className="table table-compact w-full z-0">
                                    <thead>
                                        <tr className='text-left font-semibold text-xs text-gray-400 sticky top-0 z-20 w-full'>
                                            <th className='normal-case pl-5' style={{ width: '20%' }}>Activity</th>
                                            <th className='normal-case pl-4' style={{ width: '20%' }}>Staff</th>
                                            <th className='normal-case pl-4' style={{ width: '15%' }}>Plant Type</th>
                                            <th className='normal-case pl-4' style={{ width: '12%' }}>Plant ID</th>
                                            <th className='normal-case pl-4' style={{ width: '15%' }}>Work Hours</th>
                                            <th className='normal-case' style={{ width: '10%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listFilter.map((wh, index) => (<>
                                            {editStaffId === wh.id && editActId === wh.type ?
                                                <EditStaffList
                                                    key={`wh-es-${index}`}
                                                    workhour={wh}
                                                    editFormData={editFormData}
                                                    handleEditCancel={handleEditCancel}
                                                    handleEditFormChange={handleEditFormChange}
                                                    handleEditSave={handleEditSave}
                                                    handleEditActChange={handleEditActChange}
                                                    handleEditUserChange={handleEditUserChange}
                                                    handleChangePlant={handleChangePlant}
                                                /> :
                                                <StaffList
                                                    key={`wh-sl-${index}`}
                                                    workhour={wh}
                                                    handleEditClick={handleEditClick}
                                                    handleDeletetable={handleDeletetable}
                                                />}
                                        </>))}
                                    </tbody>
                                </table>
                                :
                                <div className='flex justify-center w-full'>
                                    <div style={{ marginTop: "15%" }}>
                                        <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                                        <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                    </div>
                                </div>
                            }
                        </form>}
                </div>
                :
                <div className='flex flex-wrap overflow-auto w-full'>
                    {loading ?
                        <div style={{ marginTop: "14%", marginLeft: "48%" }}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p>
                        </div>
                        :
                        <div className='flex flex-wrap overflow-auto w-full'>
                            {Object.keys(listWorkHour.data_group).length > 0 ?
                                <>
                                    {listWorkHour.data_group.map((key, index) => {
                                        return (
                                            <div>
                                                <GridBoxView
                                                    key={`wh-grid-${index}`}
                                                    title={findActivityTitle(key.activity_id, key.type)}
                                                    staff={key.users}
                                                    rawData={listWorkHour.rawData}
                                                    hours={key.hours}
                                                    values={key}
                                                    date={date}
                                                    onCLickFeature={"Navigate"}
                                                    rightClickEnable={true}
                                                    handleBulkDelete={ids => handleBulkDelete(ids)}
                                                />
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className='flex justify-center w-full'>
                                    <div style={{ marginTop: "15%" }}>
                                        <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                                        <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </Container>
    )
}

export default WorkHour