import React from 'react';
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";

const Pagination = ({
    totalItems,
    itemsPerPage,
    currentPage,
    onPageChange,
    maxDisplayedPages = 3
}) => {

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Calculate the range of page numbers to display
    const getPageNumbers = () => {
        const halfWay = Math.floor(maxDisplayedPages / 2);
        let startPage = currentPage - halfWay;
        let endPage = currentPage + halfWay;

        if (startPage <= 0) {
            endPage = maxDisplayedPages;
            startPage = 1;
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - maxDisplayedPages + 1);
        }
        console.log(endPage, startPage, totalPages)
        console.log(Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i))

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    // Calculate the range of items being displayed
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    const pages = getPageNumbers();

    return (
        <div className="flex flex-col items-center space-y-1">
            <div className="flex items-center space-x-2">
                {/* First page button */}
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                    aria-label="First page"
                >
                    <BiChevronsLeft className="w-5 h-5" />
                </button>

                {/* Previous page button */}
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                    aria-label="Previous page"
                >
                    <MdChevronLeft className="w-5 h-5" />
                </button>

                {/* Page numbers */}
                <div className="flex items-center space-x-1">
                    {pages.map(page => (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-2 py-0.5 rounded-lg ${currentPage === page
                                ? 'bg-red-550 text-white'
                                : 'hover:bg-gray-100'
                                }`}
                        >
                            {page}
                        </button>
                    ))}
                </div>

                {/* Next page button */}
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                    aria-label="Next page"
                >
                    <MdChevronRight className="w-5 h-5" />
                </button>

                {/* Last page button */}
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                    aria-label="Last page"
                >
                    <BiChevronsRight className="w-5 h-5" />
                </button>
            </div>

            {/* Items range display */}
            <div className="text-sm text-gray-600">
                Showing {startItem} to {endItem} of {totalItems} items
            </div>
        </div>
    );
};

export default Pagination;