import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import React, { useRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import produce from "immer";
import { useAtom } from "jotai";
import { getZimmermanSelectedBaysDataAtom, getZimmermanSelectedPiersDataAtom } from "../../../atoms/dashboardAtom";

export default function ZimmerBlock(props) {
    const [selectedPiers, setSelectedPiers] = useState([]);
    const [selectedBays, setSelectedBays] = useState([]);
    const selectedBaysRef = useRef([])
    const containerRef = useRef();
    const selectedPiersRef = useRef([]);
    const [, getZimmermanSelectedPiersData] = useAtom(getZimmermanSelectedPiersDataAtom);
    const [, getZimmermanSelectedBaysData] = useAtom(getZimmermanSelectedBaysDataAtom);

    useEffect(() => {
        renderMap();
    }, [props.selectedItem, selectedPiers, selectedBays]);

    console.log(props)
    useEffect(() => {
        getZimmermanCompletedPiers();
    }, [selectedPiers]);

    useEffect(() => {
        getZimmermanCompletedBays();
    }, [selectedBays]);

    useEffect(() => {
        if (props.selectedItem) {
            setSelectedPiers(props?.selectedItem?.piers);
            selectedPiersRef.current = props.selectedItem?.piers;
            setSelectedBays(props?.selectedItem?.torque_tubes)
            selectedBaysRef.current = props?.selectedItem?.torque_tubes
        }
    }, [props.selectedItem]);

    // const getZimmermanCompletedBays = async () => {
    //     try {
    //         // Get current tracker type ID from selected table/row
    //         const trackerTypeId = props.selectedTable && props.selectedTable.length > 0
    //             ? props.selectedTable[0].split('-')[2]
    //             : null;

    //         if (trackerTypeId) {
    //             // Create an object where the key is the tracker type ID and the value is the array of selected pier IDs
    //             const selectedBaysData = {
    //                 [trackerTypeId]: selectedBays
    //             };

    //             console.log("selectedBaysData", selectedBaysData)

    //             // Pass the updated object to the atom
    //             await getZimmermanSelectedBaysData(selectedBaysData);
    //         } else {
    //             // If no tracker type ID is available, still update with the array of selected piers
    //             await getZimmermanSelectedBaysData(selectedBays);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // Update this function to store selected piers by tracker type ID
    
    const getZimmermanCompletedBays = async () => {
        try {
            // Get current tracker type ID from selected table/row
            const trackerTypeId = props.selectedTable && props.selectedTable.length > 0
                ? props.selectedTable[0].split('-')[2]
                : null;
    
            if (trackerTypeId) {
                // Create an object where the key is the tracker type ID and the value is the array of selected bay IDs
                const selectedBaysData = {
                    [trackerTypeId]: selectedBays
                };
    
                // Pass the updated object to the atom
                await getZimmermanSelectedBaysData(selectedBaysData);
            } else {
                // If no tracker type ID is available, still update with the array of selected bays
                await getZimmermanSelectedBaysData(selectedBays);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const getZimmermanCompletedPiers = async () => {
        try {
            // Get current tracker type ID from selected table/row
            const trackerTypeId = props.selectedTable && props.selectedTable.length > 0
                ? props.selectedTable[0].split('-')[2]
                : null;

            if (trackerTypeId) {
                // Create an object where the key is the tracker type ID and the value is the array of selected pier IDs
                const selectedPiersData = {
                    [trackerTypeId]: selectedPiers
                };

                // Pass the updated object to the atom
                await getZimmermanSelectedPiersData(selectedPiersData);
            } else {
                // If no tracker type ID is available, still update with the array of selected piers
                await getZimmermanSelectedPiersData(selectedPiers);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleClickPier = (data) => {
        if (!selectedPiersRef.current.includes(data?.id)) {
            if (selectedPiers.length > 0) {
                setSelectedPiers(produce((draft) => {
                    draft.push(data?.id);
                }));
                selectedPiersRef.current = ([...selectedPiers, data?.id]);
            } else {
                setSelectedPiers([data?.id]);
                selectedPiersRef.current = [data?.id];
            }
        } else {
            let filteredData = selectedPiers.filter((id) => id !== data?.id);
            setSelectedPiers(filteredData);
            selectedPiersRef.current = filteredData;
        }
    };

    // const handleClickBay = (data) => {
    //     if (!selectedBaysRef.current.includes(data?.id)) {
    //         if (selectedBays.length > 0) {
    //             setSelectedBays(produce((draft) => {
    //                 draft.push(data?.id);
    //             }));
    //             selectedBaysRef.current = ([...selectedBays, data?.id]);
    //         } else {
    //             setSelectedBays([data?.id]);
    //             selectedBaysRef.current = [data?.id];
    //         }
    //     } else {
    //         let filteredData = selectedBays.filter((id) => id !== data?.id);
    //         setSelectedBays(filteredData);
    //         selectedBaysRef.current = filteredData;
    //     }
    // };

    const handleClickBay = (data) => {
        if (!selectedBaysRef.current.includes(data?.id)) {
            if (selectedBays.length > 0) {
                setSelectedBays(produce((draft) => {
                    draft.push(data?.id);
                }));
                selectedBaysRef.current = ([...selectedBays, data?.id]);
            } else {
                setSelectedBays([data?.id]);
                selectedBaysRef.current = [data?.id];
            }
        } else {
            let filteredData = selectedBays.filter((id) => id !== data?.id);
            setSelectedBays(filteredData);
            selectedBaysRef.current = filteredData;
        }
    };
    const renderMap = () => {
        // Clear previous SVG content
        if (containerRef.current) {
            d3.select(containerRef.current).selectAll("*").remove();
        }

        let xTableCoordinates = [];
        let yTableCoordinates = [];
        if (props?.tableCoordinates && props?.tableCoordinates?.table_coordinates && props?.tableCoordinates?.table_coordinates.length !== 0) {
            let tableCoordinatesData = props?.tableCoordinates?.table_coordinates;
            for (let i of tableCoordinatesData) {
                xTableCoordinates.push(i["x"]);
                yTableCoordinates.push(i["y"]);
            }
        }

        if (xTableCoordinates.length === 0 || yTableCoordinates.length === 0) {
            return; // No coordinates to render
        }

        let xScale = scaleLinear()
            .domain([
                Math.min(...xTableCoordinates), Math.max(...xTableCoordinates)
            ])
            .range([0, 400]);

        let yScale = scaleLinear()
            .domain([
                Math.max(...yTableCoordinates), Math.min(...yTableCoordinates)
            ])
            .range([0, 200]);

        // Render torque tube
        if (props?.tableCoordinates) {
            let ttData = props?.tableCoordinates["table_coordinates"];
            let ttColorCode = props?.tableCoordinates["color_code"];
            let ttHatch = props?.tableCoordinates["hatch"];

            if (ttData && ttData.length >= 4 && ttColorCode) {
                d3.select(containerRef.current)
                    .append("path")
                    .attr("class", "testing-border")
                    .attr("d", `M${xScale(ttData[0].x)} ${yScale(ttData[0].y)} L${xScale(ttData[1].x)} ${yScale(ttData[1].y)} L${xScale(ttData[2].x)} ${yScale(ttData[2].y)} L${xScale(ttData[3].x)} ${yScale(ttData[3].y)} Z`)
                    .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`)
                    .attr("stroke-width", "1px")
                    .attr("fill", "white");

                if (ttHatch) {
                    d3.select(containerRef.current)
                        .append("line")
                        .attr("class", "testing-border-diag")
                        .attr("x1", xScale(ttData[0].x))
                        .attr("y1", yScale(ttData[0].y))
                        .attr("x2", xScale(ttData[2].x))
                        .attr("y2", yScale(ttData[2].y))
                        .attr("stroke", `rgb(${ttColorCode.split("-")[0]}, ${ttColorCode.split("-")[1]}, ${ttColorCode.split("-")[2]})`);
                }
            }
        }

        //Render Bays
        if (props?.data?.torque_tube) {
            props?.data?.torque_tube?.map((data) => {
                let ttData = data?.tt_window_zimmerman
                d3.select(containerRef.current)
                    .append("path")
                    .attr("class", "tableBays cursor-pointer")
                    .attr("d", `M${xScale(ttData[0]?.latitude)} ${yScale(ttData[0]?.longitude)} L${xScale(ttData[1]?.latitude)} ${yScale(ttData[1]?.longitude)} L${xScale(ttData[3]?.latitude)} ${yScale(ttData[3]?.longitude)} L${xScale(ttData[5]?.latitude)} ${yScale(ttData[5]?.longitude)} L${xScale(ttData[4]?.latitude)} ${yScale(ttData[4]?.longitude)} L${xScale(ttData[2]?.latitude)} ${yScale(ttData[2]?.longitude)} Z`)
                    .attr("stroke", "black")
                    .attr("stroke-width", "1px")
                    .attr("fill", selectedBays.includes(parseInt(data?.id)) ? "lightgreen" : "white")
                    .on("click", () => {
                        handleClickBay(data)
                    })
            })
        }

        // Render piers
        if (props?.data?.piers && props?.data?.piers.length !== 0) {
            for (let data of props?.data?.piers) {
                let tableId = props.selectedTable && props.selectedTable.length > 0
                    ? parseInt(props.selectedTable[0].split("-")[1])
                    : null;

                if (tableId === data?.tracker_row_id) {
                    if (props?.selectedItem?.piers && selectedPiers.includes(parseInt(data?.id))) {
                        d3.select(containerRef.current)
                            .append("circle")
                            .attr("class", "cursor-pointer")
                            .attr("cx", xScale(data?.latitude))
                            .attr("cy", yScale(data?.longitude) - 2)
                            .attr("r", 12)
                            .attr("fill", selectedPiers.includes(parseInt(data?.id)) ? "lightgreen" : "white")
                            .attr("stroke", "black")
                            .attr("stroke-width", "1px")
                            .on("click", () => {
                                handleClickPier(data);
                            });
                    }

                    let labelColor = data?.label_name && data?.label_name.split("_") ? data?.label_name.split("_")[1] : "255-0-0";

                    d3.select(containerRef.current)
                        .append("text")
                        .attr("class", "cursor-pointer")
                        .attr("fill", `rgb(${labelColor.split("-")[0]}, ${labelColor.split("-")[1]}, ${labelColor.split("-")[2]})`)
                        .attr("x", xScale(data?.latitude))
                        .attr("y", yScale(data?.longitude))
                        .attr("text-anchor", "middle")
                        .style("font-size", "10px")
                        .style("font-weight", "700")
                        .text(data?.label_name && data?.label_name.split("_") ? data?.label_name.split("_")[0] : "")
                        .on("click", () => {
                            handleClickPier(data);
                        });
                }
            }
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <TransformWrapper centerZoomedOut minScale={0.5} initialScale={1} alignmentAnimation={{ disabled: true }} initialPositionX={50} initialPositionY={50}>
                <TransformComponent contentStyle={{ height: "60vh", width: 1100 }} wrapperStyle={{ height: "100%", width: "100%" }}>
                    <div id="wrapper">
                        <svg ref={containerRef} style={{ height: 700, width: 700, margin: "auto" }} />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}