import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { VscClose } from 'react-icons/vsc';
import Modal from 'react-modal';
import { FaLongArrowAltRight } from 'react-icons/fa';
import ZimmerBlock from './individualZimmermanBlock';
import produce from 'immer';

function ZimmermanInProgressStepperModal({
    zimmerMan,
    listblocks,
    listId,
    isQtyExceeded,
    getInprogressDefaultValue,
    getLinkedPartName,
    currentTrackerType,
    inprogressTotalPartCount,
    enableSaveButton,
    selectedActivity,
    enableNextButton,
    setBtnEnable,
    inprogressCount,
    setIsQtyExceeded,
    setUpdateQuantity,
    updateInprogressDeployedCount,
    setInprogressDeployedPartCount,
    handleCloseWithoutSaving,
    handleSaveInProgressSteps,
    piersTorqueTubesDetails,
    inprogressIndividualTrackerModal,
    setInprogressIndividualTrackerModal,
    selectedPiersTorqueTubes,
    setSelectedPiersTorqueTubes,
    selectedTrackerRows,
    setDashboard,
    cpTrackerRowWise
}) {
    const [showDescription, setShowDescription] = useState(null);
    const [enableNextBtn, setEnableNextBtn] = useState(false);
    const [individualTrackerAlertModal, setIndividualTrackerAlertModal] = useState(false);
    const [selectedTableZimmermanTableCoordinates, setSelectedTableZimmermanTableCoordinates] = useState({});
    const [selectedZimmermanPierWithStatus, setSelectedZimmermanPierWithStatus] = useState([]);
    const [checkPreviousStatusZimmerman, setCheckPreviousStatusZimmerman] = useState(false);

    useEffect(() => {
        if (listblocks?.zimmermanTableCoordinates) {
            let tableId = selectedTrackerRows[0]?.split("-")[1];
            let selectedTableCoordinates = listblocks?.zimmermanTableCoordinates[tableId];
            setSelectedTableZimmermanTableCoordinates(selectedTableCoordinates);
        }
    }, [listblocks?.zimmermanTableCoordinates, selectedTrackerRows]);


    // useEffect(() => {
    //     if (zimmerMan === true && selectedTrackerRows) {
    //         let piersWithStatus = []
    //         let pierIds = []
    //         let trackerrowId_activityId_key = `${selectedTrackerRows[0]?.split("-")[1]}_${parseInt(selectedActivity)}`
    //         if (cpTrackerRowWise[trackerrowId_activityId_key] && cpTrackerRowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
    //             let pierData = cpTrackerRowWise[trackerrowId_activityId_key]["piers"]
    //             piersWithStatus = pierData
    //             for (let i of pierData) {
    //                 if (i?.status === 1) {
    //                     pierIds.push(i?.pier_id)

    //                 }
    //             }
    //         }
    //         else {
    //             if (piersTorqueTubesDetails && piersTorqueTubesDetails?.piers.length !== 0) {
    //                 for (let i of piersTorqueTubesDetails?.piers) {
    //                     let tableId = parseInt(selectedTrackerRows[0].split("-")[1])
    //                     if (i?.tracker_row_id === tableId) {
    //                         piersWithStatus.push({ pier_id: i?.id, status: 0 })
    //                     }
    //                 }
    //             }
    //         }
    //         setSelectedZimmermanPierWithStatus(piersWithStatus)
    //         console.log("pierIds", pierIds)
    //         setSelectedPiersTorqueTubes(pierIds)
    //         setCheckPreviousStatusZimmerman(true)
    //     }
    // }, [zimmerMan])

    useEffect(() => {
        let deployedCount = getInprogressDefaultValue();
        if (deployedCount <= inprogressTotalPartCount && deployedCount !== 0) {
            setEnableNextBtn(true);
        } else {
            setEnableNextBtn(false);
        }
    }, [getInprogressDefaultValue, inprogressTotalPartCount]);

    useEffect(() => {
        if (zimmerMan === true) {
            if (listblocks?.zimmermanSelectedPiersData) {
                if (selectedZimmermanPierWithStatus && selectedZimmermanPierWithStatus.length !== 0) {
                    setSelectedPiersTorqueTubes(listblocks?.zimmermanSelectedPiersData);
                    handleZimmermanSelectPierItem();
                    setCheckPreviousStatusZimmerman(true);
                }
            }
            if (listblocks?.zimmermanSelectedBaysData) {
                if (selectedZimmermanPierWithStatus && selectedZimmermanPierWithStatus.length !== 0) {
                    setSelectedPiersTorqueTubes(listblocks?.zimmermanSelectedBaysData);
                    handleZimmermanSelectPierItem();
                    setCheckPreviousStatusZimmerman(true);
                }
            }
        }
    }, [listblocks?.zimmermanSelectedPiersData, listblocks?.zimmermanSelectedBaysData, zimmerMan, selectedZimmermanPierWithStatus]);

    // const handleZimmermanSelectPierItem = async () => {
    //     // if (!listblocks?.zimmermanSelectedPiersData) return;

    //     const trackerTypeId = currentTrackerType?.tracker_type_id;
    //     const selectedPierIds = listblocks?.zimmermanSelectedPiersData[trackerTypeId] || [];

    //     // Get previously saved status from cpTrackerRowWise if available
    //     const trackerRowId = selectedTrackerRows[0]?.split("-")[1];
    //     const activityIdKey = `${trackerRowId}_${parseInt(selectedActivity)}`;
    //     const previousProgress = listblocks?.cpTrackerRowWise[activityIdKey];
    //     console.log("activityIdKey ", activityIdKey)
    //     console.log("previousProgress", previousProgress)
    //     // First gather all piers for this tracker type across all selected rows
    //     let allPiersForTracker = [];
    //     selectedTrackerRows.forEach(row => {
    //         if (row.split('-')[2] === trackerTypeId.toString()) {
    //             const rowId = row.split('-')[1];
    //             const piersForRow = piersTorqueTubesDetails?.piers.filter(
    //                 pier => pier.tracker_row_id.toString() === rowId
    //             );
    //             allPiersForTracker = [...allPiersForTracker, ...piersForRow];
    //         }
    //     });

    //     // Update status for all piers based on the selected piers
    //     let updatedPiersWithStatus = allPiersForTracker.map(pier => {
    //         // Check if this pier is in the selected list
    //         const isSelected = selectedPierIds.includes(pier.id);

    //         // If there's previous progress, use that status if available
    //         if (previousProgress && previousProgress.piers) {
    //             const previousPierStatus = previousProgress.piers.find(p => p.pier_id === pier.id);
    //             if (previousPierStatus) {
    //                 return {
    //                     pier_id: pier.id,
    //                     status: isSelected ? 1 : previousPierStatus.status
    //                 };
    //             }
    //         }

    //         return {
    //             pier_id: pier.id,
    //             status: isSelected ? 1 : 0
    //         };
    //     });

    //     // Group piers by tracker row for display
    //     const piersWithStatusByRow = {};
    //     updatedPiersWithStatus.forEach(pier => {
    //         const rowId = allPiersForTracker.find(p => p.id === pier.pier_id)?.tracker_row_id;
    //         if (rowId) {
    //             if (!piersWithStatusByRow[rowId]) {
    //                 piersWithStatusByRow[rowId] = [];
    //             }
    //             piersWithStatusByRow[rowId].push(pier);
    //         }
    //     });

    //     // Update the dashboard state
    //     setDashboard(produce((draft) => {
    //         if (!draft.listOfPiersTorqueTubeIdsWithStatus) {
    //             draft.listOfPiersTorqueTubeIdsWithStatus = {};
    //         }

    //         if (!draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
    //             draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId] = {
    //                 piers: [],
    //                 torque_tubes: []
    //             };
    //         }

    //         // Store all updated piers with status
    //         draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId].piers = updatedPiersWithStatus;
    //     }));

    //     // Update component state
    //     setSelectedZimmermanPierWithStatus(updatedPiersWithStatus);
    // }
    const handleZimmermanSelectPierItem = async () => {
        const trackerTypeId = currentTrackerType?.tracker_type_id;
        const selectedPierIds = listblocks?.zimmermanSelectedPiersData[trackerTypeId] || [];
        const selectedBayIds = listblocks?.zimmermanSelectedBaysData[trackerTypeId] || [];
    
        // Get previously saved status from cpTrackerRowWise if available
        const trackerRowId = selectedTrackerRows[0]?.split("-")[1];
        const activityIdKey = `${trackerRowId}_${parseInt(selectedActivity)}`;
        const previousProgress = listblocks?.cpTrackerRowWise[activityIdKey];
    
        // Process piers
        let allPiersForTracker = [];
        selectedTrackerRows.forEach(row => {
            if (row.split('-')[2] === trackerTypeId.toString()) {
                const rowId = row.split('-')[1];
                const piersForRow = piersTorqueTubesDetails?.piers.filter(
                    pier => pier.tracker_row_id.toString() === rowId
                );
                allPiersForTracker = [...allPiersForTracker, ...piersForRow];
            }
        });
    
        // Update status for all piers based on the selected piers
        let updatedPiersWithStatus = allPiersForTracker.map(pier => {
            // Check if this pier is in the selected list
            const isSelected = selectedPierIds.includes(pier.id);
    
            // If there's previous progress, use that status if available
            if (previousProgress && previousProgress.piers) {
                const previousPierStatus = previousProgress.piers.find(p => p.pier_id === pier.id);
                if (previousPierStatus) {
                    return {
                        pier_id: pier.id,
                        status: isSelected ? 1 : previousPierStatus.status
                    };
                }
            }
    
            return {
                pier_id: pier.id,
                status: isSelected ? 1 : 0
            };
        });
    
        // Process torque tubes (bays)
        let allBaysForTracker = [];
        selectedTrackerRows.forEach(row => {
            if (row.split('-')[2] === trackerTypeId.toString()) {
                const rowId = row.split('-')[1];
                const baysForRow = piersTorqueTubesDetails?.torque_tube?.filter(
                    bay => bay.tracker_row_id?.toString() === rowId
                ) || [];
                allBaysForTracker = [...allBaysForTracker, ...baysForRow];
            }
        });
    
        // Update status for all bays based on the selected bays
        let updatedBaysWithStatus = allBaysForTracker.map(bay => {
            // Check if this bay is in the selected list
            const isSelected = selectedBayIds.includes(bay.id);
    
            // If there's previous progress, use that status if available
            if (previousProgress && previousProgress.torque_tubes) {
                const previousBayStatus = previousProgress.torque_tubes.find(t => t.torque_tube_id === bay.id);
                if (previousBayStatus) {
                    return {
                        torque_tube_id: bay.id,
                        status: isSelected ? 1 : previousBayStatus.status
                    };
                }
            }
    
            return {
                torque_tube_id: bay.id,
                status: isSelected ? 1 : 0
            };
        });
    
        // Update the dashboard state
        setDashboard(produce((draft) => {
            if (!draft.listOfPiersTorqueTubeIdsWithStatus) {
                draft.listOfPiersTorqueTubeIdsWithStatus = {};
            }
    
            if (!draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
                draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId] = {
                    piers: [],
                    torque_tubes: []
                };
            }
    
            // Store all updated piers and bays with status
            draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId].piers = updatedPiersWithStatus;
            draft.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId].torque_tubes = updatedBaysWithStatus;
        }));
    
        // Update component state
        setSelectedZimmermanPierWithStatus(updatedPiersWithStatus);
    };


    const inProgressTrackersBomCustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '75vw',
            maxWidth: "1000px",
            height: "95vh",
            maxHeight: "1200px",
            padding: "0px 20px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
        }
    };

    const alertModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '800px',
            maxHeight: "650px",
            padding: "0px 20px 20px",
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    };

    function closeInProgressTrackerBomModal() {
        setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 });
    }

    const handleChangeUnitOfCounts = (value) => {
        if (value <= inprogressTotalPartCount) {
            setInprogressDeployedPartCount(prev => ({
                ...prev,
                [currentTrackerType.tracker_type_id]: value
            }));

            let updatedObj = {
                ...listblocks.inprogressDeployedCount,
                [currentTrackerType.tracker_type_id]: value
            };

            updateInprogressDeployedCount(updatedObj);

            const isExceeded = value > inprogressTotalPartCount;
            setIsQtyExceeded(isExceeded);
            setEnableNextBtn(true);
        } else {
            setEnableNextBtn(false);
            toast.error("Value must not be greater than unit of counts");
        }
        const isExceeded = value > inprogressTotalPartCount;
        setIsQtyExceeded(isExceeded);
    };

    const handleDistribuetdInstalledQtyOnChange = (e, item, maxVal, type) => {
        const trackerTypeId = parseInt(item?.tracker_type_id);
        const value = parseInt(e.target.value) || 0;

        setUpdateQuantity(prev => ({
            ...prev,
            [trackerTypeId]: {
                ...prev[trackerTypeId],
                [`${item.bom_id}_${item.tracker_row_id}`]: {
                    ...prev[trackerTypeId]?.[`${item.bom_id}_${item.tracker_row_id}`],
                    [type]: value
                }
            }
        }));

        setBtnEnable(true);
        setEnableNextBtn(true);
        setIsQtyExceeded(value > maxVal);

        if (value > maxVal) {
            setBtnEnable(false);
            setEnableNextBtn(false);
            toast.error("Value must not exceed per tracker quantity");
        }
    };

    const getExistingDistributedAndInstalledQuantity = (data) => {
        let existingValue = null;
        let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`;
        if (listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key]) {
            if (listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key][data?.found_type] !== 0) {
                existingValue = listblocks?.existing_distributed_installed_quantity[bomId_trackerrowId_key][data?.found_type];
            }
        }
        return existingValue;
    };

    const getCurrentDistributedAndInstalledQuantity = (data) => {
        let currentValue = null;
        let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`;
        if (Object.keys(listblocks?.updateDistributedAndInstalledQuantity).length > 0) {
            if (listblocks?.updateDistributedAndInstalledQuantity[key] &&
                listblocks?.updateDistributedAndInstalledQuantity[key][data?.found_type]) {
                currentValue = listblocks?.updateDistributedAndInstalledQuantity[key][data?.found_type];
            }
        }
        return currentValue;
    };

    const handleCloseWithoutSavingClick = () => {
        setIndividualTrackerAlertModal(false);
        closeInProgressTrackerBomModal();
        handleCloseWithoutSaving();
    };

    // Render BOM Data Entry Step
    const renderBOMDataStep = () => (
        <div className='h-[70%] overflow-auto'>
            <p className='font-semibold text-base my-2'>Distributed Quantities</p>
            <table className='table table-compact w-full'>
                <thead>
                    <tr>
                        <th className='normal-case'>Part Number</th>
                        <th className='normal-case'>Part Description</th>
                        <th className='normal-case'>Quantities per tracker</th>
                        <th className='normal-case'>Distributed Quantities</th>
                    </tr>
                </thead>
                <tbody>
                    {inprogressIndividualTrackerModal?.bomdata?.distributed?.length > 0 && inprogressIndividualTrackerModal?.bomdata?.distributed.map((districtedBomData) => (
                        <tr key={`dist-${districtedBomData.bom_id}`}>
                            <td>{districtedBomData?.part_name}</td>
                            <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(`${districtedBomData?.bom_id}_distributed`) }} onMouseLeave={() => { setShowDescription() }}>
                                {districtedBomData?.bom_id && showDescription === `${districtedBomData?.bom_id}_distributed` ?
                                    <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                        {districtedBomData?.description}
                                    </p>
                                    :
                                    null
                                }
                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{districtedBomData?.description}</p>
                            </td>
                            <td>{districtedBomData?.total_quantity}</td>
                            <td>
                                <input type='number' className='border px-2 py-1.5 w-32'
                                    defaultValue={listblocks?.updateDistributedAndInstalledQuantity[`${districtedBomData?.bom_id}_${parseInt(districtedBomData?.tracker_row_id)}`] && listblocks?.updateDistributedAndInstalledQuantity[`${districtedBomData?.bom_id}_${parseInt(districtedBomData?.tracker_row_id)}`][districtedBomData?.found_type] ? getCurrentDistributedAndInstalledQuantity(districtedBomData) : getExistingDistributedAndInstalledQuantity(districtedBomData)}
                                    onChange={(e) => handleDistribuetdInstalledQtyOnChange(e, districtedBomData, districtedBomData?.total_quantity, "distributed")} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <hr className='mt-2 w-full' />

            <p className='font-semibold text-base my-2'>Installed Quantities</p>
            <table className='table table-compact w-full'>
                <thead>
                    <tr>
                        <th className='normal-case'>Part Number</th>
                        <th className='normal-case'>Part Description</th>
                        <th className='normal-case'>Quantities per tracker</th>
                        <th className='normal-case'>Installed Quantities</th>
                    </tr>
                </thead>
                <tbody>
                    {inprogressIndividualTrackerModal?.bomdata?.installed?.length > 0 && inprogressIndividualTrackerModal?.bomdata?.installed.map((installedBomData) => (
                        <tr key={`inst-${installedBomData.bom_id}`}>
                            <td>{installedBomData?.part_name}</td>
                            <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(`${installedBomData?.bom_id}_installed`) }} onMouseLeave={() => { setShowDescription() }}>
                                {installedBomData?.bom_id && showDescription === `${installedBomData?.bom_id}_installed` ?
                                    <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                        {installedBomData?.description}
                                    </p>
                                    :
                                    null
                                }
                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{installedBomData?.description}</p>
                            </td>
                            <td>{installedBomData?.total_quantity}</td>
                            <td>
                                <input type='number' className='border px-2 py-1.5 w-32'
                                    defaultValue={listblocks?.updateDistributedAndInstalledQuantity[`${installedBomData?.bom_id}_${parseInt(installedBomData?.tracker_row_id)}`] && listblocks?.updateDistributedAndInstalledQuantity[`${installedBomData?.bom_id}_${parseInt(installedBomData?.tracker_row_id)}`][installedBomData?.found_type] ? getCurrentDistributedAndInstalledQuantity(installedBomData) : getExistingDistributedAndInstalledQuantity(installedBomData)}
                                    onChange={(e) => handleDistribuetdInstalledQtyOnChange(e, installedBomData, installedBomData?.total_quantity, "installed")} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className='flex items-center space-x-3 my-4'>
                <p className='text-base font-medium mr-5'>Unit of Count
                    <br />
                    <span className='text-base'>{getLinkedPartName()}</span>
                </p>
                <input type='number' className='border p-2 w-[100px]' defaultValue={getInprogressDefaultValue()} onChange={e => handleChangeUnitOfCounts(parseInt(e.target.value))} />
                <p className='text-sm'>out of</p>
                <p>{inprogressTotalPartCount}</p>
            </div>
        </div>
    );

    // Main render method
    return (
        <div>
            <Modal
                isOpen={inprogressIndividualTrackerModal.bool}
                onRequestClose={() => setIndividualTrackerAlertModal(true)}
                style={inProgressTrackersBomCustomStyles}
                contentLabel='In-Progress Individual Tracker Type BOM'
            >
                <div className='h-full'>
                    {/* Header */}
                    <div className='flex justify-between font-bold text-base pt-4 py-2 bg-white sticky top-0 z-30'>
                        <div className='flex flex-col'>
                            <p className='my-2'>
                                {inprogressIndividualTrackerModal?.tracker_name}
                            </p>
                        </div>

                        <div className='flex'>
                            <VscClose
                                onClick={() => { setIndividualTrackerAlertModal(true) }}
                                className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full"
                                style={{ width: "25px", height: '25px' }}
                            />
                        </div>
                    </div>

                    {/* Activity info */}
                    <div className='mb-4'>
                        {!["All", "None Selected"].includes(selectedActivity) && listId?.construction ? (
                            <p className='text-gray-500 text-sm flex gap-2 items-center space-x-2 font-medium'>
                                <span className='text-black font-semibold'>
                                    {listId?.construction[listId?.construction[selectedActivity]?.parent_activity_id]?.name}
                                </span>
                                <span className=''>
                                    <FaLongArrowAltRight />
                                </span>
                                <span className='text-black font-semibold'>
                                    {listId?.construction[selectedActivity]?.name}
                                </span>
                            </p>
                        ) : null}
                    </div>

                    <hr />

                    {/* Stepper */}
                    <div className='flex justify-between items-center w-[70%] my-4 m-auto'>
                        <div className='flex items-center space-x-3'>
                            <div className="avatar">
                                <div className={`w-6 text-center rounded-full ${inprogressIndividualTrackerModal?.step === 1 ? "text-white bg-red-550" : "text-white bg-gray-300"}`}>
                                    1
                                </div>
                            </div>
                            <p className={`font-semibold text-sm ${inprogressIndividualTrackerModal?.step === 1 ? "text-black" : "text-gray-400"}`}>
                                Enter BOM data
                            </p>
                        </div>

                        <hr className='w-[60%]' />

                        <div className='flex items-center space-x-3'>
                            <div className="avatar">
                                <div className={`w-6 text-center rounded-full ${inprogressIndividualTrackerModal?.step === 2 ? "text-white bg-red-550" : "text-white bg-gray-300"}`}>
                                    2
                                </div>
                            </div>
                            <p className={`font-semibold text-sm ${inprogressIndividualTrackerModal?.step === 2 ? "text-black" : "text-gray-400"}`}>
                                Enter Colors
                            </p>
                        </div>
                    </div>

                    <hr />

                    {/* Content based on step */}
                    {inprogressIndividualTrackerModal?.step === 1 ? (
                        renderBOMDataStep()
                    ) : (
                        <div className='h-[72%] overflow-auto'>
                            <p className='text-sm my-3'>Choose Piers and Bays that are completed</p>
                            {zimmerMan ? (
                                <ZimmerBlock
                                    data={piersTorqueTubesDetails}
                                    pierLegend={listblocks?.pierLegends}
                                    tableCoordinates={selectedTableZimmermanTableCoordinates}
                                    selectedItem={selectedPiersTorqueTubes}
                                    clearState={false}
                                    selectedTable={selectedTrackerRows}
                                    type="CP"
                                />
                            ) : null}
                        </div>
                    )}

                    <hr />

                    {/* Bottom navigation */}
                    <div className='my-5'>
                        {inprogressIndividualTrackerModal?.step === 1 ? (
                            enableNextBtn ? (
                                <div className='flex justify-end'>
                                    <button
                                        className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full'
                                        onClick={() => setInprogressIndividualTrackerModal(produce((draft) => { draft.step = 2 }))}
                                    >
                                        Next
                                    </button>
                                </div>
                            ) : (
                                <div className='flex justify-end'>
                                    <button className='cursor-pointer px-6 py-2 bg-gray-400 text-white rounded-full'>
                                        Next
                                    </button>
                                </div>
                            )
                        ) : (
                            <div className='flex justify-between'>
                                <p
                                    className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full'
                                    onClick={() => setInprogressIndividualTrackerModal(produce((draft) => { draft.step = 1 }))}
                                >
                                    Back
                                </p>
                                {enableSaveButton ? (
                                    <p
                                        className='cursor-pointer px-6 py-2 bg-red-550 text-white rounded-full'
                                        onClick={handleSaveInProgressSteps}
                                    >
                                        Save
                                    </p>
                                ) : (
                                    <p className='px-6 py-2 bg-gray-400 text-white rounded-full'>
                                        Save
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>

            {/* Confirmation Modal */}
            <Modal
                isOpen={individualTrackerAlertModal}
                style={alertModalStyles}
                contentLabel='Individual Tracker alert modal'
            >
                <div className=''>
                    <p className='text-base font-semibold my-5 pl-3 pr-16'>
                        All the entered data within this tracker type will be lost. Please confirm
                    </p>
                    <div className='flex space-x-4 justify-end text-sm font-normla'>
                        <button
                            className='px-4 py-2 rounded-full text-gray-500 border'
                            onClick={() => setIndividualTrackerAlertModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className='px-4 py-2 rounded-full bg-red-550 text-white'
                            onClick={handleCloseWithoutSavingClick}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ZimmermanInProgressStepperModal;