import React, { useState, useCallback, useRef } from "react";
import Container from "../../../components/container";
import Drawer from "react-modern-drawer";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import ImageViewer from "react-simple-image-viewer";
import { v4 as uuid } from "uuid";
import * as XLSX from 'xlsx'
import { SlCalender } from "react-icons/sl"
import axios, { isAxiosError } from "axios";
import { CgSoftwareDownload } from "react-icons/cg"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { VscClose } from "react-icons/vsc";
import { BiArrowBack } from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { AiOutlineInfoCircle, AiOutlineSearch } from "react-icons/ai"
import { IoMdRemoveCircleOutline } from "react-icons/io"
import { RiEdit2Fill, RiFolderDownloadFill } from "react-icons/ri"
import { TbForklift } from "react-icons/tb"
import { TbCameraPlus } from "react-icons/tb";
import Modal from "react-modal"
import { BsThreeDots } from "react-icons/bs";
import plantAtom, {
  createPlantAtom,
  deletePlant,
  fetchPlantAtom,
  fetchorgPlants,
  handleForceDeleteAtom,
  updateImportPlantAtom
} from "../../../atoms/plantAtom";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactTooltip from 'react-tooltip'
import { DateFormat } from "../../../components/dateFormat";
import produce from "immer";
import { FaUser, FaUsers } from "react-icons/fa";
// import PDFViewer from "../PDFViewer";
import { MdOutlineFileDownload } from "react-icons/md"
import { saveAs } from 'file-saver';
import userAtom from "../../../atoms/userAtom";
import PDFViewer from "../PDFViewer";
import { Parser } from 'json2csv'
import projectAtom from "../../../atoms/projectAtom";
import SliderToggle from "../../../components/sliderToggle";
// import MyPdfViewer from "./PdfViewerComponent";

function PlantList() {

  const [listPlant] = useAtom(plantAtom)
  const [listUser] = useAtom(userAtom)
  const [projectsName] = useAtom(projectAtom)
  const [, setGetPlant] = useAtom(fetchPlantAtom)
  const [, setGetOrgPlant] = useAtom(fetchorgPlants)
  const [, setCreatePlant] = useAtom(createPlantAtom)
  const [, setDeletePlant] = useAtom(deletePlant)
  const [, setUpdateImportPlant] = useAtom(updateImportPlantAtom)
  const [, setForceDelete] = useAtom(handleForceDeleteAtom)

  const [importLoader, setImportLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const [editImageHover, setEditImageHover] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [existPlantModel, setExistPlantModel] = useState(false)
  const [searchPlantData, setSearchPlantData] = useState("")
  const [date, setDates] = useState();
  const [existData, setExistData] = useState({ registration_number: null })
  const [plantList, setPlantList] = useState(listPlant?.data);
  const [importPlant, setImportPlant] = useState([])
  const [copyImport, setCopyImport] = useState([])
  const { register, handleSubmit, reset, setValue } = useForm();
  const navigate = useNavigate();
  const [imageBoolean, setImageBoolean] = useState(false);
  const [images, setImages] = useState()
  const [profileImg, setProfileImg] = useState()
  const [document, setDocument] = useState([])
  const [docNameEdit, setDocNameEdit] = useState([])
  const [docCount, setDocCount] = useState([1])
  const [docNameChange, setDocNameChange] = useState()
  const [docRenameBox, setDocRenameBox] = useState([])
  const [docImage, setDocImage] = useState([])
  const [downloadImg, setDownloadImg] = useState()
  const [isDocOpen, setIsDocOpen] = useState(false)
  const onDrop = useCallback((acceptedFiles) => { }, []);
  const { getInputProps, isDragActive } = useDropzone({ onDrop });
  const [plantId, setPlantId] = useState('')
  const [plantType, setPlantType] = useState('')
  const [isActive, setIsActive] = useState('Project Plant')
  const [isSearch, setIsSearch] = useState(false);
  const [duplicateEntry, setDuplicateEntry] = useState({})
  const [isModalShow, setIsModalShow] = useState(false);
  const [editOnclickData, setEditOnclickData] = useState()
  const [importModal, setImportModal] = useState(false)

  const existPlantModelRef = useRef(false)
  const searchRef = useRef(null);

  let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const Formatedate = new Date();
  const day = Formatedate.getDate();
  const month = months[Formatedate.getMonth()];
  const year = Formatedate.getFullYear().toString().slice(-2);

  const formattedDate = `${day}-${month}-${year}`;

  const handleClickOutside = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target) && !existPlantModelRef.current) {
      setIsSearch(false);
      setSearchPlantData("")
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      window.document.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setLoading(true)
      await setGetPlant()
      await setGetOrgPlant()
    }
    catch (err) {
      console.log(err)
      toast.error(err)
    }
    finally {
      setLoading(false)
    }
  }

  const imageHover = () => {
    setImageBoolean(true);
  };

  const imageLeave = () => {
    setImageBoolean(false);
  }

  const handleDocChange = (e, index) => {
    let temp = e.target.files[0].name.split(".")[1]
    if (temp === "png" || temp === "jpg" || temp === "pdf") {
      let data = [...document]
      data[index] = e.target.files[0]
      let dataName = [...docNameEdit]
      dataName[index] = e.target.files[0].name
      setDocument(data)
      setDocNameEdit(dataName)
    }
    else {
      toast.error("File type must be png or jpg or pdf")
    }
  }

  const handleDocNameChange = (index) => {
    setDocRenameBox(produce((draft) => { draft[index] = false }))
    let temp = [...docNameEdit]
    temp[index] = docNameChange
    setDocNameEdit(temp)
    setDisableSubmit(false)
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    reset()
    setDates()
    setProfileImg()
    setDocument([])
    setIsOpen((prevState) => !prevState);
    setIsEdit(false)
    setImages()
    setDocCount([1])
  };

  function checkPlantDataExist(plantList, registration_number) {
    let temp = plantList.find(o => o?.registration_number === registration_number)
    console.log(temp)
    let result
    if (temp) {
      result = true
    }
    else {
      result = false
    }
    return result
  }

  const checkSubmitPlant = async (data) => {
    if (date === undefined) {
      toast.error("Please enter Date on sight")
    }
    else {
      if (checkPlantDataExist(plantList, data.registration_number) && (parseInt(editOnclickData?.registration_number) !== parseInt(existData.registration_number))) {
        setIsOpen(false)
        setDuplicateEntry(data)
        setExistPlantModel(true)
        existPlantModelRef.current = true
      }
      else {
        if (isEdit) {
          onEditSubmit(data)
        }
        else {
          onSubmit(data)
        }
      }
    }
  }

  const onSubmit = async (data) => {
    try {
      setBtnLoading(true)
      let temp = {}
      temp.document = []
      if (document.length > 0) {
        for (let key in document) {
          let docFormData = new FormData();
          let docFile = new File([document[key]], `${docNameEdit[key]}_${uuid()}.${docNameEdit[key].split('.').at(-1)}`);
          docFormData.append("file", docFile)
          let docRes = await axios.post(`/file_upload/upload/image?org=True${docNameEdit[key] === "pdf" ? "&user_content_type=application/pdf" : ""}`, docFormData, { headers: { "Content-Type": "multipart/form-data" } })
          temp.document.push(
            {
              type: 1,
              id: docRes.data,
              name: docNameEdit[key]
            }
          )
        }
      }
      if (profileImg) {
        let profileFormData = new FormData();
        let profileFile = new File([profileImg], `${uuid()}.${profileImg.name.split('.').at(-1)}`);
        profileFormData.append("file", profileFile)
        let profileRes = await axios.post(`/file_upload/upload/image?org=True`, profileFormData, { headers: { "Content-Type": "multipart/form-data" } })
        temp.document.push(
          {
            type: 3,
            id: profileRes.data,
            name: profileImg?.name
          }
        )
      }
      temp.name = data.name
      temp.plant_id = data.plant_id
      temp.plant_type = data.plant_type
      temp.make = data.make
      temp.model = data.model
      temp.registration_number = data.registration_number
      temp.status = 0
      temp.date = Math.round(date.getTime() / 1000)
      temp.org_id = listUser?.data?.org_id
      if (isActive === "Project Plant") {
        await setCreatePlant({ data: temp, org: false })
      }
      else {
        await setCreatePlant({ data: temp, org: true })
      }
      await setGetOrgPlant()
      await setGetPlant()
      toast.success("Plant Added")
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      setIsOpen(false);
      reset();
      setImages()
      setDocument([])
      setDocNameEdit([])
      setDocRenameBox([])
      setDocNameChange()
      setDocCount([1])
      setDates()
      setIsEdit(false)
      setBtnLoading(false)
      setPlantType('')
      setPlantId('')
      setExistPlantModel(false)
      existPlantModelRef.current = false
    }
  };

  useEffect(() => {
    if (isActive === "Project Plant") {
      setPlantList(listPlant?.data)
    }
    else {
      setPlantList(listPlant?.orgData)
    }
    setImportPlant(listPlant?.orgData)
  }, [listPlant, isActive])

  const onEditSubmit = async (data) => {
    try {
      setBtnLoading(true)
      let temp = {}
      temp.document = []
      temp.id = data.id
      temp.name = data.name
      temp.plant_id = data.plant_id
      temp.plant_type = data.plant_type
      temp.make = data.make
      temp.model = data.model
      temp.registration_number = existData.registration_number
      temp.status = 0
      temp.org_id = listUser?.data?.org_id
      if (date === undefined) {
        temp.date = null
      }
      else {
        temp.date = Math.round(date.getTime() / 1000)
      }
      if (document.length > 0) {
        for (let key in document) {
          if (document[key]?.upload_id) {
            temp.document.push(
              {
                type: 1,
                id: document[key]?.upload_id,
                name: docNameEdit[key]
              }
            )
          }
          else {
            let docFormData = new FormData();
            let docFile = new File([document[key]], `${docNameEdit[key]}_${uuid()}.${docNameEdit[key].split('.').at(-1)}`);
            docFormData.append("file", docFile)
            let docRes = await axios.post(`/file_upload/upload/image?org=True${docNameEdit[key].split(".")[1] === "pdf" ? "&user_content_type=application/pdf" : ""}`, docFormData, { headers: { "Content-Type": "multipart/form-data" } })
            temp.document.push(
              {
                type: 1,
                id: docRes.data,
                name: docNameEdit[key]
              }
            )
          }

        }
      }

      if (profileImg) {
        if (profileImg?.name) {
          let profileFormData = new FormData();
          let profileFile = new File([profileImg], `${uuid()}`);
          profileFormData.append("file", profileFile)
          let profileRes = await axios.post(`/file_upload/upload/image?org=True`, profileFormData)
          temp.document.push(
            {
              type: 3,
              id: profileRes.data,
              name: profileImg?.name
            }
          )
        }
        else {
          temp.document.push(
            {
              type: 3,
              id: data?.profile,
              name: data?.profileName
            }
          )
        }
      }
      if (isActive === "Project Plant") {
        await setCreatePlant({ data: temp, org: false })
      }
      else {
        await setCreatePlant({ data: temp, org: true })
      }
      await setGetOrgPlant()
      await setGetPlant()
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsOpen(false);
      reset();
      setImages()
      setDocument([])
      setDocNameEdit([])
      setDocRenameBox([])
      setDocNameChange()
      setDocCount([1])
      setDates()
      setBtnLoading(false)
      setExistPlantModel(false)
      existPlantModelRef.current = false
    }
  }

  const handleProfileImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]));
    setProfileImg(e.target.files[0])
  };

  const handleEditClick = async (i) => {
    setIsEdit(true)
    setIsOpen((prevState) => !prevState)
    setValue("profile", i?.profile)
    setValue("id", i?.id)
    setValue("name", i?.name)
    setValue("plant_id", i?.plant_id)
    setValue("plant_type", i?.plant_type)
    setValue("make", i?.make)
    setValue("model", i?.model)
    setValue("registration_number", i?.registration_number)
    setDates(new Date(i?.date * 1000))
    setPlantId(i?.plant_id)
    setPlantType(i?.plant_type)
    if (i?.document) {
      let tempDocCount = []
      let tempDoc = []
      let tempDocName = []
      i?.document.map((doc) => {
        if (doc?.type === 1 && doc?.name) {
          tempDocCount.push(tempDocCount.length + 1)
          tempDoc.push(doc)
          tempDocName.push(doc?.name)
          setDocument(tempDoc)
          setDocNameEdit(tempDocName)
          setDocCount(tempDocCount)
        }
        if (doc.type === 1 && doc?.name === null) {
          setDocCount([1])
        }
        if (doc.type === 3 && doc?.upload_id) {
          setValue("profile", doc?.upload_id)
          setValue("profileName", doc?.name)
          axios.get(`/file_upload/download?id=${doc?.upload_id}&org=True`).then((res) => {
            setProfileImg(res.data)
            setImages(res.data)
          })
        }
        else {
          setProfileImg()
        }
      })
    }
    setExistData(produce((draft) => { draft.registration_number = i?.registration_number }))
    setEditOnclickData(i)
  }

  const handleAddDoc = () => {
    let temp = [1]
    for (let i = 0; i < docCount.length;) {
      temp.push(i + 2)
      i++
    }
    setDocCount(temp)
  }

  const handleRemoveDoc = (count, index) => {
    let temp = docCount.filter(item => item !== count)
    if (document.length > 0) {
      if (isEdit) {
        let tempDoc = document.filter((_, item) => item !== index)
        setDocument(tempDoc)
        let tempName = docNameEdit.filter((_, item) => item !== index)
        setDocNameEdit(tempName)
      }
      else {
        document.splice(index, 1)
        docNameEdit.splice(index, 1)
      }

    }
    setDocCount(temp)
  }

  const [deletePlantId, setDeletePlantId] = useState()

  const handleDelete = async (data) => {
    try {
      setLoading(true)
      if (isActive === "Project Plant") {
        await setDeletePlant({ id: data?.plant_project_id, org: true })
      }
      else {
        await setDeletePlant({ id: data?.id, org: false })
      }
      await setGetOrgPlant()
      await setGetPlant()
    }
    catch (err) {
      console.log(err)
      setDeleteModalOpen(true)
      if (isActive === "Project Plant") {
        setDeletePlantId(data?.plant_project_id)
      }
      else {
        setDeletePlantId(data?.id)
      }
    }
    finally {
      setLoading(false)
    }
  }

  const handleForceDelete = async () => {
    try {
      setLoading(true)

      if (isActive === "Project Plant") {
        await setForceDelete({ id: deletePlantId, org: true })
      }
      else {
        await setForceDelete({ id: deletePlantId, org: false })
      }
      await setGetOrgPlant()
      await setGetPlant()
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setDeleteModalOpen(false)
      setLoading(false)
    }
  }

  function getFileSize(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, true);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const contentLength = xhr.getResponseHeader('Content-Length');
            resolve(parseInt(contentLength, 10));
          } else {
            reject(new Error('Failed to get file size'));
          }
        }
      };

      xhr.send();
    });
  }

  const openImageViewer = async (data) => {
    setDownloadImg(data)
    setIsModalShow(true)
    setIsDocOpen(true)
    let temp = []
    if (data?.upload_id) {
      let res = await axios.get(`/file_upload/download?id=${data?.upload_id}&org=True`)
      temp.push(res.data)
      if (data.name.split(".")[1] === "pdf") {
        getFileSize(res.data)
          .then(size => {
            if (size < 25000000) {
              window.open(`/pdfViewer?url=${temp[0]}`, '_blank')
              console.log(`File size: ${size} bytes`)
            }
            else {
              toast("Download queued")
              saveAs(res.data, data.name);
            }
          })
          .catch(error => {
            console.error(error)
            toast.error("Something went wrong")
          });
      }
    }
    if (data.name.split(".")[1] === "png" || data.name.split(".")[1] === "jpg") {
      setDocImage(temp)
    }
  }

  const handleSavePdf = async (data) => {
    if (data?.upload_id) {
      let res = await axios.get(`/file_upload/download?id=${data?.upload_id}&org=True`)
      saveAs(res.data, data?.name);
    }
  }

  const closeImageViewer = () => {
    setDownloadImg()
    setIsDocOpen(false)
    setDocImage([])
  }

  const handleDownloadProfile = (i) => {
    setDocImage([i?.profile_url])
    setIsDocOpen(true)
    i?.document.map((j) => {
      if (j?.type === 3) {
        setDownloadImg(j)
      }
    })
  }

  const datePickerStyle = ({ className, children }) => {
    return (
      <div>
        <calendarContainer className={className}>
          <div style={{ position: "relative" }}>
            {children}
          </div>
        </calendarContainer>
      </div>
    )
  }
  const handleClick = (value) => {
    setIsActive(value)
  }

  const handleViewExistData = () => {
    setIsSearch(true)
    setExistPlantModel(false)
    existPlantModelRef.current = false
    let temp = listPlant?.orgData?.filter(o => o?.registration_number === existData?.registration_number.toString())
    setSearchPlantData(existData?.registration_number)
    setPlantList(temp)
  }

  const handleInputChange = (e) => {
    setSearchPlantData(e.target.value)
  };

  useEffect(() => {
    if (isActive !== "Project Plant") {
      setPlantList(
        listPlant?.orgData?.filter(item => item.name.toLowerCase().includes((searchPlantData).toLowerCase()) ||
          item.registration_number.includes(searchPlantData))
      );
    }
    else {
      setPlantList(
        listPlant?.data?.filter(item => item.name.toLowerCase().includes((searchPlantData).toLowerCase()) ||
          item.registration_number.includes(searchPlantData))
      );
    }
  }, [searchPlantData])

  const handleSubmitImport = async (e) => {
    e.preventDefault()
    try {
      setImportLoader(true)
      await setUpdateImportPlant(copyImport)
      await setGetPlant()
      await setGetOrgPlant()
      toast.success("Plant Imported")
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setImportLoader(false)
      setModalActivityOpen(false)
      setCopyImport([])
      setImportPlant(listPlant?.orgData)
      setImportModal(false)
    }
  }

  let subtitle;
  const [modalActivityOpen, setModalActivityOpen] = useState(false)

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    setModalActivityOpen(false)
    setImportPlant(listPlant?.orgData)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '540px'
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  };

  const existPlantStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40vw',
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const handleDateChange = (value) => {
    setDates(new Date(value))
  }

  const handleSearchImportPlant = (e) => {
    setImportPlant(
      listPlant?.orgData?.filter(item => item.name.toLowerCase().includes((e.target.value).toLowerCase()) ||
        item.registration_number.includes(e.target.value))
    );
  }

  function handleCheckPlantExists(userId) {
    let temp = plantList.find(o => o?.id === userId)
    if (temp?.id) {
      return true
    }
    else {
      return false
    }
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const deleteCustomStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      boxShadow: "3px 3px 10px 1px",
      width: "40vw"
    },
  }

  const createWorksheetWithData = (data, customHeaders) => {
    // First, create arrays for custom content
    const headerRows = [
      [`Project Number`, `${project_name?.project_number}`],
      ["Project Name", `${project_name?.name}`],
      ["Report", `${isActive === "Project Staff" ? "Project Plant List" : "Org Plant List"}`],
      ["Report Date", `${formattedDate}`],
      ['']  // empty row
    ];

    // Convert the data array to array of arrays for easier manipulation
    const dataAsArray = [
      Object.keys(data[0]), // Column headers
      ...data.map(obj => Object.values(obj)) // Data rows
    ];

    // Combine custom headers and data
    const allRows = [...headerRows, ...dataAsArray];

    // Create worksheet from combined arrays
    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    return worksheet;
  };

  const formatingDate = (plantDate) => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let date = new Date(plantDate);
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear().toString().slice(-4);
    if (day < 10) {
      day = `0${day}`
    }

    let formattedDate = `${day}-${month}-${year}`;
    return formattedDate
  }

  function getDocsList(docsData) {
    let temp = []
    docsData.map((docs) => {
      if (docs.type === 1 && docs?.name) {
        temp.push(docs?.name.split(".")[0])
      }
    })
    var stringValueYouWant = temp.join(' , ')
    return stringValueYouWant
  }

  const handleDownloadPlant = () => {
    try {
      toast("Download Queued")
      let arr = []
      let reportName
      let type
      if (isActive === "Project Plant") {
        type = "data"
        reportName = "Project Plant"
      }
      else {
        type = "orgData"
        reportName = "Org Plant"
      }
      listPlant[type].map((plantData) => {
        let temp = {}
        temp["Name"] = plantData?.name
        temp["Plant ID"] = plantData?.plant_id
        temp["Plant Type"] = plantData?.plant_type
        temp["Make & Model"] = `${plantData?.make ? plantData?.make : "-"} & ${plantData?.model ? plantData?.model : "-"}`
        temp["Date on Sight"] = formatingDate(plantData?.date * 1000)
        temp["Registration Number"] = plantData?.registration_number
        temp["Docs"] = getDocsList(plantData?.document)
        arr.push(temp)
      })
      const workbook = XLSX.utils.book_new();
      const worksheet1 = createWorksheetWithData(arr)
      XLSX.utils.book_append_sheet(workbook, worksheet1, `${reportName} List`);
      XLSX.writeFile(workbook, `${project_name?.project_number}_${reportName} List_${project_name?.name}.xlsx`);
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
  }

  return (
    <Container>
      <div className="flex justify-between mb-5">
        <div>
          <BiArrowBack
            style={{ width: "25px", height: "25px" }}
            className="cursor-pointer mt-4 ml-5"
            onClick={() => navigate("/staff_and_plant")}
          />
          <div className="ml-6 mt-5 text-xl font-bold">Plant List</div>
        </div>
        <div className="flex items-center mt-4">
          <div ref={searchRef}>
            {!isSearch ?
              <AiOutlineSearch className='text-2xl mr-5 mt-1 cursor-pointer' onClick={() => setIsSearch(true)} />
              :
              <input
                className='appearance-none text-gray-700 border border-gray-300 leading-tight py-3 px-5 rounded-3xl mr-4'
                type='search'
                placeholder='Search Plant' style={{ width: "280px", height: "48px" }}
                value={searchPlantData}
                onChange={e => handleInputChange(e)}
              />
            }
          </div>
          <div className="mr-4">
            <SliderToggle
              currentMode={isActive}
              setCurrentMode={setIsActive}
              data={["Project Plant", "Org Plant"]}
              icons={[<FaUsers />, <FaUser />]}
            />
          </div>
          {/* <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100 mr-4'>
            <div className={`flex ${isActive === 'Project Plant' ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => { handleClick("Project Plant") }}>
              <FaUsers className={`mt-3 ml-3 ${isActive === 'Project Plant' ? "text-white" : "text-gray-400"}`} />
              <p className={`${isActive === 'Project Plant' ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>Project Plant</p>
            </div>
            <div className={`flex ${isActive === 'Org Plant' ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => { handleClick("Org Plant") }}>
              <FaUser className={`mt-3 ml-3 ${isActive === 'Org Plant' ? "text-white" : "text-gray-400"}`} />
              <p className={`${isActive === 'Org Plant' ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>Org Plant</p>
            </div>
          </div> */}
          <div className='flex font-semibold text-sm mr-4 text-gray-500 hover:text-gray-600 cursor-pointer' onClick={handleDownloadPlant}>
            <CgSoftwareDownload className='' style={{ width: '20px', height: '20px' }} />
            <p className=' ml-1.5'>Download</p>
          </div>
          <Modal
            isOpen={modalActivityOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Import Plant">
            <div>
              <div className='flex justify-between'>
                <p className='font-bold text-xl'>Select Plant</p>
                <VscClose onClick={closeModal} className="cursor-pointer mt-1" style={{ width: '25px', height: '25px' }} />
              </div>
              <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Plant' onChange={e => handleSearchImportPlant(e)} />
              <p className='m-2 pt-1 text-gray-400 font-medium'>{importPlant?.length} plant available in the database</p>
              <form>
                {loading ?
                  <div className='' style={{ marginTop: "20%", marginLeft: "44%" }}>
                    <p className='loader ml-3 mb-1'></p>
                    <p className='font-bold text-xl'>Fetching...</p></div>
                  :
                  <div className='overflow-auto' style={{ height: '290px' }}>
                    {importPlant.length > 0 ?
                      importPlant.map((i) => {
                        if (handleCheckPlantExists(i?.id)) {
                          return <>
                            <label>
                              <div className='flex mt-1 cursor-auto bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' value={i?.id} disabled />
                                {i?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2 flex flex-col justify-center'>
                                  <span className='text-sm font-semibold text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.name}</span>
                                  <p className='text-xs font-normal text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.plant_id}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                        else {
                          return <>
                            <label>
                              <div className='flex mt-2 cursor-pointer hover:bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' value={i?.id}
                                  onChange={e => copyImport.push(e.target.value)}
                                />
                                {i?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2'>
                                  <span className='text-sm font-semibold' style={{ textTransform: 'capitalize' }}>{i?.name}</span>
                                  <p className='text-xs font-normal text-gray-500' style={{ textTransform: 'capitalize' }}>{i?.plant_id}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                      })
                      :
                      <div className='flex justify-center' style={{ margin: "90px 50px" }}>
                        <div>
                          <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                          <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div className={`flex justify-end ${loading ? "mt-32" : null}`}>
                  <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setModalActivityOpen(false); setCopyImport([]); setImportPlant(listPlant?.orgData) }}>Cancel</button>
                  {importLoader ?
                    <button className='btn flex space-x-2 items-center border-none rounded-3xl bg-red-550 text-white px-5 mr-4'>
                      <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                      <p>Import</p>
                    </button>
                    :
                    <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={e => handleSubmitImport(e)}>
                      Import
                    </button>
                  }
                </div>
              </form>
            </div>
          </Modal>
          {isActive === "Org Plant" ?
            <button
              htmlFor="my-drawer"
              onClick={toggleDrawer}
              className="mr-4 bg-red-550 text-white w-[140px] border-none rounded-3xl btn drawer-button"
            >
              Add Plant <span className="text-xl ml-1.5 mb-0.5">+</span>
            </button>
            :
            <button
              htmlFor="my-drawer"
              onClick={() => setImportModal(true)}
              className="mr-4 w-[140px] bg-red-550 text-white border-none rounded-3xl btn drawer-button"
            >
              Import Plant <span className="text-xl ml-1.5 mb-0.5">+</span>
            </button>
          }
        </div>
      </div>
      <hr />
      <div>
        <Drawer
          open={importModal}
          onClose={() => setImportModal(false)}
          direction="right"
          size="600px"
          className="absolute"
        >
          <div className='menu pl-4 bg-base-100 text-base-content w-full h-full'>
            <div className="h-full">
              <div className="flex justify-between mt-4 mr-5">
                <p className="text-xl font-bold mt-2">Select Plant</p>
                <VscClose
                  className="cursor-pointer mt-1 mr-3 hover:bg-gray-200 rounded-full"
                  onClick={() => setImportModal(false)}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
              <input className='appearance-none rounded-full w-[80%] mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Plant' onChange={e => handleSearchImportPlant(e)} />
              <p className='my-2 pt-1 text-gray-400 font-medium'>{importPlant?.length} plant available in the database</p>
              <form className="h-full">
                {loading ?
                  <div className='h-[65%] flex flex-col items-center justify-center w-full'>
                    <p className='loader'></p>
                    <p className='font-bold m-3 text-xl'>Fetching...</p>
                  </div>
                  :
                  <div className='overflow-auto h-[65%] border-b'>
                    {importPlant.length > 0 ?
                      importPlant.map((i) => {
                        if (handleCheckPlantExists(i?.id)) {
                          return <>
                            <label>
                              <div className='flex mt-1 cursor-auto bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' checked={false} disabled />
                                {i?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2 flex flex-col justify-center'>
                                  <span className='text-sm font-semibold text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.name}</span>
                                  <p className='text-xs font-normal text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.plant_id}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                        else {
                          return <>
                            <label>
                              <div className='flex mt-2 cursor-pointer hover:bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' value={i?.id}
                                  onChange={e => copyImport.push(e.target.value)}
                                />
                                {i?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2'>
                                  <span className='text-sm font-semibold' style={{ textTransform: 'capitalize' }}>{i?.name}</span>
                                  <p className='text-xs font-normal text-gray-500' style={{ textTransform: 'capitalize' }}>{i?.plant_id}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                      })
                      :
                      <div className='flex justify-center' style={{ margin: "90px 50px" }}>
                        <div>
                          <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                          <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div className={`flex justify-end h-[10%] items-center ${loading ? "mt-32" : null}`}>
                  <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setModalActivityOpen(false); setCopyImport([]); setImportModal(false); setImportPlant(listPlant?.orgData) }}>Cancel</button>
                  {importLoader ?
                    <button className='btn flex space-x-2 items-center border-none rounded-3xl bg-red-550 text-white px-5 mr-4'>
                      <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                      <p>Import</p>
                    </button>
                    :
                    <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={e => handleSubmitImport(e)}>
                      Import
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </Drawer>
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen((prevState) => !prevState)}
          direction="right"
          size="600px"
          className="absolute"
        >
          <div>
            <form
              onSubmit={handleSubmit(checkSubmitPlant)}
              className="menu p-4 bg-base-100 text-base-content"
              style={{ width: "635px" }}
            >
              <div className="overflow-y-scroll mr-5" style={{ height: "85vh" }}>
                <div className="flex justify-between mt-4">
                  <p className="text-xl font-bold ml-4 mt-2 ">{isEdit ? "Edit" : "Add"} Plant Details</p>
                  <VscClose
                    className="cursor-pointer mt-1 mr-3"
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>

                <div className="w-28">
                  <label className="mt-8 ml-14">
                    <div
                      className="border-2 rounded-full ml-4 h-20 w-20 cursor-pointer"
                      onMouseEnter={imageHover}
                      onMouseLeave={imageLeave}
                    >
                      <input
                        name="profile"
                        type="file"
                        onChange={handleProfileImageChange}
                        style={{ display: "none" }}
                      />
                      {images ? (
                        <div className="relative" onMouseEnter={() => { setEditImageHover(true) }} onMouseLeave={() => { setEditImageHover(false) }}>
                          {editImageHover ?
                            <p className="absolute text-sm font-semibold bottom-4 left-4">Update<br />Photo</p>
                            :
                            null
                          }
                          <img src={images} alt="" className="h-20 w-20 rounded-full" style={{ opacity: editImageHover ? 0.5 : 1 }} />
                        </div>
                      ) : imageBoolean ? (
                        <TbCameraPlus
                          className="m-auto mt-7 text-gray-400"
                          style={{ width: "23px", height: "23px" }}
                        />
                      ) : (
                        <TbForklift
                          className="m-auto mt-7 text-gray-400"
                          style={{ width: "23px", height: "23px" }}
                        />
                      )}
                    </div>
                  </label>
                </div>

                <div className="flex mt-2 mb-3 ml-4">
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7"
                    style={{ width: "250px" }}
                    placeholder="Nickname *"
                    required
                    {...register("name")}
                  // onChange={(e) => setPlantType(e.target.value)}
                  />
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                    style={{ width: "250px" }}
                    placeholder="Plant ID *"
                    required
                    {...register("plant_id")}
                  // onChange={e => setPlantId(e.target.value)}
                  />
                </div>
                <div className="ml-4">
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                    style={{ width: "528px" }}
                    placeholder="Plant Type"
                    {...register("plant_type")}
                  />
                </div>
                <div className="flex mb-1 ml-4">
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7"
                    style={{ width: "250px" }}
                    placeholder="Make"
                    {...register("make")}
                  />
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                    style={{ width: "250px" }}
                    placeholder="Model"
                    {...register("model")}
                  />
                </div>
                <div className="flex mb-6 ml-4">
                  <input
                    className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7"
                    style={{ width: "250px" }}
                    placeholder="Registration Number *"
                    required
                    {...register("registration_number")}
                    onChange={e => setExistData(produce((draft) => { draft.registration_number = e.target.value }))}
                  />
                  <div className="relative">
                    <SlCalender className="absolute z-10 right-3 bottom-5" />
                    <DatePicker
                      dateFormat="dd-MMMM-yyyy"
                      {...register("date")}
                      placeholderText="Date on site *"
                      className='appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-2 mt-4 w-[250px] cursor-pointer'
                      calendarContainer={datePickerStyle}
                      onChange={handleDateChange}
                      selected={date}
                    />
                  </div>
                </div>

                <div className="text-xl font-bold mt-4 ml-4">
                  <div className="flex">
                    Documents
                    <AiOutlineInfoCircle data-tip data-for="other-documents" className="mt-1 ml-2 text-gray-400 cursor-pointer" />
                    <ReactTooltip effect='solid' place='top' id="other-documents">
                      <div>You can add upto 5 documents</div>
                    </ReactTooltip>
                  </div>
                  {docCount.map((count, index) => {
                    return (
                      <div className="mt-5">
                        {!document[index] ?
                          <div
                            className="mt-5 border-2 border-dashed border-gray rounded-2xl"
                            style={{ width: "528px", height: "155px" }}
                          >
                            <div className='flex justify-end'>
                              {docCount.length > 1 ?
                                <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleRemoveDoc(count, index)} />
                                :
                                <p className="m-4" />
                              }
                            </div>
                            <label className="cursor-pointer">
                              <input
                                {...getInputProps()}
                                type="file"
                                style={{ display: "none" }}
                                onChange={e => handleDocChange(e, index)}
                              />
                              {isDragActive ? (
                                <div className="font-normal text-center">
                                  <img
                                    src="/Upload.jpg"
                                    alt=""
                                    className="m-auto"
                                  />
                                  <p className="text-sm mt-6">
                                    Click or Drop Files here
                                  </p>
                                  <p className="text-xs mt-4">
                                    Supported files : JPG, PNG, PDF
                                  </p>
                                </div>
                              ) : (
                                <div className="font-normal text-center">
                                  <img
                                    src="/Upload.jpg"
                                    alt=""
                                    className="m-auto"
                                  />
                                  <p className="text-sm mt-6">
                                    Click or Drop Files here
                                  </p>
                                  <p className="text-xs mt-4">
                                    Supported files : JPG, PNG, PDF
                                  </p>
                                </div>
                              )}
                            </label>
                          </div> :
                          <div className="mt-5 mb-5">
                            <div className="flex justify-between">
                              <div className="flex">
                                <p className="text-sm pl-0.25 font-semibold">{docNameEdit[index]}</p>
                                <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = true })); setDisableSubmit(true) }} style={{ width: "20px", height: "20px" }} />
                              </div>
                              <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => { setDocument(produce((draft) => { draft.splice(index, 1) })); setDisableSubmit(false) }} />
                            </div>
                            {docRenameBox[index] ?
                              <div className="flex">
                                <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={docNameEdit[index].split(".")[0]} onChange={e => setDocNameChange(e.target.value + "." + docNameEdit[index].split(".")[1])} />
                                <p className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = false })); setDisableSubmit(false) }}>Cancel</p>
                                <p className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleDocNameChange(index)}>Save</p>
                              </div>
                              : null
                            }
                          </div>
                        }
                      </div>
                    )
                  })}
                  {docCount.length < 5 ?
                    <p className="text-gray-400 flex justify-end font-medium text-base cursor-pointer m-5 mr-8" onClick={handleAddDoc}>+ Add More</p>
                    :
                    null
                  }
                  <div>

                  </div>
                </div>
              </div>
              <div className="flex mt-11 justify-end mr-20">
                <button
                  className="btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500"
                  style={{ width: "100px" }}
                  type="reset"
                  onClick={() => {
                    setImages()
                    setDocNameChange()
                    setDocNameEdit([])
                    setDocRenameBox([])
                    setDocument([])
                    toggleDrawer()
                    setDates()
                    setDocCount([1])
                    setIsEdit(false)
                    setDisableSubmit(false)
                  }}
                >
                  Cancel
                </button>
                {btnLoading ?
                  <button
                    className="border-none rounded-3xl bg-red-550 text-white btn loading"
                    style={{ width: "126px" }}
                  >
                    {isEdit ? "Updating" : "Saving"}
                  </button>
                  :
                  <input
                    className="btn border-none rounded-3xl bg-red-550 text-white btn"
                    disabled={disableSubmit}
                    style={{ width: "96px" }}
                    value={isEdit ? "Update" : "Save"}
                    type="submit"
                  />
                }
              </div>
            </form>
          </div>
        </Drawer>
      </div>

      <div>
        {loading ?
          <div className='' style={{ marginTop: "14%", marginLeft: "44%" }}>
            <p className='loader ml-3 mb-1'></p>
            <p className='font-bold text-xl'>Fetching...</p>
          </div>
          :
          <div className="overflow-scroll" style={{ height: "calc(100vh - 190px)" }}>
            {plantList.length || listPlant.data.length > 0 ?
              <table className="table table-compact w-full p-2 z-0">
                <thead>
                  <tr className="text-left font-semibold text-xs text-gray-400 sticky top-0 z-1 w-full">
                    <th className='normal-case pl-11 w-[18%]'>Name & Plant ID</th>
                    <th className='normal-case w-[10%]'>Plant Type</th>
                    <th className='normal-case w-[14%]'>Make & Model</th>
                    <th className='normal-case w-[11%]'>Date on Site</th>
                    <th className='normal-case w-[14%]'>Registration Number</th>
                    <th className='normal-case w-[25%]'>Docs</th>
                    <th className='normal-case w-[8%]'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {plantList.map((i) => {
                    return (
                      <tr className="hover" style={{ textTransform: "capitalize" }}>
                        <td>
                          <div className="flex">
                            {i?.profile_url === null ?
                              <div className="avatar">
                                <div className="mt-1.5 text-neutral-content rounded-full w-7 text-center p-0.5 bg-yellow-400 text-white text-base">
                                  <span className="text-base" style={{ textTransform: 'capitalize' }}>{i?.name?.charAt(0).toUpperCase()}</span>
                                </div>
                              </div>
                              :
                              <img src={i?.profile_url} alt='' className='border-none rounded-full mt-2 cursor-pointer' onClick={() => { handleDownloadProfile(i) }} style={{ width: '25px', height: '25px' }} />
                            }
                            <div className="ml-2">
                              <p className="font-normal text-sm">{i?.name}</p>
                              <p className="font-normal text-xs text-gray-400">
                                {i?.plant_id}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>{i?.plant_type}</td>
                        <td>{i?.make} {i?.model ? "•" : null} {i?.model}</td>
                        <td style={{ textTransform: "capitalize" }}>{<DateFormat date={i?.date * 1000} />}</td>
                        <td>{i?.registration_number}</td>
                        <td className='underline underline-offset-2 decoration-sky-500'>{i?.document?.map((doc, index) => {
                          if (doc.type === 1 && doc?.name) {
                            return (
                              <div className={'flex items-center gap-4 cursor-pointer'}>
                                <p
                                  className=" text-blue-400 cursor-pointer"
                                  onClick={() => openImageViewer(doc)}
                                >
                                  {doc?.name.split(".")[0]}
                                </p>
                              </div>
                            )
                          }
                        })}

                        </td>
                        <td>
                          <div className="dropdown dropdown-end" style={{ textTransform: "capitalize" }}>
                            <BsThreeDots tabIndex={0} alt='' className='cursor-pointer' style={{ fontSize: "20px" }} />
                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box min-w-52">
                              <li><a className='flex active:bg-red-550 active:text-white' htmlFor="my-drawer" onClick={() => handleEditClick(i)}>
                                <MdModeEditOutline style={{ width: "20px", height: '20px' }} />
                                <p>Edit</p></a>
                              </li>
                              {isActive === "Project Plant" ?
                                <li><a className='flex active:bg-red-700 active:text-white' onClick={() => handleDelete(i)}>
                                  <MdDelete style={{ width: "20px", height: '20px' }} />
                                  <p>Remove from Project</p></a></li>
                                :
                                <li><a className='flex active:bg-red-700 active:text-white' onClick={() => handleDelete(i)}>
                                  <MdDelete style={{ width: "20px", height: '20px' }} />
                                  <p>Remove from Database</p></a></li>
                              }
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              :
              <div className='flex justify-center'>
                <div style={{ marginTop: "15%" }}>
                  <img src='/EmptyStat.svg' alt='' className='m-auto' style={{ marginTop: "15%" }} />
                  <p className='text-gray-400 mt-4 text-center font-medium'>Use Add plant to start</p>
                </div>
              </div>
            }
          </div>
        }
      </div>
      <Modal
        isOpen={deleteModalOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeDeleteModal}
        style={deleteCustomStyle}
        contentLabel="Delete Modal">
        <p className='text-lg font-bold my-2'>Delete Plant</p>
        <p className='text-base font-medium text-gray-500 mt-2'>There are work hours recorded against this plant on project(s), removing the plant will remove all the associated work hours captured in SURYA. Please confirm</p>
        <div className="flex justify-end mt-4">
          <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' onClick={() => setDeleteModalOpen(false)}>Cancel</button>
          <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={() => handleForceDelete()}>Confirm</button>
        </div>
      </Modal>
      {downloadImg?.name?.split(".")[1] === "png" || downloadImg?.name?.split(".")[1] === "jpg" ?
        <div className="flex">
          <MdOutlineFileDownload className='text-gray-400 hover:text-white fixed right-14 top-9 opacity-50 hover:opacity-100 cursor-pointer z-50 text-2xl' onClick={() => handleSavePdf(downloadImg)} />
          <ImageViewer
            src={docImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        </div>
        :
        null
      }
      <Modal
        isOpen={existPlantModel}
        onRequestClose={() => { setExistPlantModel(false); existPlantModelRef.current = false }}
        style={existPlantStyle}
        contentLabel="Exist Data Modal"
      >
        <div>
          <p className='text-base font-medium text-gray-500 my-3'>
            {isEdit ?
              "A record already exists with the same Registration number, confirm if you want to proceed with the edit"
              :
              "A record already exists with the same Registration number, please review or confirm to create a record anyways"
            }
          </p>
          <hr />
          <div className="flex justify-end space-x-4 mt-3">
            {isEdit ?
              null
              :
              <button className='text-red-550'
                onClick={handleViewExistData}
              >
                Review
              </button>
            }
            <button className="btn border-none bg-red-550 text-white rounded-full space-x-4 flex items-center" onClick={() => isEdit ? onEditSubmit(duplicateEntry) : onSubmit(duplicateEntry)}>
              {btnLoading ?
                <p className="loader" style={{ width: "25px", height: "25px" }}></p>
                :
                null
              }
              <p>Confirm</p>
            </button>
            <button className='btn border-2 rounded-3xl bg-white text-gray-500 border-gray-500' onClick={() => { setExistPlantModel(false); existPlantModelRef.current = false }}>Cancel</button>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default PlantList;
